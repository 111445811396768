import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Loading } from '../../components';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { TOAST_CONFIG } from '../../config/constants';
import { roleActions } from '../../state/ducks/role';
import { gatewayActions } from '../../state/ducks/gateway/';
import UserForm from '../../components/UserForm';

class EditBankUser extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    name: '',
    lastName: '',
    documentType: -1,
    documentNumber: '',
    email: '',
    adminPosition: '',
    roles: [],
    roleList1: [],
    edit: false,
    hidden: false,
    isAcquiring: false,
    gateways: [],
    gateway: '',
    role: -1,
    phoneNumber: '',
    unformattedRut: '',
    collaborator_number: '',
    disabled: true,
    typePerson: '',
    typeDocument: '',
    type_legal_person: '',
  });

  /*formatEmail = (email) => {
    let re = /\S+@baccredomatic\.com+/;
    let re1 = /\S+@elcallao\.com+/;
    let re2 = /\S+@baccredomatic\.gt+/;
    let re3 = /\S+@medullaris\.com+/;
    return re.test(email) || re1.test(email) || re2.test(email) || re3.test(email);
  };*/

  formatEmail = (email) => {
    let re = /\S+@yopmail\.com+/;
    return re.test(email);
  };

  componentDidMount() {
    const {
      auth: { user: { user_id } },
      role: { list: roleList },
      fetchRoles,
      fetchDocumentTypes,
      router: { location: { pathname } },
      documentType: { list: documentTypeList },
      //getGateway,
    } = this.props;

    const {
      userInfo
    } = this.props.location.state;

    /*fetchDocumentTypes({
      params: { legal_type: 'J' },
      callback: () => {
        this.setState({
          documentType: documentTypeList.findIndex(d => userInfo.idPerson.idDocumentType.id === d.id)
        })
      }
    });*/

    let doctype = '';
    if (userInfo.idPerson.idDocumentType !== null) {
      doctype = documentTypeList.findIndex(x => x.id == userInfo.idPerson.idDocumentType.id);
    }

    /*getGateway({
      callback: (response) => {
        this.setState({ gateways: response });
      }
    });*/

    fetchRoles({
      params: {
        owner_id: user_id,
      },
      callback: (response) => {
        const role = response.map((roleMap, index) => ({
            ...roleMap,
            index
          })
        )
          .filter(roleTypeFil => roleTypeFil.name === userInfo.roles[0].name)[0].index;
        this.setState({ role: role });
      }
    });

    const roles1 = [...roleList];
    /*let band = false;
    roles1.sort();
    for (let i = 0; i < userInfo.roles.length; i++) {
      for (let j = 0; j < roles1.length; j++) {
        if (Number(userInfo.roles[i].id) === Number(roles1[j].id)) {
          roles1.splice(j, 1);
          band = true;
        }
      }
    }*/

    let acquiringBand = false;

    if (userInfo.roles[0].name === 'acquiring_bank') {
      acquiringBand = true;
    }

    let documentType = -1;
    if (userInfo.type_legal_person !== null) {
      fetchDocumentTypes({
        params: {
          legal_type: userInfo.type_legal_person === null ?
            userInfo.idPerson.idDocumentType.document_type === 'Cédula jurídica' ? 'J' : 'F'
            :
            userInfo.type_legal_person === 'JUR' ? 'J' : 'F'
        },
        callback: (data) => {
          documentType = userInfo.idPerson.idDocumentType !== null ? (data.map(
            (documentTypeMap, index) => ({
              ...documentTypeMap,
              index
            })
          )
            .filter(documentTypeFil => documentTypeFil.document_type
              === userInfo.idPerson.idDocumentType.document_type)[0].index) : -1;
          this.setState({
            documentType: documentType,
            typeDocument: data,
            type_legal_person: userInfo.idPerson.idDocumentType.document_type === 'Cédula jurídica' ? 'JUR' : 'FIS',
            typePerson: userInfo.idPerson.idDocumentType.document_type === 'Cédula jurídica' ? 'legal' : 'person',
          });
        }
      });
    }

    /*const role = roleList.map((roleMap, index) => ({
        ...roleMap,
        index
      })
    )
      .filter(roleTypeFil => roleTypeFil.name === userInfo.roles[0].name)[0].index;*/
    this.setState({
      name: userInfo.idPerson.name,
      lastName: userInfo.idPerson.lastname,
      documentType: doctype,
      documentNumber: userInfo.idPerson.document_number,
      email: userInfo.username,
      roles: roleList,//userInfo.roles,
      adminPosition: userInfo.admin_position,
      roleList1: this.labelType(roles1),
      isAcquiring: acquiringBand,
      phoneNumber: userInfo.phones.length === 0 ? '' : userInfo.phones[0].phone_number,
      collaborator_number: userInfo.collaborator_number,
      disabled: userInfo.collaborator_number !== '' ? false : true,
      //roleType: roleList.findIndex(role => role.id == userInfo.roles[0].id),
    });
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleInputChangeTypeDocument = (event) => {
    const {
      fetchDocumentTypes,
    } = this.props;
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    fetchDocumentTypes({
      params: { legal_type: value === 'legal' ? 'J' : 'F' },
      callback: (data) => {
        this.setState({
          type_legal_person: value === 'legal' ? 'JUR' : 'FIS',
          typeDocument: data,
          [name]: value,
        });
      }
    });

  };

  cancelSave = () => {
    const { history } = this.props;
    history.push('/dashboard');
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    /*if (name === 'documentNumber') {
      let num = this.formatRUT(event.target.value);
      let unformattedNum = this.formatRUT(event.target.value);
      let prenum = '';
      let postnum = '';

      if (num.length >= 9) {
        prenum = num.slice(0, 8);
        postnum = num.slice(8, 9);
        num = prenum + '-' + postnum;
      }

      this.setState({ unformattedRut: unformattedNum});

      this.setState({
        [name]: (num),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }*/
    this.setState({
      [name]: value,
    });
  };

  formatRUT = (n) => {
    return n.replace(/[^0-9Kk]/, '');
    //return n.replace(/[^a-zA-Z0-9]/, '');
  };

  handleItemChange = (newValue, event) => {
    const {
      role: { list: roleList },
    } = this.props;
    const {
      roleList1
    } = this.state;

    if (event.action === 'remove-value') {
      roleList1.push({
        id: event.removedValue.id,
        name: event.removedValue.name,
        type: event.removedValue.type,
        owner_id: event.removedValue.owner_id,
        label_type: event.removedValue.label_type,
      });
    }

    if (event.action === 'select-option') {
      for (let j = 0; j < roleList1.length; j++) {
        if (Number(event.option.id) === Number(roleList1[j].id)) {
          roleList1.splice(j, 1);
        }
      }
    }

    if (event.action === 'clear') {
      roleList1.splice(0, roleList1.length);
      ;
      for (let j = 0; j < roleList.length; j++) {
        roleList1.push({
          id: roleList[j].id,
          name: roleList[j].name,
          type: roleList[j].type,
          owner_id: roleList[j].owner_id,
        });
      }
    }
    this.setState({ roles: newValue });
  };

  handleSelectChange = (newVal, label) => {
    const { role: { list: roleList } } = this.props;
    const {
      collaborator_number,
    } = this.state;
    let rolename = '';

    if (label.name === 'role') {
      this.setState({
        [label.name]: newVal.value,
        disabled: !(roleList[newVal.value].name === 'acquiring_bank' ||
          roleList[newVal.value].name === 'issuing_bank'),
        collaborator_number: (roleList[newVal.value].name === 'acquiring_bank' ||
          roleList[newVal.value].name === 'issuing_bank') ? collaborator_number : '',
      });
    } else if (label.name !== 'role') {
      this.setState({
        [label.name]: newVal.value,
      });
    }
    for (let i = 0; i < roleList.length; i++) {
      if (roleList[i].id == newVal.value) {
        rolename = roleList[i].name;
        if (rolename === 'acquiring_bank') {
          this.setState({ isAcquiring: true });
        } else {
          this.setState({ isAcquiring: false });
        }
      }
    }
  };

  editBankUser = () => {
    const {
      editProvider,
      t,
      showMessage,
      documentType: { list: documentTypeList },
      role: { list: roleList },
      router: { location: { pathname } },
      history,
    } = this.props;

    const {
      name,
      lastName,
      documentType,
      documentNumber,
      email,
      roles,
      adminPosition,
      //gateway,
      phoneNumber,
      role,
      collaborator_number,
      type_legal_person,
    } = this.state;

    const {
      userInfo
    } = this.props.location.state;

    /*const type = documentType === 0 ? 'CI' : 'Pasaporte';
    const type1 = documentTypesList
      .map((documentTypeMap, index) => ({
        ...documentTypeMap,
        index
      }))
      .filter(documentType => documentType.document_type
        === 'RUT');*/

    if ((roleList[role].name === 'acquiring_bank' ||
      roleList[role].name === 'issuing_bank')) {
      if (this.formatEmail(email)) {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('validEmail')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
        return;
      }
      if (collaborator_number.length < 8) {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('collaboratorNumberCharacter')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
        return;
      }
    }

    const data = {
      id_user: userInfo.id,
      name: name,
      lastname: lastName,
      documentType: documentType !== '' ? documentTypeList[documentType].id : '',
      documentNumber: documentNumber,
      email,
      country_code: null,
      phone_number: '',
      password: '',
      bankType: roleList[role].id,
      gateway_id: '',// gateway + 1,
      b2b: {
        issuing_bank: 0,
        acquiring_bank: 1,
        admin_position: adminPosition,
        collaborator_number,
        type_legal_person,
      },
      phone: {
        phone_number: phoneNumber,
        country_code: '+56',
        is_main: 1,
      },
    };

    editProvider({
      data,
      callback: (response) => {
        history.push('/bank-user-list');
        showMessage({
          message: t('editUserSuccess', { name }),
          config: TOAST_CONFIG.SUCCESS,
        });
      }
    });
    /*if (this.validateEmptyFields()) {
      createProvider({
        data,
        callback: (response) => {
          console.info(response);
          history.push('/dashboard');
          showMessage({
            message: t('createUserSuccess', { name }),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
        },
      });
    } else {
      showMessage({
        message: t('emptyAll'),
        config: TOAST_CONFIG.WARNING,
      });
    }*/
  };

  isEmail = n => /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/.test(n);

  validateEmptyFields = () => {
    const {
      name,
      lastName,
      documentNumber,
      documentType,
      email,
      roles,
    } = this.state;

    return !(
      name === '' ||
      lastName === '' ||
      documentNumber === '' ||
      documentType === -1 ||
      email === '' ||
      roles.length < 0);
  };

  labelType = (roleList) => {
    const {
      t,
      auth: { user: { b2b: { type_user } } },
    } = this.props;
    const roles = [];

    /*for (let i = 0; i < roleList.length; i++) {
      roles.push({
        id: roleList[i].id,
        name: roleList[i].name,
        type: roleList[i].type,
        owner_id: roleList[i].owner_id,
        label_type: t(roleList[i].name)
      });
    }*/

    for (let i = 0; i < roleList.length; i++) {
      if (type_user === 'main_bank_issuing') {
        roles.push({
          id: roleList[i].id,
          name: roleList[i].name,
          type: roleList[i].type,
          owner_id: roleList[i].owner_id,
          label_type: t(roleList[i].name), /*roleList[i].name === 'issuing_bank' ? t('platformAdministrator') :
            roleList[i].name === 'issuing_query' ? t('userQuery') : roleList[i].name === 'cycle_loader' ? t('userBillingCycles') :
              roleList[i].name === 'rate_operator' ? t('commercialBillingUser') : t('commercialBillingUser'),*/
        });
      } else {
        roles.push({
          id: roleList[i].id,
          name: roleList[i].name,
          type: roleList[i].type,
          owner_id: roleList[i].owner_id,
          label_type: /*type_user === 'main_bank_acquiring' ? roleList[i].name === 'acquiring_bank' ? t('acquiringBank') : t('acquiringQuery') :*/
            t(roleList[i].name)
        });
      }
    }

    return roles;
  };

  cancelSave = () => {
    const { history } = this.props;
    history.push('/dashboard');
  };

  render() {
    const {
      t,
      documentType: { list: documentTypesList, loading: documentLoading },
      providers: { loading: providerLoading },
      role: { list: roleList, loading: roleLoading },
      auth: { user: { b2b: { type_user } } },
    } = this.props;
    const {
      name,
      lastName,
      documentNumber,
      email,
      documentType,
      edit,
      hidden,
      roles,
      adminPosition,
      /*gateway,
      gateways,*/
      isAcquiring,
      role,
      phoneNumber,
      collaborator_number,
      disabled,
      typePerson,
    } = this.state;

    //let filteredRoleList = roleList.filter(r => !roles.some(r2 => r2.id == r.id));
    //console.dir([roles, roleList, filteredRoleList]);

    return (
      <div>
        {(documentLoading || roleLoading || providerLoading) && <Loading/>}
        <div className="columns">
          <div className="column is-full">
            <UserForm
              t={t}
              title={t('edit_user')}
              onSubmit={this.editBankUser}
              handleInputChange={this.handleInputChange}
              name={name}
              lastName={lastName}
              handleSelectChange={this.handleSelectChange}
              handleItemChange={this.handleItemChange}
              documentTypesList={documentTypesList}
              documentNumber={documentNumber}
              documentType={documentType}
              email={email}
              position={adminPosition}
              roleList={this.labelType(roleList)}
              roles={roles}
              role={role}
              edit={edit}
              hidden={hidden}
              isAcquiring={isAcquiring}
              /*gatewayList={gateways}
              gateway={gateway}*/
              phoneNumber={phoneNumber}
              typeUser={type_user}
              collaborator_number={collaborator_number}
              disabled={disabled}
              typePerson={typePerson}
              handleInputChangeTypeDocument={this.handleInputChangeTypeDocument}
              cancelSave={this.cancelSave}
            />
          </div>
        </div>
      </div>
    );
  }
}

EditBankUser.propTypes = {
  /*auth: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  location: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  createProvider: PropTypes.func.isRequired,
  roles: PropTypes.shape(Object).isRequired,
  editUser: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,*/
};


const mapStateToProps = ({
                          providers,
                          documentType,
                          auth,
                          role,
                          router,
                          gateway,
                        }) => ({
  providers,
  documentType,
  auth,
  role,
  router,
  gateway,
});
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...providersActions,
    ...roleActions,
    ...gatewayActions,
  }),
  withNamespaces(),
)(EditBankUser);
