import { toast } from 'react-toastify';
import { authTypes } from '../ducks/auth';
import { TOAST_CONFIG } from '../../config/constants';
import { transactionsTypes } from '../ducks/transactions';
import { cardTypes } from '../ducks/card';
import { providersTypes } from '../ducks/providers';
import { clientsTypes } from '../ducks/clients';
import { documentTypeTypes } from '../ducks/documentType';
import { itemTypes } from '../ducks/item';
import { paymentConditionsTypes } from '../ducks/paymentConditions';
import { statsTypes } from '../ducks/stats';
import { roleTypes } from '../ducks/role';
import { financingProductsTypes } from '../ducks/financingProducts';
import { bankTypes } from '../ducks/bank';
import { routeTypes } from '../ducks/route';
import { leadTypes } from '../ducks/lead';
import { userProfileTypes } from '../ducks/userProfile';
import { flowApprovalTypes } from '../ducks/flowApproval';
import { logTypes } from '../ducks/log';
import { getPath } from '../../util';
import { webpayTypes } from '../ducks/webpay/';
import { gatewayTypes } from '../ducks/gateway';
import { credentialsTypes } from '../ducks/credentials';
import { currencyTypes } from '../ducks/currency';
import { bankingExecutivesTypes } from '../ducks/bankingExecutives';
import { productTypes } from '../ducks/product/';
import { supportTypes } from '../ducks/support';

const endFetch = ({ dispatch }) => next => (action) => {
  next(action);

  const types = [
    authTypes.END_FETCH, transactionsTypes.END_FETCH,
    cardTypes.END_FETCH, providersTypes.END_FETCH, clientsTypes.END_FETCH,
    documentTypeTypes.END_FETCH, itemTypes.END_FETCH, paymentConditionsTypes.END_FETCH,
    statsTypes.END_FETCH, roleTypes.END_FETCH, bankTypes.END_FETCH, routeTypes.END_FETCH,
    financingProductsTypes.END_FETCH, leadTypes.END_FETCH, userProfileTypes.END_FETCH,
    flowApprovalTypes.END_FETCH, logTypes.END_FETCH, webpayTypes.END_FETCH, gatewayTypes.END_FETCH, 
    credentialsTypes.END_FETCH, currencyTypes.END_FETCH, bankingExecutivesTypes.END_FETCH,
    productTypes.END_FETCH, supportTypes.END_FETCH,
  ];

  if (!types.includes(action.type) || !action.payload) {
    return;
  }

  const { payload } = action;
  if (getPath(['response', 'status'], payload) === 422) {
    dispatch({
      type: authTypes.MESSAGE,
      payload: {
        message: getPath(['response', 'data', 'data', 'message'], payload),
      },
      meta: {
        config: TOAST_CONFIG.WARNING,
      },
    });
  } else if(getPath(['response', 'status'], payload) === 500){
    if(getPath(['response', 'data', 'data', 'message'], payload) === 'Expired token'){
      dispatch({
        type: authTypes.MESSAGE,
        payload: {
          message: 'Sesión Expirada',//getPath(['response', 'data', 'data', 'message'], payload),
        },
        meta: {
          config: TOAST_CONFIG.WARNING,
        },
      });
      setTimeout(() => {
        dispatch({
          type: authTypes.LOGOUT,
        });
      }, 3000);
    }
  } else if (Object.prototype.hasOwnProperty.call(payload, 'data')
    && Object.prototype.hasOwnProperty.call(payload.data, 'success') && !payload.data.success) {
    dispatch({
      type: authTypes.MESSAGE,
      payload: {
        message: payload.data.data.message,
      },
      meta: {
        config: TOAST_CONFIG.INFO,
      },
    });
  }
};

const messages = () => next => (action) => {
  const types = [
    authTypes.MESSAGE, transactionsTypes.MESSAGE,
    cardTypes.MESSAGE, providersTypes.MESSAGE, clientsTypes.MESSAGE,
    documentTypeTypes.MESSAGE, itemTypes.MESSAGE, paymentConditionsTypes.MESSAGE,
    statsTypes.MESSAGE, roleTypes.MESSAGE, bankTypes.MESSAGE, routeTypes.MESSAGE,
    financingProductsTypes.MESSAGE, leadTypes.MESSAGE, userProfileTypes.MESSAGE,
    flowApprovalTypes.MESSAGE, logTypes.MESSAGE, webpayTypes.MESSAGE,
    gatewayTypes.MESSAGE, credentialsTypes.MESSAGE, currencyTypes.MESSAGE, 
    bankingExecutivesTypes.MESSAGE, productTypes.MESSAGE, supportTypes.MESSAGE, ];

  if (!types.includes(action.type)) {
    return next(action);
  }

  const { message = 'THERE IS NO MESSAGE' } = action.payload;
  const { config = TOAST_CONFIG.INFO } = action.meta;
  toast(message, config);
};

export default [endFetch, messages];
