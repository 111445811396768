import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading } from '../../components';
import { TOAST_CONFIG } from '../../config/constants';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { roleActions } from '../../state/ducks/role';
import { clientsActions } from '../../state/ducks/clients';
import { gatewayActions } from '../../state/ducks/gateway';
import Resizer from 'react-image-file-resizer';
import MainBankForm from '../../components/MainBankForm';

class CreateBank extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    bank_id: '',
    registerLoading: false,
    bankType: -1,
    name: '',
    documentType: -1,
    documentNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    user_type: 'flecha',
    agency_user_type: 'business_admin',
    b2b: {
      bank_id: null,
      admin: 0,
      issuing_bank: 0,
      acquiring_bank: 0,
      seller: 0,
      buyer: 0,
    },
    file: '',
    binaryFile: '',
    bankName: '',
    typePerson: '',
    typeDocument: [],
    documentTypeUser: '',
    type_legal_person: '',
    phone: '',
  });

  componentDidMount() {
    const {
      auth: {
        user: {
          b2b: { id },
        },
      },
      fetchRoles,
      router: { location: { pathname } },
    } = this.props;

    fetchRoles({
      params: {
        owner_id: id,
      },
    });

    if (pathname === '/edit-admin-bank') {
      const {
        userInfo
      } = this.props.location.state;
      this.setState(this.arrayBank(userInfo));
    }
  }

  arrayBank = (userInfo) => {
    const {
      auth: {
        user: {
          b2b: { id },
        },
      },
      fetchDocumentTypes,
      fetchRoles,
      role: { list: roleList },
    } = this.props;

    let documentType = -1;

    if (userInfo.type_legal_person !== null) {
      fetchDocumentTypes({
        params: {
          legal_type: userInfo.type_legal_person === 'JUR' ? 'J' : 'F'
        },
        callback: (data) => {
          documentType = (data.map(
            (documentTypeMap, index) => ({
              ...documentTypeMap,
              index
            })
          )
            .filter(documentTypeFil => documentTypeFil.document_type
              === userInfo.idPerson.idDocumentType.document_type)[0].index);
          this.setState({
            documentTypeUser: documentType,
            typeDocument: data,
            typePerson: userInfo.type_legal_person === 'JUR' ? 'legal' : 'person',
            type_legal_person: userInfo.type_legal_person === 'JUR' ? 'JUR' : 'FIS',
          });
        }
      });
    }
    let banktype = 0;
    if (userInfo.roles.length > 0) {
      fetchRoles({
        params: {
          owner_id: id,
        },
        callback: (data) => {
          banktype = data.map(
            (roleMap, index) => ({
              ...roleMap,
              index
            }),
          )
            .filter(roleFil => roleFil.id === parseInt(userInfo.roles[0].id))[0].index;
        }
      });
    }

    return {
      bank_id: userInfo.id,
      name: userInfo.idPerson.name,
      documentType: 'RUT',
      documentNumber: userInfo.idPerson.document_number,
      bankType: banktype,
      email: userInfo.idPerson.idEmailActive.email,
      originalEmail: userInfo.idPerson.idEmailActive.email,
      phone: userInfo.phones.length > 0 ? userInfo.phones[0].phone_number : '',
    };
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    /*if (name === 'documentNumber') {
      let num = this.formatRUT(event.target.value);
      let prenum = '';
      let postnum = '';

      if (num.length >= 9) {
        prenum = num.slice(0, 8);
        postnum = num.slice(8, 9);
        num = prenum + '-' + postnum;
      }

      this.setState({
        [name]: (num),
      });
    } else {*/
    this.setState({
      [name]: value,
    });
    //}
  };

  handleSelectChange = (newVal, label) => {
    const { role: { list: roleList } } = this.props;

    if (label.name === 'bankType') {
      this.setState({
        [label.name]: newVal.value,
        bankName: newVal.value !== '' ? roleList[newVal.value].name : '',
      });
    } else {
      this.setState({
        [label.name]: newVal.value,
      });
    }
  };

  validateEmptyFields = () => {
    const {
      name,
      bankType,
      documentTypeUser,
      documentNumber,
      email,
    } = this.state;

    return !(
      name === '' || 
      documentTypeUser === -1 ||
      documentNumber === '' ||
      bankType === -1 || 
      email === '');
  };

  createProvider = () => {
    const {
      createProvider,
      showMessage,
      t,
      history,
      role: { list: roleList },
    } = this.props;

    const {
      name,
      bankType,
      documentNumber,
      email,
      password,
      binaryFile,
      phone,
      documentTypeUser,
      typeDocument,
      type_legal_person,
    } = this.state;

    if (this.validateEmptyFields()) {
      let data;
      const dataForm = new FormData();

      data = {
        name,
        document_type: typeDocument[documentTypeUser].document_type,
        document_number: documentNumber,
        email,
        password,
        user_type: 'flecha',
        agency_user_type: 'business_admin',
        items: [],
        role_name: roleList[bankType].id,
        b2b: {
          type_legal_person,
          bank_id: null,
          admin: 0,
          issuing_bank: 0,
          acquiring_bank: 0,
          [roleList[bankType].name]: 1,
          seller: 0,
          buyer: 0,
        },
        resource: binaryFile,
        gateway_id: null,
        phone: {
          phone_number: phone,
          country_code: '+56',
          is_main: 1,
        },
      };

      if (binaryFile) {
        for (let key in data) {
          if (typeof (data[key]) === 'object' && key !== 'resource') {
            for (let subKey in data[key]) {
              dataForm.append(`${key}.${subKey}`, data[key][subKey]);
            }
          } else {
            dataForm.append(key, data[key]);
          }
        }
      }

      const dataSend = binaryFile ? dataForm : data;

      createProvider({
        data: dataSend,
        is_perfil: true,
        callback: () => {
          history.push('/bank-list');
          showMessage({
            message: t('createBankSuccess', { name }),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
        },
      });
    } else {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }
  };

  editProvider = () => {
    const {
      t,
      history,
      role: { list: roleList },
      showMessage,
      editProvider,
    } = this.props;
    const {
      bank_id,
      name,
      documentNumber,
      bankType,
      email,
      originalEmail,
      binaryFile,
      phone,
      type_legal_person,
      documentTypeUser,
      typeDocument,
      userInfo,
    } = this.state;

    const dataForm = new FormData();

    const data = {
      id_user: userInfo.id,
      country_code: '',
      phone_number: '',
      email: email === originalEmail ? '' : email,
      password: '',
      lastname: '',
      name,
      documentType: typeDocument[documentTypeUser].id,
      documentNumber,
      bankType: roleList[bankType].id,
      resource: binaryFile,
      b2b: {
        type_legal_person,
        admin: 0,
        issuing_bank: 0,
        acquiring_bank: 0,
        seller: 0,
        buyer: 0,
        [roleList[bankType].name]: 1,
      },
      phone: {
        phone_number: phone,
        country_code: '+56',
        is_main: 1,
      },
    };

    if (binaryFile) {
      for (let key in data) {
        if (typeof (data[key]) === 'object' && key !== 'resource') {
          for (let subKey in data[key]) {
            if (key !== 'tdc_bin') {
              dataForm.append(`${key}.${subKey}`, data[key][subKey]);
            }
          }
        } else {
          dataForm.append(key, data[key]);
        }
      }
    }

    const dataSend = binaryFile ? dataForm : data;

    editProvider({
      data: dataSend,
      callback: () => {
        showMessage({
          message: t('editUserSuccess', { name: name }),
          config: TOAST_CONFIG.SUCCESS,
        });
        history.push('/dashboard');
        /*fetchBanks({
          params: {
            id,
            main_bank: true,
          }
        })*/
        this.setState(this.getInitialState());
      },
    });
  };

  resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 300, 55, 'png', 100, 0,
      uri => {
        //resolve(uri);
        this.setState({
          binaryFile: uri,
        });
      },
      'file'
    );
  });

  handleFileChange = (event) => {
    const {
      t,
      showMessage,
    } = this.props;
    const files = Array.from(event.target.files);
    const arrays = files[0].name;
    let ext = arrays.split('.');

    if (ext[ext.length - 1].toUpperCase() === 'JPG' || ext[ext.length - 1].toUpperCase() === 'PNG') {
      if (parseInt(files[0].size / 1024) > 3072) {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('fileSizeExceeded')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      } else {
        //const image = this.resizeFile(files[0]);
        this.resizeFile(files[0]);
        /*let reader = new FileReader();
        reader.onload = (e) => {

        }*/
        this.setState({
          //binaryFile: files[0],
          file: event.target.value,
        });
      }
    } else {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('errorLoad', {
          type: 'JPG',
          type1: 'PNG'
        })}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }
  };

  handleInputChangeTypeDocument = (event) => {
    const {
      fetchDocumentTypes,
    } = this.props;
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    fetchDocumentTypes({
      params: { legal_type: value === 'legal' ? 'J' : 'F' },
      callback: (data) => {
        this.setState({
          type_legal_person: value === 'legal' ? 'JUR' : 'FIS',
          typeDocument: data,
          [name]: value,
        });
      }
    });
  };

  render() {
    const {
      t,
      clients: { loading: clientLoading },
      documentType: { loading: documentTypesLoading },
      providers: { loading: providerLoading },
      role: { list: roleList, loading: roleLoading },
      router: { location: { pathname } },
    } = this.props;
    const {
      name,
      documentNumber,
      email,
      registerLoading,
      bankType,
      typePerson,
      typeDocument,
      phone,
      documentTypeUser,
    } = this.state;
    const loading = clientLoading || documentTypesLoading || providerLoading || roleLoading || registerLoading;

    return (
      <div>
        {loading && <Loading/>}
        <div className="columns">
          <div className="column is-full">
            <MainBankForm
              handleInputChange={this.handleInputChange}
              onSubmit={pathname === '/edit-admin-bank' ? this.editProvider : this.createProvider}
              email={email}
              name={name}
              title={pathname === '/edit-admin-bank' ? t('edit') : t('createBank')}
              documentNumber={documentNumber}
              handleSelectChange={this.handleSelectChange}
              t={t}
              mark={typePerson}
              handleInputChangeTypeDocument={this.handleInputChangeTypeDocument}
              documentTypesList={typeDocument}
              documentTypeUser={documentTypeUser}
              roleList={roleList}
              phone={phone}
              bankType={bankType}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreateBank.propTypes = {
  history: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  createProvider: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


const mapStateToProps = ({
                           clients,
                           documentType,
                           auth,
                           router,
                           role,
                           gateway,
                           providers,
                         }) => ({
  clients,
  documentType,
  auth,
  router,
  role,
  gateway,
  providers,
});

export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...roleActions,
    ...clientsActions,
    ...gatewayActions,
  }),
  withNamespaces(),
)(CreateBank);
