import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading, Panel, TableSimple } from '../../components';
import { authActions } from '../../state/ducks/auth';
import { transactionsActions } from '../../state/ducks/transactions';
import { formatCurrencyCl, formatDate } from '../../util';
import { API_URL } from '../../config/constants';

class ManualResolution extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getInitialState = () => ({
    id: '',
    name: '',
  });

  componentDidMount() {
    const {
      listTransactionsSender,
      listTransactionsReceiver,
      auth: { user: { user_id, b2b: { type_user } } },
    } = this.props;
    if (type_user === 'buyer') {
      listTransactionsSender({
        params: {
          user_id,
        },
      });
    }
    if (type_user === 'seller' || type_user === 'seller_assist_card') {
      listTransactionsReceiver({
        params: {
          user_id,
        },
      });
    }
    /*listTransactionsSender({
      params: {
        id,
      },
    });*/
  }

  getGridSettings = () => {
    const {
      t,
      transactions: { sender, receiver },
      /*listTransactionsSender,
      listTransactionsReceiver,
      setTransaction,
      showMessage,*/
      fetchTransaction,
      auth: { user: { b2b: { type_user } } },
      history,
    } = this.props;
    const userTypes = ['buyer'];
    const columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: 'invoicesNumber', // Required because our accessor is not a string
        Header: t('invoiceNumber'),
        accessor: d => `${d.invoice_number}`,
      },
      {
        id: 'paymentDate', // Required because our accessor is not a string
        Header: userTypes.includes(type_user) ? t('datePayment'): t('paymentDate'),
        accessor: d => formatDate(d.due_date),
      },
      {
        id: 'totalPay', // Required because our accessor is not a string
        Header: t('totalPay'),
        accessor: d => formatCurrencyCl(d.adjusted_amount),
      },
      {
        id: 'currency',
        Header: t('currency'),
        accessor: d => d.pay_currency_id !== null ? `${d.pay_currency_id}` : '',
      },
      {
        id: 'client', // Required because our accessor is not a string
        Header: type_user === 'buyer' ? t('providerName') : t('buyerName'),
        headerClassName: 'wordwrap',
        accessor: d => type_user === 'buyer' ? `${d.receiver.idPerson.name}` : `${d.sender.idPerson.name}`,
      },
      /*{
        id: 'provider', // Required because our accessor is not a string
        Header: t('provider'),
        accessor: d => `${d.receiver.idPerson.name}`,
      },*/
      {
        id: 'description', // Required because our accessor is not a string
        Header: t('description'),
        accessor: d => `${d.description}`,
      },
      {
        id: 'status', // Required because our accessor is not a string
        Header: t('status'),
        accessor: d => `${d.senderStatus.description}`,
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        accessor: d => `${d.id} `,
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              {cellInfo.original.resource
                && (
                  <td>
                    <a
                      href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.resource}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button"
                    >
                        <span className="icon" title={t('download')}>
                          <a className="ml-">
                            <i className="fas fa-download"/>
                          </a>
                        </span>
                    </a>
                  </td>
                )}
              <td>
                <button
                  className="button is-primary is-inverted"
                  onClick={() => {
                    fetchTransaction({
                      params: {
                        id: cellInfo.original.id,
                        role: 'sender',
                      },
                      callback: () => {
                        history.push('/invoice-detail');
                      }
                    });
                  }}
                >
                  <span className="icon" title={t('detail')}>
                    <i className="far fa-file-alt"/>
                  </span>
                </button>
              </td>
              {/*<td>
                <button
                  className="button is-success"
                  onClick={() => {
                    setTransaction({
                      params: {
                        id: cellInfo.original.id,
                      },
                      callback: () => {
                        showMessage({
                          message: t('invoiceMarkPaid', { invoiceNumber: cellInfo.original.invoice_number }),
                          config: TOAST_CONFIG.SUCCESS,
                        });
                        listTransactionsSender({
                          params: {
                            id,
                          },
                        });
                      }
                    });
                  }}
                >
                  {t('paidOut')}
                </button>
              </td>*/}
            </tr>
            </tbody>
          </table>
        ),
      },
    ];
    const data = type_user === 'buyer' ? sender : receiver;
    const title = t('invoiceManualResolution');
    return {
      columns,
      data,
      title,
    };
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      transactions: { loading: transactionsLoading },
    } = this.props;
    const { title, columns, data } = this.getGridSettings();
    return (
      <div className="dashboard">
        {(transactionsLoading) && <Loading/>}
        <div className="columns is-multiline">
          <div className="column is-full">
            <Panel headingText={title}>
              <div>
                <TableSimple data={data} columns={columns}/>
              </div>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

ManualResolution.propTypes = {
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  listTransactionsSender: PropTypes.func.isRequired,
};

const mapStateToProps = ({ transactions, auth, }) => (
  {
    transactions,
    auth,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
    ...authActions,
  }),
  withNamespaces(),
)(ManualResolution);
