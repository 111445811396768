import React, { Component, Fragment } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { authActions } from '../../../state/ducks/auth';
import { providersActions } from '../../../state/ducks/providers';
import { TOAST_CONFIG } from '../../../config/constants';
import { ENV } from '../../../config/env';
import Fingerprint2 from 'fingerprintjs2';
import HCaptcha from '@hcaptcha/react-hcaptcha';
//UI COMPONENTS
import { Loading, Toggle, Modal } from '../../../components';
import FormLogin from './components/FormLogin';
import FormRecover from './components/FormRecover';
// ASSETS
import logoChile from '../../../resources/images/assets/logo.svg';
import logoAmex from '../../../resources/images/assets/logo-amex.png';
import bacLogo from '../../../resources/images/assets/santander-logo.png';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.captcha = React.createRef();
  }

  componentDidMount = () => {
    const { auth: { language } } = this.props;
    this.setState({ language });
  
    const promise = new Promise( (resolve) => {

      const options = {
        excludes: {
          touchSupport: true,
          fontsFlash: true,
          screenResolution: true,
          pixelRatio: true,
          colorDepth: true,
          availableScreenResolution: true,
        }
      };

      Fingerprint2.get(options, function (components) {
        const values = components.map(function (component) {
          return component.value;
        });
        const murmur = Fingerprint2.x64hash128(values.join(''), 31);
        resolve(murmur);
      });
    });
    
    promise.then( result => {
      this.setState({device_info: result});
    });

    if(ENV === 'local' || ENV === 'dev'){
      this.setState({robot: false});
    }
  };
  
  handleInputChange = (event) => {
    const {
      target: {
        type, checked, value: targetValue, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal) => {
    const { i18n } = this.props;
    this.setState({
      language: newVal.target.value,
    });
    i18n.changeLanguage(newVal.target.value);
    // eslint-disable-next-line no-undef
    window.location.reload();
  };

  handleClickIcon = () => {
    const { inputType } = this.state;
    const isText = (inputType === 'text');
    this.setState({
      inputType: isText ? 'password' : 'text',
      icon: isText ? 'fa fa-eye' : 'fa fa-eye-slash',
    });
  };

  handleSubmit = (event) => {
    
    event.preventDefault();
    const { signIn, showMessage, t } = this.props;
    const { ...data } = this.state;
    const { robot } = this.state;

    if(!robot){
      signIn({ 
        data,
        callback: res => {
          if(!res.success && ENV !== 'local' && ENV !== 'dev') {
            this.captcha.current.resetCaptcha()
            this.setState({robot: true})
          }
        } 
      });
    }else{
      showMessage({
        message: t('verifyHuman'),
        config: TOAST_CONFIG.INFO,
      });
    }
  };

  handleReset = () => {
    const { passwordReset, showMessage } = this.props;
    const { username } = this.state;
    const email = username;
    passwordReset({
      email,
      callback: (response) => {
        showMessage({
          message: response.data.message,
          config: TOAST_CONFIG.SUCCESS,
        });
        this.resetState();
        if(ENV === 'local' || ENV === 'dev'){
          this.setState({robot: false});
        }
      },
    });
  };

  getInitialState = () => ({
    language: '',
    username: '',
    password: '',
    device_info: '',
    robot: true,
    icon: 'fa fa-eye',
    inputType: 'password'
  });

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleVerificationSuccess = (token, ekey) => {
    this.setState({
      robot: false,
    });
  }

  render() {
    const {
      auth: { logged, loading, user, user: { b2b: { type_user } } },
      t,
    } = this.props;
    const { language, username, password, device_info, icon, inputType } = this.state;
    const
      firstLogin = user.first_login === 1,
      noRegisteredCards = user.b2b.registered_card === 0,
      has2FA = user.b2b.password_changed === -1,
      hasToResetPassword = user.b2b.password_changed === 0,
      isBuyer = type_user === 'buyer';

    if (logged === true) {
      if(firstLogin){
        if (isBuyer && noRegisteredCards) {
          return <Redirect to="/register-card"/>;
        }
        return <Redirect to="/dashboard"/>;
      } else if (hasToResetPassword) {
        return <Redirect to="/recover-password"/>;
      } else if (has2FA) {
        return <Redirect to="/validate-code"/>;
      } else {
        return <Redirect to="/dashboard"/>;
      }
    }

    return (
      <Toggle>
        {({ toggled, onToggle }) => (
          <Fragment>
            {
              toggled
              && (
                <Modal onToggle={onToggle}>
                  <FormRecover
                    handleReset={this.handleReset}
                    handleInputChange={this.handleInputChange}
                    data={{ username }}
                    onToggle={onToggle}
                  />
                </Modal>
              )
            }
            <div className="SignIn">
              <ToastContainer/>
              {loading && <Loading/>}
              <section className="hero is-info is-fullheight">
                <div className="headerLogin">
                  <div>
                    <div className="footer-left">
                      <img src={bacLogo} alt="logo" style={{
                        width: '115px',
                        height: '60px',
                        display: 'block'
                      }}/>
                    </div>
                    <div className="footer-right" style={{
                      alignSelf: 'flex-end',
                      marginBottom: '1rem',
                      float: 'right'
                    }}>
                      <div className="field">
                        <div className="content-select">
                          <select
                            name="language"
                            value={language}
                            onChange={this.handleSelectChange}
                          >
                            <option value="es">{t('esp')}</option>
                            <option value="pt">{t('por')}</option>
                            <option value="en">{t('eng')}</option>
                          </select>
                          <i/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hero-body">
                  <div className="container" style={{
                    marginTop: '13vh',
                    width: '300px'
                  }}>
                    <div className="column panel fadeInUp animated"
                        style={{ background: '#B3B2B2' }}>
                      <div className="contentLogo" >
                        <div className="centerImg" >
                          <img src={logoChile} alt="logo"/>
                        </div>
                      </div>
                      &nbsp;&nbsp;
                      <FormLogin
                        handleSubmit={this.handleSubmit}
                        handleInputChange={this.handleInputChange}
                        handleClickIcon={this.handleClickIcon}
                        data={{
                          username,
                          password,
                          device_info
                        }}
                        options={{
                          icon,
                          inputType
                        }}
                      >
                        {(ENV !== 'local' && ENV !== 'dev') &&
                        <div className="recaptcha-box">
                          <div className="captcha-container">
                            <HCaptcha
                              hl={language}
                              sitekey="15d6a7e0-1ba4-4252-90be-f52d7260c679"
                              onVerify={(token,ekey) => this.handleVerificationSuccess(token, ekey)}
                              ref={this.captcha}
                            />
                          </div> 
                        </div>}
                      </FormLogin>
                      <div className="column is-full">
                        <div className="center link-chile">
                          {/* eslint-disable-next-line */}
                          <a href={'#'}
                            onClick={() => {
                              onToggle({
                                isEdit: true,
                              });
                            }}
                          >
                            {t('forgotPassword')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="footerLogin">
                <div style={{
                  width: '170px',
                  height: '35px',
                  float: 'right'
                }}>
                  <img src={logoAmex} alt="logo"/>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Toggle>
    );
  }
}

SignIn.propTypes = {
  signIn: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
  passwordReset: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
  i18n: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, providers }) => (
  {
    auth,
    providers,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
    ...providersActions,
  }),
  withNamespaces(),
)(SignIn);
