import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Loading } from '../../components';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { TOAST_CONFIG } from '../../config/constants';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { itemActions } from '../../state/ducks/item';
import { clientsActions } from '../../state/ducks/clients';
import { leadActions } from '../../state/ducks/lead';
import PersonForm from '../../components/PersonForm';
import { roleActions } from '../../state/ducks/role';
import { flowApprovalActions } from '../../state/ducks/flowApproval';
import { currencyActions } from '../../state/ducks/currency';
import { cardActions } from '../../state/ducks/card';
import { authActions } from '../../state/ducks/auth';

class ConfirmCustomer extends Component {
    constructor(props) {
        super(props);
        const { location: { state } } = props;
        this.state = { ...this.getInitialState(), ...state };
    }

    getInitialState = () => ({
        name: '',
        documentType: -1,
        documentNumber: '',
        address: '',
        email: '',
        password: '',
        percentage: 0,
        period: -1,
        installments: 1,
        items: [],
        client: '',
        startCountingFrom: 1,
        baseProvider: 0,
        baseDays: 0,
        discountRate: 0,
        adminName: '',
        adminLastname: '',
        adminDocumentNumber: '',
        adminDocumentType: [],
        adminPosition: '',
        receiverDiscountShare: 100,
        clientPayment: 0,
        noRetain: false,
        edit: false,
        hidden: false,
        typeFlow: -1,
        code: '',
        regions: [],
        region: -1,
        communes: [],
        commune: -1,
        inputValue: '',
        city: '',
        paymentCurrency: -1,
        currency: -1,
        inputValueExecutive: '',
        executives: [],
        assistCard: '',
        business_name: '',
        unformattedRut: '',
        unformattedAdminRut: '',
        newBankingExec: [],
        countBankingExec: 0,
        typePerson: '',
        typeDocument: [],
        documentTypeUser: '',
        type_legal_person: '',
        provinces: [],
        province: -1,
        cantons: [],
        canton: -1,
        districts: [],
        district: -1,
        street: '',
        number: '',
        otherSign: '',
        countTradeInformation: 0,
        commerce_code: [{
            id: 0,
            commerce_code: '',
            terminal: '',
            currency: -1,
            currency_id: '',
            description: '',
        }],
        //cif: '',
        categories: [],
        category: '',
        username_csp: '',
        password_csp: '',
        confirm: true,
        phoneNumber: '',
        phoneNumberAdmin: '',
    });

    componentDidMount() {
        const {
            t,
            auth: { user },
            fetchCategories,
            fetchDocumentTypes,
            getCurrency,
            fetchRegions,
            getExecutive,
        } = this.props;

        getExecutive({
            params: { owner: user.b2b.bank_id },
            callback: (response) => {
                const exc = [];
                let j = 0;
                for (let i = 0; i < response.length + 1; i++) {
                    if (i === 0) {
                        exc.push({
                            bank_id: response.length === 0 ? user.b2b.bank.id : response[i].bank_id,
                            lastname: '',
                            id: -1,
                            email: t('businessExecutive'),
                            name: t('addNew'),
                        });
                    } else {
                        exc.push({
                            bank_id: response[j].bank_id,
                            email: response[j].email,
                            id: response[j].id,
                            lastname: response[j].lastname,
                            name: response[j].name,
                        });
                        j += 1;
                    }
                }
                this.setState({ exc: exc });
            }
        });

        getCurrency({});

        fetchDocumentTypes({
            params: { legal_type: 'F' },
            callback: (data) => {
                let documentType = (user.b2b.admin_document_type !== null) ?
                  (data.map(
                    (documentTypeMap, index) => ({
                        ...documentTypeMap,
                        index
                    })
                  )
                    .filter(documentTypeFil => documentTypeFil.document_type
                      === user.b2b.admin_document_type)[0].index) : '';
                this.setState({
                    adminDocumentType: data,
                    documentType: documentType
                });
            }
        });

        fetchCategories({
            callback: (response) => {
                let category = (user.items.length > 0) ?
                  (response.map(
                    (documentTypeMap, index) => ({
                        ...documentTypeMap,
                        index
                    })
                  )
                    .filter(documentTypeFil => documentTypeFil.id
                      === parseInt(user.items[0].category_id))[0].index) : '';
                this.setState({
                    categories: response,
                    category: category
                });
            }
        });

        fetchRegions({
            callback: (response) => {
                this.setState({ provinces: response });
            }
        });

        this.setState(this.arrayProvider);
    }

    arrayProvider = () => {

        const {
            auth: { user },
            providers: { list: providerList },
            documentType: { list: documentTypeList },
            fetchCommunes, fetchRegions, fetchCities,
            currency: { list: listCurrency },
            fetchDocumentTypes,
        } = this.props;
        const { categories } = this.state;
        let documentType = -1;

        fetchDocumentTypes({
            params: {
                legal_type: user.b2b.type_legal_person === null ?
                  user.document_type === 'Cédula jurídica' ? 'J' : 'F'
                  :
                  user.b2b.type_legal_person === 'JUR' ? 'J' : 'F'
            },
            callback: (data) => {
                documentType = data.findIndex(x => x.document_type = user.document_type);
                this.setState({
                    documentTypeUser: documentType,
                    typeDocument: data,
                    typePerson:user.document_type === 'Cédula jurídica' ? 'legal' : 'person',
                    type_legal_person: user.document_type === 'Cédula jurídica' ? 'JUR' : 'FIS',
                });
            }
        });

        /*if (user.b2b.admin_document_type !== '' && user.b2b.admin_document_type !== null) {
            fetchDocumentTypes({
                params: { legal_type: 'F' },
                callback: (data) => {
                    documentType = data.findIndex(x => x.document_type = user.b2b.admin_document_type);
                    this.setState({
                        documentType: documentType,
                    });
                }
            });
        }*/

        const item = [];
        for (let i = 0; i < user.items.length; i++) {
            item.push({
                id: user.items[i].id,
                classification: user.items[i].classification,
                code: user.items[i].code,
                description: user.items[i].description
            });
        }

        if(user.b2b.canton !== null){
            fetchCommunes({
                //providerList.seller[0].province.id,
                callback: (response) => {
                    this.setState({
                        cantons: response,
                        canton: response.findIndex(x => x.id == user.b2b.canton.id),
                    });
                }
            });
            fetchCities({
                canton_id: user.b2b.canton.id,
                callback: (response) => {
                    this.setState({
                        districts: response,
                        district: response.findIndex(x => x.id == user.b2b.district.id)
                    });
                }
            });
        }

        /*if (user.b2b.province !== null) {
            fetchRegions({
                callback: (response) => {
                    this.setState({ province: response.findIndex(x => x.id == user.b2b.province.id) });
                }
            });
            fetchCommunes({
                province_id: user.b2b.province.id,
                callback: (response) => {
                    this.setState({
                        cantons: response,
                        canton: response.findIndex(x => x.id == user.b2b.canton.id)
                    });
                }
            });
            fetchCities({
                canton_id: user.b2b.canton.id,
                callback: (response) => {
                    this.setState({
                        districts: response,
                        district: response.findIndex(x => x.id == user.b2b.district.id)
                    });
                }
            });
        }*/

        const executives = [];
        for (let i = 0; i < user.banking_executive.length; i++) {
                executives.push({
                id: user.banking_executive[i].id,
                name: user.banking_executive[i].name,
                lastname: user.banking_executive[i].lastname,
                email: user.banking_executive[i].email
            });
        }

        const commerce_code = [];
        if (user.commerce_codes.length === 0) {
            commerce_code.push({
                id: 0,
                commerce_code: '',
                terminal: '',
                currency: -1,
                currency_id: '',
                description: '',
            });
        } else {
            for (let i = 0; i < user.commerce_codes.length; i++) {

                commerce_code.push({
                    id: user.commerce_codes[i].id,
                    commerce_code: user.commerce_codes[i].commerce_code,
                    terminal: user.commerce_codes[i].terminal,
                    currency: user.commerce_codes[i].currency_id !== null ? (listCurrency.map(
                      (listCurrencyTypeMap, index) => ({
                          ...listCurrencyTypeMap,
                          index
                      })
                    )
                      .filter(listCurrencyTypeFil => listCurrencyTypeFil.id
                        === user.commerce_codes[i].currency_id)[0].index) : '',
                    currency_id: user.commerce_codes[i].currency_id,
                    description: user.commerce_codes[i].description
                });
            }
        }

        this.setState({
            name: user.name,
            documentNumber: user.document_number,
            //cif: user.b2b.cif !== null ? user.b2b.cif : '',
            street: user.b2b.address !== null ? user.b2b.address.street : '',
            //number: user.b2b.address !== null ? user.b2b.address.number : '',
            //otherSign: user.b2b.address !== null ? user.b2b.address.other_sign : '',
            items: item,
            commerce_code,
            username_csp: user.username_csp === null ? '' : user.username_csp,
            password_csp: user.password_csp === null ? '' : user.password_csp,
            executives,
            business_name: user.b2b.business_name === null ? '' : user.b2b.business_name,
            adminName: user.b2b.admin_name,
            adminLastname: user.b2b.admin_lastname,
            adminDocumentNumber: user.b2b.admin_document_number === null ? '' : user.b2b.admin_document_number,
            adminPosition: user.b2b.admin_position,
            email: user.email,
            edit: true,
            //category: user.items[0].category_id !== null ? categories.findIndex(x => x.id == user.items[0].category.id) : 0,
            phoneNumber: user.phone !== null ? user.phone.phone_number : '',
            phoneNumberAdmin: user.b2b.admin_phone_number,
        });
    }

    handleInputChange = (event) => {
        const {
            target: {
                checked, value: targetValue, type, name,
            },
        } = event;
        const value = type === 'checkbox' ? checked : targetValue;
        this.setState({
            [name]: value,
        });
    }

    formatNumber = (n) => {
        if (n.length < 14) {
            return n.replace(/\D/g, '').replace(/([0-9]{14})$/);
        } else {
            return;
        }
    };

    handleInputChange = (event) => {
        const {
            target: {
                checked, value: targetValue, type, name,
            },
        } = event;
        const value = type === 'checkbox' ? checked : targetValue;
        this.setState({
            [name]: value,
        });
    }

    handleInputChangeAdminDocument = (event) => {
        const {
            target: {
                checked, value: targetValue, type, name,
            },
        } = event;

        const {
            adminDocumentType,
        } = this.state;

        const {
            t,
            showMessage,
        } = this.props;

        const value = type === 'checkbox' ? checked : targetValue;

        if ((name === 'adminDocumentNumber' && adminDocumentType === 0)) {
            let num = this.formatNumber(event.target.value);
            this.setState({
                [name]: (num),
            });
        } else {
            this.setState({
                [name]: value,
            });
        }
    }

    handleSelectChange = (newVal, label) => {
        const {
            fetchCities,
            t,
            showMessage,
            router: { location: { pathname } },
            fetchItems,
        } = this.props;

        const {
            categories,
        } = this.state;

        if (label.name === 'currency' && pathname === '/confirm-provider') {
            showMessage({
                message: <strong style={{ color: '#ffffff' }}>{t('changeCode')}</strong>,
                config: TOAST_CONFIG.ERROR,
            });
        }
        if(label.name === 'category'){
            fetchItems({
                category_id: categories[newVal.value].id,
            });
        }
        this.setState({
            [label.name]: newVal.value,
        });
    }

    handleItemChange = (newValue) => {
        this.setState({ items: newValue });
    }

    handleExecutivesChange = (newValue, event) => {
        const {
            auth,
        } = this.props;
        const {
            newBankingExec,
        } = this.state;

        if (event.action === 'clear') {
            this.setState({
                executives: newValue,
                newBankingExec: [],
            });
        } else {
            if (event.action === 'remove-value' && event.removedValue.id === -1) {
                this.setState({
                    executives: newValue,
                    newBankingExec: [],
                });
            } else {
                if (newValue[newValue.length - 1].id === -1) {
                    newBankingExec[0] = {
                        id: 0,
                        name: '',
                        lastname: '',
                        email: '',
                        bank_id: auth.user.b2b.id,
                    };
                    this.setState({
                        executives: newValue,
                        newBankingExec: newBankingExec
                    });
                } else {
                    this.setState({
                        executives: newValue
                    });
                }
            }
        }
    }

    handleRegionChange = (newVal, label) => {
        const { fetchCommunes } = this.props;
        const { provinces } = this.state;

        this.setState({
            [label.name]: newVal.value,
        });

        fetchCommunes({
            province_id: provinces[newVal.value].id,
            callback: (response) => {
                this.setState({
                    cantons: response,
                });
            }
        });
    }

    handleCantonChange = (newVal, label) => {
        const { fetchCities } = this.props;
        const { cantons } = this.state;

        this.setState({
            [label.name]: newVal.value,
        });

        fetchCities({
            canton_id: cantons[newVal.value].id,
            callback: (response) => {
                this.setState({
                    districts: response,
                });
            }
        });
    }

    handleOnInputChange = (newValue) => {
        this.setState({ inputValue: newValue });
        return newValue;
    }

    handleOnInputChangeExecutive = (newValue) => {
        this.setState({ inputValueExecutive: newValue });
        return newValue;
    }

    handleInputChangeNewExc = (event) => {
        const {
            target: {
                checked, value: targetValue, type, name,
            },
        } = event;
        const {
            auth: { user: { b2b: { id } } },
        } = this.props;
        const value = type === 'checkbox' ? checked : targetValue;

        const executive = name.split('-');

        let newBankingExec = [...this.state.newBankingExec];

        switch (executive[0]) {
            case 'name':
                newBankingExec[executive[1]] = {
                    id: Number(executive[1]),
                    name: value,
                    lastname: newBankingExec[executive[1]].lastname,
                    email: newBankingExec[executive[1]].email,
                    bank_id: id,
                };
                break;
            case 'lastname':
                newBankingExec[executive[1]] = {
                    id: Number(executive[1]),
                    name: newBankingExec[executive[1]].name,
                    lastname: value,
                    email: newBankingExec[executive[1]].email,
                    bank_id: id,
                };
                break;
            case 'email':
                newBankingExec[executive[1]] = {
                    id: Number(executive[1]),
                    name: newBankingExec[executive[1]].name,
                    lastname: newBankingExec[executive[1]].lastname,
                    email: value,
                    bank_id: id,
                };
                break;
        }
        this.setState({ newBankingExec });
    }

    handleInputChangeTypeDocument = (event) => {
        const {
            fetchDocumentTypes,
        } = this.props;

        const {
            target: {
                checked, value: targetValue, type, name,
            },
        } = event;
        const value = type === 'checkbox' ? checked : targetValue;

        fetchDocumentTypes({
            params: { legal_type : value === 'legal' ? 'J' : 'F' },
            callback: (data) => {
                this.setState({
                    type_legal_person: value === 'legal' ? 'JUR' : 'FIS',
                    typeDocument: data,
                    [name]: value,
                });
            }
        });
    }

    handleInputChangeTradeInformation = (event) => {
        const {
            currency: { list }
        } = this.props;
        const {
            target: {
                checked, value: targetValue, type, name,
            },
        } = event;
        const {
            auth: { user: { b2b: { id } } },
        } = this.props;

        const value = type === 'checkbox' ? checked : targetValue;
        const information = name.split('-');
        let commerce_code = [...this.state.commerce_code];

        switch (information[0]) {
            case 'commerce_code':
                commerce_code[information[1]] = {
                    id: Number(information[1]),
                    commerce_code: value,
                    terminal: commerce_code[information[1]].terminal,
                    currency: commerce_code[information[1]].currency,
                    currency_id: commerce_code[information[1]].currency_id,
                    description: commerce_code[information[1]].description,
                };
                break;
            case 'terminal':
                commerce_code[information[1]] = {
                    id: Number(information[1]),
                    commerce_code: commerce_code[information[1]].commerce_code,
                    terminal: value,
                    currency: commerce_code[information[1]].currency,
                    currency_id: commerce_code[information[1]].currency_id,
                    description: commerce_code[information[1]].description,
                };
                break;
            case 'currency':
                commerce_code[information[1]] = {
                    id: Number(information[1]),
                    commerce_code: commerce_code[information[1]].commerce_code,
                    terminal: commerce_code[information[1]].terminal,
                    currency: value,
                    currency_id: list[value].id,
                    description: commerce_code[information[1]].description,
                };
                break;
            case 'description':
                commerce_code[information[1]] = {
                    id: Number(information[1]),
                    commerce_code: commerce_code[information[1]].commerce_code,
                    terminal: commerce_code[information[1]].terminal,
                    currency: commerce_code[information[1]].currency,
                    currency_id: commerce_code[information[1]].currency_id,
                    description: value,
                };
                break;
        }
        this.setState({ commerce_code });
    }

    handleSelectCurrency = (newVal, label) => {
        const {
            currency: { list },
        } = this.props;

        const cycles1 = label.name.split('-');
          //const inputName = cycles1[0];
        const index = cycles1[1];

        let commerce_code = [...this.state.commerce_code];

        commerce_code[index] = {
            id: index,
            commerce_code: commerce_code[index].commerce_code,
            terminal: commerce_code[index].terminal,
            currency: newVal.value,
            currency_id: list[newVal.value].id,
            description: commerce_code[index].description,
        };

        this.setState({ commerce_code });
    }

    addTradeInformation = () => {
        const {
            countTradeInformation,
        } = this.state;
        let count = 0,
          i = 1;
        count = countTradeInformation + i;

        let variable = {
            id: count,
            commerce_code: '',
            terminal: '',
            currency_id: -1,
            description: '',
        };
        this.state.commerce_code.push(variable);

        this.setState({
            countTradeInformation: count
        });
    }

    deleteTradeInformation = (index) => {
        const {
            commerce_code,
        } = this.state;
        this.setState(commerce_code.splice(index, 1));
    }

    addBankingExec = () => {
        const {
            auth,
            showMessage,
            t,
        } = this.props;
        const {
            newBankingExec,
            countBankingExec,
        } = this.state;
        let count = 0,
          i = 1;
        count = countBankingExec + i;

        if (newBankingExec[newBankingExec.length - 1].email === '') {
            showMessage({
                message: <strong style={{ color: '#ffffff' }}>{t('mandatoryFieldsRequired')}</strong>,
                config: TOAST_CONFIG.ERROR,
            });
        } else {
            let variable = {
                id: count,
                name: '',
                lastname: '',
                email: '',
                bank_id: auth.user.b2b.id,
            };
            this.state.newBankingExec.push(variable);
        }
        this.setState({
            countBankingExec: count
        });
    }

    deleteBankingExec = (index) => {
        const {
            newBankingExec,
        } = this.state;
        this.setState(newBankingExec.splice(index, 1));
    }

    array_executives = (executives) => {
        let variable = [];

        for (let i = 0; i < executives.length; i++) {
            if (executives[i].id !== -1) {
                variable.push(Number(executives[i].id));
            }
        }

        return variable;
    };

    createProvider = () => {

        const {
            createProvider,
            auth,
            t,
            showMessage,
            createPaymentCondition,
            documentType: { list: documentTypeList },
            deleteLead,
            clients,
            role: { list: roleList },
            router: { location: { pathname } },
            providers: { list: providerList },
            editUser,
            history,
            flowApproval: { list: typeFlows },
            currency: { list: listCurrency },
            getUserInfo,
        } = this.props;

        const {
            name,
            documentType,
            documentNumber,
            email,
            address,
            percentage,
            items,
            adminName,
            adminLastname,
            adminDocumentNumber,
            adminDocumentType,
            adminPosition,
            districts,
            district,
            executives,
            business_name,
            newBankingExec,
            documentTypeUser,
            typeDocument,
            type_legal_person,
            street,
            number,
            otherSign,
            username_csp,
            password_csp,
            phoneNumber,
            phoneNumberAdmin,
        } = this.state;

        const data = {
            id_user: auth.user.user_id,
            name,
            type_legal_person,
            documentType: typeDocument[documentTypeUser].id,
            documentNumber: documentNumber,
            email,
            items,
            address,
            admin_name: adminName,
            admin_lastname: adminLastname,
            admin_document_number: adminDocumentNumber,
            admin_document_type: documentType !== -1 ? adminDocumentType[documentType].document_type : '',// 'RUT',
            admin_position: adminPosition,
            admin_phone_number: phoneNumberAdmin,
            percentage,
            //days: this.getDaysInv(period),
            type: 'B',
            process_type: null,
            banking_executive: this.array_executives(executives),
            new_banking_executive: newBankingExec,
            business_name,
            username_csp,
            password_csp,
            b2b_address: {
                street,
                number,
                other_sign: otherSign,
                district_id: districts[district].id,
            },
            phone: {
                phone_number: phoneNumber,
                country_code: '+56',
                is_main: 1,
            },
            //commerce_code,
            //cif,
        };

        editUser({
            data,
            callback: () => {

                getUserInfo({
                    params: {
                        user: auth.user.user_id,
                    },
                    callback: (response) => {
                        history.push('/dashboard');
                        showMessage({
                            message: t('dataConfirmedSuccessfully'),
                            config: TOAST_CONFIG.SUCCESS,
                        });
                    }
                })
            },
        });

    }

    render(){

        const {
            t,
            auth: { user: { profile: { role } } },
            providers: { loading },
            currency: { list },
            item: { list: itemList },
        } = this.props;

        const{
            name,
            code,
            documentType,
            documentNumber,
            //cif,
            address,
            street,
            number,
            otherSign,
            adminName,
            adminLastname,
            adminDocumentNumber,
            items,
            //itemList,
            email,
            password,
            baseDays,
            percentage,
            discountRate,
            adminDocumentType,
            adminPosition,
            receiverDiscountShare,
            clientPayment,
            typeFlow,
            period,
            edit,
            hidden,
            //role,
            regions,
            region,
            cantons,
            canton,
            districts,
            district,
            communes,
            commune,
            inputValue,
            inputValueExecutive,
            city,
            //list,
            exc,
            executives,
            assistCard,
            assist,
            business_name,
            newBankingExec,
            documentTypeUser,
            typeDocument,
            typePerson,
            province,
            provinces,
            commerce_code,
            categories,
            category,
            username_csp,
            password_csp,
            confirm,
            phoneNumber,
            phoneNumberAdmin,
        } = this.state;

        return (
          <div>
              {(loading) && <Loading/>}
              <div className="columns">
                  <div className="column is-full">
                      <PersonForm
                        t={t}
                        title="Confirmar Datos"
                        onSubmit={this.createProvider}
                        handleInputChange={this.handleInputChange}
                        handleInputChangeAdminDocument={this.handleInputChangeAdminDocument}
                        name={name}
                        handleSelectChange={this.handleSelectChange}
                        handleItemChange={this.handleItemChange}
                        handleExecutivesChange={this.handleExecutivesChange}
                        documentNumber={documentNumber}
                        address={address}
                        documentType={documentType}
                        items={items}
                        itemList={itemList}
                        email={email}
                        password={password}
                        baseDays={baseDays}
                        percentage={percentage}
                        discountRate={discountRate}
                        person="provider"
                        adminName={adminName}
                        adminLastname={adminLastname}
                        adminDocumentNumber={adminDocumentNumber}
                        adminDocumentType={adminDocumentType}
                        adminPosition={adminPosition}
                        handlePercentageChange={this.handlePercentageChange}
                        receiverDiscountShare={receiverDiscountShare}
                        clientPayment={clientPayment}
                        //typeFlows={this.labelType(typeFlows)}
                        typeFlow={typeFlow}
                        period={period}
                        edit={edit}
                        hidden={hidden}
                        code={code}
                        role={role}
                        regions={regions}
                        region={region}
                        handleRegionChange={this.handleRegionChange}
                        handleCantonChange={this.handleCantonChange}
                        cantons={cantons}
                        canton={canton}
                        districts={districts}
                        district={district}
                        communes={communes}
                        commune={commune}
                        handleOnInputChange={this.handleOnInputChange}
                        handleOnInputChangeExecutive={this.handleOnInputChangeExecutive}
                        inputValue={inputValue}
                        inputValueExecutive={inputValueExecutive}
                        city={city}
                        paymentCurrency={list}
                        executivesList={exc}
                        executives={executives}
                        assistCard={assistCard}
                        //exist={assist !== null ? assist.exist : ''}
                        business_name={business_name}
                        newBankingExec={newBankingExec}
                        addBankingExec={this.addBankingExec}
                        deleteBankingExec={this.deleteBankingExec}
                        handleInputChangeNewExc={this.handleInputChangeNewExc}
                        handleInputChangeTypeDocument={this.handleInputChangeTypeDocument}
                        documentTypeUser={documentTypeUser}
                        documentTypesList={typeDocument}
                        noRetain={typePerson}
                        provinces={provinces}
                        province={province}
                        street={street}
                        number={number}
                        otherSign={otherSign}
                        tradeInformation={commerce_code}
                        addTradeInformation={this.addTradeInformation}
                        deleteTradeInformation={this.deleteTradeInformation}
                        handleInputChangeTradeInformation={this.handleInputChangeTradeInformation}
                        handleSelectCurrency={this.handleSelectCurrency}
                        //cif={cif}
                        categories={categories}
                        category={category}
                        username_csp={username_csp}
                        password_csp={password_csp}
                        confirm={confirm}
                        client={true}
                        phoneNumber={phoneNumber}
                        pathName={true}
                        phoneNumberAdmin={phoneNumberAdmin}
                      />
                  </div>
              </div>
          </div>
        );
    }

}


const mapStateToProps = ({
                             clients, documentType, auth, item, role, router, providers, flowApproval, currency, card
                         }) => ({
    clients,
    documentType,
    auth,
    item,
    role,
    router,
    providers,
    flowApproval,
    currency,
    card,
});
export default compose(
  connect(mapStateToProps, {
      ...documentTypeActions,
      ...providersActions,
      ...paymentConditionsActions,
      ...itemActions,
      ...clientsActions,
      ...leadActions,
      ...roleActions,
      ...providersActions,
      ...flowApprovalActions,
      ...currencyActions,
      ...cardActions,
      ...authActions,
  }),
  withNamespaces(),
)(ConfirmCustomer);