const START_FETCH = 'transactions/START_FETCH';
const END_FETCH = 'transactions/END_FETCH';
const END_ERROR = 'transactions/END_ERROR';
const API_CALL = 'transactions/API_CALL';
const LIST_TRANSACTIONS_RECEIVER = 'transactions/LIST_TRANSACTIONS_RECEIVER';
const SET_TRANSACTIONS_RECEIVER = 'transactions/SET_TRANSACTIONS_RECEIVER';
const LIST_TRANSACTIONS_SENDER = 'transactions/LIST_TRANSACTIONS_SENDER';
const SET_TRANSACTIONS_SENDER = 'transactions/SET_TRANSACTIONS_SENDER';
const FETCH_TRANSACTIONS = 'transactions/FETCH_TRANSACTIONS';
const EXTRACT = 'transactions/EXTRACT';
const TRANSFER = 'transactions/TRANSFER';
const UPDATE_TRANSACTIONS = 'transactions/UPDATE_TRANSACTIONS';
const MESSAGE = 'transactions/MESSAGE';
const CLEAR = 'transactions/CLEAR';
const AUTHORIZE_TRANSACTION = 'transactions/AUTHORIZE_TRANSACTION';
const UPDATE_CHARGE = 'transactions/UPDATE_CHARGE';
const UPDATE_BALANCE = 'transactions/UPDATE_BALANCE';
const CHECK_BALANCE = 'transactions/CHECK_BALANCE';
const EDIT_TRANSACTION = 'transactions/EDIT_TRANSACTION';
const GET_TRANSACTION = 'transactions/GET_TRANSACTION';
const SAVE_TRANSACTION = 'transactions/SAVE_TRANSACTION';
const DELETE_TRANSACTION = 'transactions/DELETE_TRANSACTION';
const FETCH_TRANSACTION = 'transactions/const FETCH_TRANSACTION';
const LIST_TRANSACTION_ADMIN = 'transactions/const LIST_TRANSACTION_ADMIN';
const STAT_TRANSACTION = 'transactions/const STAT_TRANSACTION';
const SAVE_STAT_TRANSACTION = 'transactions/const SAVE_STAT_TRANSACTION';
const SET_TRANSACTION = 'transactions/SET_TRANSACTION';
const RATE_TRANSACTION = 'transactions/RATE_TRANSACTION';
const SAVE_RATE = 'transactions/SAVE_RATE';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  LIST_TRANSACTIONS_RECEIVER,
  SET_TRANSACTIONS_RECEIVER,
  LIST_TRANSACTIONS_SENDER,
  SET_TRANSACTIONS_SENDER,
  FETCH_TRANSACTIONS,
  EXTRACT,
  TRANSFER,
  UPDATE_TRANSACTIONS,
  MESSAGE,
  CLEAR,
  AUTHORIZE_TRANSACTION,
  CHECK_BALANCE,
  UPDATE_CHARGE,
  UPDATE_BALANCE,
  EDIT_TRANSACTION,
  GET_TRANSACTION,
  SAVE_TRANSACTION,
  DELETE_TRANSACTION,
  FETCH_TRANSACTION,
  LIST_TRANSACTION_ADMIN,
  STAT_TRANSACTION,
  SAVE_STAT_TRANSACTION,
  SET_TRANSACTION,
  RATE_TRANSACTION,
  SAVE_RATE,
};
