const START_FETCH = 'webpay/START_FETCH';
const END_FETCH = 'webpay/END_FETCH';
const END_ERROR = 'webpay/END_ERROR';
const API_CALL = 'webpay/API_CALL';
const CREATE_TRANSACTION = 'webpay/CREATE_TRANSACTION';
const GET_WEBPAY = 'webpay/GET_WEBPAY';
const RESULT_TRANSACTION = 'webpay/RESULT_TRANSACTION';
const MESSAGE = 'webpay/MESSAGE';
const ACCESS_TOKEN = 'webpay/ACCESS_TOKEN';
const TOKEN = 'webpay/TOKEN';
const REGISTER_PAYMENT = 'webpay/REGISTER_PAYMENT';
const LIST_CARDS = 'webpay/LIST_CARDS';
const CONFIRM_BILLING_CYCLE = 'webpay/CONFIRM_BILLING_CYCLE';
const CYCLE_CARD = 'webpay/CYCLE_CARD';
const ADD_ALIAS = 'webpay/ADD_ALIAS';

export default {
    START_FETCH,
    END_FETCH,
    END_ERROR,
    API_CALL,
    CREATE_TRANSACTION,
    RESULT_TRANSACTION,
    MESSAGE,
    GET_WEBPAY,
    ACCESS_TOKEN,
    TOKEN,
    REGISTER_PAYMENT,
    LIST_CARDS,
    CONFIRM_BILLING_CYCLE,
    CYCLE_CARD,
    ADD_ALIAS,
};