import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import { Loading, Panel, TableSimple, Toggle, } from '../../components';
import './styles.scss';
import SelectSingle from '../../components/SelectSingle';
import { clientsActions } from '../../state/ducks/clients';
import { providersActions } from '../../state/ducks/providers';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { statsActions } from '../../state/ducks/stats';
import { dateFilter, onInputOnlyNumbers, orderDate } from '../../util';

class PaymentConditionLog extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.selectTable = React.createRef();
    this.csvRef = React.createRef();
  }

  getInitialState = () => ({
    array: [],
    persons: [],
    person: -1,
    typePerson: false,
  });

  componentDidMount() {
    const {
      auth: { user: { b2b: { type_user } } },
      fetchStats,
      fetchRelatedUsers,
    } = this.props;

    if ( type_user !== 'admin' ) {
      if (type_user === 'issuing_bank' || type_user === 'acquiring_bank' ) {
        fetchStats({});
      } else {
        fetchRelatedUsers({
          callback: (response) => {
            this.setState({ array: response.length === 0 ? [] : response.related_users });
          }
        });
      }
    }
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = () => {
    const {
      t,
      auth: {
        user: {
          b2b: {
            type_user,
          },
        },
      },
      paymentConditions: { log },
    } = this.props;
    const {
      person,
    } = this.state;
    let columns;
    let data;
    let title;

    switch (type_user) {
      case 'admin':
      case 'buyer':
      case 'seller':
      case 'acquiring_bank':
      case 'issuing_bank':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'applicant', // Required because our accessor is not a string
            Header: t('applicant'),
            headerClassName: 'wordwrap',
            accessor: d => `${t(d.applicant)}`,
          },
          {
            id: 'applicants_name', // Required because our accessor is not a string
            Header: t('applicantName'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.applicants_name}`,
          },
          {
            id: 'customer_or_supplier_name', // Required because our accessor is not a string
            Header: type_user === 'issuing_bank' ? t('commerce') : t('client'),
            show: type_user === 'issuing_bank' || type_user === 'acquiring_bank',
            accessor: d => `${d.customer_or_supplier_name}`,
          },
          {
            id: 'arrangedDays', // Required because our accessor is not a string
            Header: t('arrangedDays'),
            headerClassName: 'wordwrap',
            accessor: d => d.days_agreed,
            sortMethod: (a, b) => Number(a)-Number(b),
            Filter: ({ filter, onChange }) =>
              <input
                onInput={onInputOnlyNumbers}
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : ''}
              />
          },
          {
            id: 'days_agreed_from', // Required because our accessor is not a string
            Header: t('startCountingFrom'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.days_agreed_from}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('applicationDate'),
            headerClassName: 'wordwrap',
            accessor: 'application_date',
            Cell: cellInfo => (
              <div>
                <span>{(cellInfo.original.application_date)}</span>
              </div>
            ),
            sortMethod: (a, b) => {orderDate(a, b)},
              filterMethod: (filter, row) => dateFilter(filter, row.application_date),
              Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d-]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'approved_date', // Required because our accessor is not a string
            Header: t('approvalDateReject'),
            headerClassName: 'wordwrap',
            accessor: 'approval_rejection_date',
            Cell: cellInfo => (
              <div>
                <span> {
                  (cellInfo.original.approval_status === 'Pendiente')
                    ? '' : (cellInfo.original.approval_rejection_date)
                }</span>
              </div>
            ),
            sortMethod: (a, b) => {orderDate(a, b)},
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('approvalStatus'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.approval_status}`,
          },
          {
            id: 'registration_status',
            Header: t('registrationStatus'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.registration_status}`,
          },
        ];
        data = person === -1 ? [] : log;
        title = t('changeOfCommercialConditions');
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    const {
      array,
    } = this.state;
    const {
      stats: { relatedUsers: { related_users }, sellers, buyers },
      auth: { user: { b2b: { type_user, id } } },
      getPaymentConditionLog,
      getLogUsers,
    } = this.props;

    if (type_user === 'issuing_bank' || type_user === 'acquiring_bank' ) {
      getPaymentConditionLog({
        params: {
          idUser: type_user === 'issuing_bank' ? buyers[newVal.value].id : sellers[newVal.value].id,
        }
      });
    } else if ( type_user === 'admin' ) {
      getPaymentConditionLog({
        params: {
          idUser:  array[newVal.value].id,
        }
      });
    } else {
      getLogUsers({
        params: {
          user: id,
          counterpart: related_users[newVal.value].id,
        }
      });
    }

    this.setState({
      [label.name]: newVal.value,
    });
  };

  handleInputChangeUser = (event) => {
    const {
      fetchStatsAdmin,
    } = this.props;

    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    fetchStatsAdmin({
      type: value === 'provider' ? 'p' : 'c',
      callback: (response) => {
        this.setState({
          array: response.length === 0 ? [] : value === 'provider' ? response.sellers : response.buyers,
          person: -1,
          [name]: value,
        });
      }
    });
  }

  render() {
    const {
      t,
      auth: { user: { b2b: { type_user } } },
      paymentConditions: { loading: paymentConditionLoading },
      stats: { sellers, buyers, loading: statsLoading },
    } = this.props;
    const {
      person,
      array,
      typePerson,
    } = this.state;

    const loading = statsLoading || paymentConditionLoading;
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle}/>
                    )
                  }
                  {data
                  && (
                    <div className="column is-full">
                      <Panel headingText={t('changeOfCommercialConditions')}>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="columns" style={{ width: '50%' }}>
                              <div className="column" style={{ zIndex: 99999 }}>
                                {type_user === "admin" ?
                                  <div>
                                    <div className="column is-two-third">
                                      <label><strong>{` ${t('user')}`}</strong></label>
                                      <br />
                                      <input
                                        name="typePerson"
                                        value={'client'}
                                        onChange={this.handleInputChangeUser}
                                        type="radio"
                                        checked={typePerson === 'client'}
                                      />
                                      {` ${t('client')}`}
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <input
                                        name="typePerson"
                                        value={'provider'}
                                        onChange={this.handleInputChangeUser}
                                        type="radio"
                                        checked={typePerson === 'provider'}
                                      />
                                      {` ${t('provider')}`}
                                    </div>
                                    <div className="column">
                                      <SelectSingle
                                        name="person"
                                        label={typePerson === false ? '' : typePerson === 'provider' ? t('provider') : t('client')}
                                        selectClasses="is-fullwidth"
                                        placeholder={t('SelectOption')}
                                        onChange={this.handleSelectChange}
                                        options={[
                                          ...array.map((item, index) => ({
                                            label: item.idPerson.name,
                                            value: index,
                                          })),
                                        ]}
                                        value={person}
                                        //isDisabled={hidden ? hidden : edit}
                                        isRequired
                                      />
                                    </div>
                                  </div>
                                  :
                                  <SelectSingle
                                    name="person"
                                    label={(type_user === 'issuing_bank' || type_user === 'seller') ? t('client') : t('commerce')}
                                    placeholder={t('SelectOption')}
                                    onChange={this.handleSelectChange}
                                    options={ (type_user === 'buyer' || type_user === 'seller') ?
                                      [
                                        ...array.map((code, index) => ({
                                          label: code.idPerson.name,
                                          value: index,
                                        })),
                                      ]
                                      :
                                      type_user === 'issuing_bank' ? [
                                      ...buyers.map((code, index) => ({
                                        label: code.idPerson.name,
                                        value: index,
                                      })),
                                    ] : [...sellers.map((code, index) => ({
                                      label: code.idPerson.name,
                                      value: index,
                                    })),]}
                                    selectClasses="is-fullwidth"
                                    controlClasses="has-icons-left"
                                    icon="far fa-user"
                                    iconPosition="is-left"
                                    value={person}
                                  />
                                }
                              </div>
                            </div>
                          </div>
                          <br/>
                        </div>
                        <TableSimple
                          data={data}
                          columns={columns}
                          title={title}
                        />
                      </Panel>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

PaymentConditionLog.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, stats, clients, providers, paymentConditions, }) => (
  {
    auth,
    stats,
    clients,
    providers,
    paymentConditions,
  }
);
export default compose(
  connect(mapStateToProps, { ...statsActions, ...clientsActions, ...providersActions, ...paymentConditionsActions }),
  withNamespaces(),
)(PaymentConditionLog);