import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const createTransaction = createAction(types.CREATE_TRANSACTION);
const resultTransaction = createAction(types.RESULT_TRANSACTION);
const getWebpay = createAction(types.GET_WEBPAY);
const accessToken = createAction(types.ACCESS_TOKEN);
const token = createAction(types.TOKEN);
const registerPayment = createAction(types.REGISTER_PAYMENT);
const listCards = createAction(types.LIST_CARDS);
const confirmBillingCycle = createAction(types.CONFIRM_BILLING_CYCLE);
const cycleCard = createAction(types.CYCLE_CARD);
const addAlias = createAction(types.ADD_ALIAS);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  })
);

export default {
  startFetch,
  endFetch,
  endError,
  createTransaction,
  resultTransaction,
  showMessage,
  getWebpay,
  accessToken,
  token,
  registerPayment,
  listCards,
  confirmBillingCycle,
  cycleCard,
  addAlias,
};