// Helper function for insert a node after other
export const insertAfter = (el, referenceNode) => {
  referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
};

export const getPath = (p, o) => (p.reduce((xs, x) => ((xs && xs[x]) ? xs[x] : null), o));

export const formatDate = date => new Intl.DateTimeFormat('es', {
  timeZone: 'America/Santiago',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}).format(date * 1000);

export const formatDateHour = date => new Intl.DateTimeFormat('es', {
  timeZone: 'America/Santiago',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
}).format(date * 1000);

export const formatFriendlyDate = date => new Intl.DateTimeFormat('es', {
  month: 'short',
  day: '2-digit',
  weekday: 'short',
}).format(date);

export const formatFriendlylongDate = (date, lng) => new Intl.DateTimeFormat(lng, {
  month: 'long',
  day: '2-digit',
  weekday: 'long',
}).format(date);

export const formatFriendlyDateLong = date => new Intl.DateTimeFormat('es', {
  month: 'long',
  day: '2-digit',
  weekday: 'long',
}).format(date * 1000);

export const formatCurrency = amount => Number(amount)
  .toLocaleString('es-AR', {
    style: 'currency',
    currency: 'ARS',
  });

export const formatCurrencyCl = amount => Number(amount)
  .toLocaleString('En', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const formatIntCurrency = amount => Number(amount)
  .toLocaleString('es-AR', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

export const formatLogDate = (date) => new Intl.DateTimeFormat('es', {
    timeZone: 'UTC',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
}).format(date * 1000)

export const modifyDate = (date, days, action = 'remove') => {
  const currentDate = new Date(date);
  currentDate.setDate(action === 'add' ? currentDate.getDate() + days : currentDate.getDate() - days);
  return currentDate;
}

export const formatAmount = (amount, currency = false) => {
  if (currency) {
    return amount > 0 ? `${currency} ${Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount)}` : '$0.00'
  } else {
    return amount > 0 ? Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount) : '0.00'
  }
}

export const formatNumber2 = (value, length, type) => {
  if (value.length <= length) {
    if(type === "number") {return value.replace(/\D/g, '').slice(0, length)}
    if(type === "text") {return value.replace(new RegExp("[0-9]"), '').slice(0, length)}
    if(type === "textNum") {return value.replace(new RegExp("[^a-zA-Z0-9]"), '').slice(0, length)}
  } else {
    return value.slice(0, length)
  }
}

export const dateToUTC = (date, type = 'start') => {
  if(type === 'start') {
    date.setHours(0, 0, 0);
  } else {
    date.setHours(23, 59, 59);
  }
  const userTimezoneOffset = (new Date().getTimezoneOffset() * 60000);
  const utcStringDate = Math.round((date.getTime() - userTimezoneOffset ) / 1000).toString();
  return (type === 'start')
    ? utcStringDate.replace(/.{0,2}$/, '00')
    : utcStringDate.replace(/.{0,2}$/, '99');
}

/* Removes commas from Addresses when they've got empty fields
E.g. " , , , street, , district, country" */
export const removeCommas = string => {
  if (string) {
    return string
      .split(', ')
      .filter(item => item !== "")
      .join(", ")
  } else {
    return string
  }
}

export const replaceRegex = (value, regex, replaceBy = '') => value ? value.replace(regex, replaceBy) : '';

export const numberFilter = (filter, row) => {
  const { id, value } = filter;
  const cleanFilter = replaceRegex(`${value}`, /[ .,]/g);
  const cleanRow = replaceRegex(`${row[id]}`, /[ .,]/g);
  if (cleanRow.includes(cleanFilter)) {
    return row[id];
  }
}

export const dateFilter = (filter, row) => {

  if (!filter.value.match(/[\d-]+$/gm)) {
    return true;
  }
  if ((row)
    .includes(filter.value)) {
    return (row);
  }
}

export const orderDate = (a, b) => {
  return (b) - (a);
}

export const onInputOnlyNumbers = (e) => {
  e.target.value = replaceRegex(e.target.value, /[^0-9]+/g, '');
}