import React, { Fragment } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Toggle } from '../../../../components';

const onClick = (event) => {
  const target = event.target.getBoundingClientRect();
  // eslint-disable-next-line no-undef
  const boxElement = document.getElementsByClassName('boxSettings')[0];
  const x = target.left;
  const y = target.top + 53;
  // boxElement.style.left = (x < 20) ? `${x}px` : `calc(${x}px - 150px)`;
  // boxElement.style.top = `${y}px`;
};
const ImageAvatar = ({
                      onToggle,
                      img,
                       shortName,
                       colorAvatar,
                       lastName,
                       name,
                       typeUser,
                       labelTypeUser,
                       mailboxNumber,
                       mailboxRoute,
                       adminName,
                       registerCard,
                       /*confirm_information,
                       savedCredentials,
                       pendingPaymentCondition,
                       prueba,*/
                       adminLastname,
                     }) => {
  let Avatar;
  if (img != null) {
    Avatar = (
      <img
        className="avatarMin"
        src={img}
        alt="Avatar minified"
      />
    );
  } else {
    Avatar = (
      <div className="avatar-short-name" style={{ background: "#e4002b" }}>
        {shortName}
      </div>
    );
  }
  let notifications;
  if (['buyer', 'issuing_bank', 'acquiring_bank', 'seller'].find(type => type === typeUser)) {
    notifications = (
      <div className="notification-invoice">
        <Link
          to={ (registerCard === 0 && typeUser === 'buyer') ? '/register-card' : /*(pendingPaymentCondition.length !== 0) ? '/accept-discount-rate' :*/ mailboxRoute}>
          <i className="far fa-file-alt" aria-hidden="true"/>
          <span className="number-invoice">{mailboxNumber}</span>
        </Link>
      </div>

    );
  }
  return (
    <div className="is-flex content-information">          
      <div className="content-information-user">
        <div className="information-user">
          <div className="full-name">
            {name}
            {' '}
            {lastName}
          </div>
          <div className="name-user">
            {adminName === null ? name+' '+lastName : adminName+' '+adminLastname}
          </div>
          <div className="type-user">
            {'(' + labelTypeUser + ')'}
          </div>
        </div>
      </div>
      {notifications}
      <button
        style={{ textDecoration: 'none' }}
        tabIndex={-1}
        className="dropdown-toggle button"
        onClick={(event) => {
          onClick(event);
          onToggle();
        }}
        // onBlur={() => toggled && setTimeout(onToggle, 500)}
      >
        {Avatar}
      </button>
    </div>
  );
};

ImageAvatar.propTypes = {
  onToggle: PropTypes.func.isRequired,
  img: PropTypes.string,
  shortName: PropTypes.string.isRequired,
  colorAvatar: PropTypes.string.isRequired,
  lastName: PropTypes.string,
  name: PropTypes.string.isRequired,
  typeUser: PropTypes.string.isRequired,
  labelTypeUser: PropTypes.string.isRequired,
  mailboxNumber: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  mailboxRoute: PropTypes.string.isRequired,
  adminName: PropTypes.string,
  adminLastname: PropTypes.string,
};

ImageAvatar.defaultProps = {
  img: '',
  lastName: '',
};

const Dropdown = (props) => {
  const {
    t,
    name,
    logout,
    lastName,
    shortName,
    colorAvatar,
    typeUser,
    labelTypeUser,
    mailboxNumber,
    mailboxRoute,
    i18n,
    language,
    setLanguage,
    adminName,
    roles,
    currentRole,
    updateUser,
    registerCard,
    savedCredentials,
    editCredentials,
    pendingPaymentCondition,
    adminLastname,
    confirm_information,
  } = props;

  return (
    <div className="Dropdown">
      <Toggle>
        {
          ({ toggled, onToggle }) => (
            <Fragment>
              <div className="be-top-header ocult-small">
                <nav className="navbar be-right-navbar">
                  <div className="navbar-brand">
                    <div className="navbar-item">
                      <ImageAvatar
                        onToggle={onToggle}
                        toggled={toggled}
                        img={null}
                        shortName={shortName}
                        colorAvatar={colorAvatar}
                        lastName={lastName}
                        name={name}
                        adminName={adminName}
                        typeUser={typeUser}
                        labelTypeUser={labelTypeUser}
                        mailboxNumber={mailboxNumber}
                        mailboxRoute={mailboxRoute}
                        registerCard={registerCard}
                        savedCredentials={savedCredentials}
                        pendingPaymentCondition={pendingPaymentCondition}
                        adminLastname={adminLastname}
                        confirm_information={confirm_information}
                      />
                    </div>
                  </div>
                </nav>
              </div>
              <div className="ocult-big">
                <nav className="navbar navbar-extend">
                  {/*<div className="navbar-brand-extend">*/}
                    <div className="navbar-start">
                      <div className="be-top-header ttt">
                        <div className="navbar-header">
                          <Link
                            className="navbar-brand"
                            to={
                                (registerCard === 0 && typeUser === 'buyer') ? '/register-card' :
                                  /*(pendingPaymentCondition.length !== 0) && (typeUser === 'buyer') ? '/accept-discount-rate' :*/
                                  '/dashboard'}
                          />
                        </div>
                      </div>
                    </div>
                    {/*</div>*/}
                  <div className="navbar-menu-extend">
                    <div className="navbar-start be-top-header modal-pop">
                      <ul className="nav be-user-nav">
                        <li className="dropdown">
                          <ImageAvatar
                            onToggle={onToggle}
                            toggled={toggled}
                            img={null}
                            shortName={shortName}
                            colorAvatar={colorAvatar}
                            lastName={lastName}
                            name={name}
                            adminName={adminName}
                            typeUser={typeUser}
                            labelTypeUser={labelTypeUser}
                            mailboxNumber={mailboxNumber}
                            mailboxRoute={mailboxRoute}
                            registerCard={registerCard}
                            savedCredentials={savedCredentials}
                            pendingPaymentCondition={pendingPaymentCondition}
                            adminLastname={adminLastname}
                            confirm_information={confirm_information}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
              <div className={`boxSettings animated fadeInUp ${toggled && 'open'}`}>
                <div className="box">
                  <ul className="sort-click">
                    <li className="is-hidden-desktop">
                      <div className="user-info">
                        <div className="user-name">
                          {name}
                          {' '}
                          {lastName}
                        </div>
                        <div className="user-position">{}</div>
                      </div>
                    </li>
                    <li className="box-icon">
                      <Link
                        to={
                            (registerCard === 0 && typeUser === 'buyer') ? '/register-card' :
                              /*(pendingPaymentCondition.length !== 0) && (typeUser === 'buyer') ? '/accept-discount-rate' :*/
                              '/account-profile'}>
                        <i className="icon far fa-user" aria-hidden="true"/>
                        {t('account')}
                      </Link>
                    </li>
                    {(typeUser === 'seller') &&
                    <li className="box-icon">
                      <Link to="/user-commerce-code">
                        <div style={{ display: 'flex' }}>
                          <i className="icon fas fa-store" aria-hidden="true"/>
                          <p style={{
                            marginLeft: '.5em',
                            marginTop: '.25em',
                            whiteSpace: 'break-spaces'
                          }}>
                            {t('affiliateNumber')}
                          </p>
                        </div>
                      </Link>
                    </li>}
                    {(typeUser === 'issuing_bank' || typeUser === 'acquiring_bank' || typeUser === 'main_bank_issuing' || typeUser === 'main_bank_acquiring') &&
                    <li className="box-icon">
                      <a target="_blank"
                        href="https://medullaris.atlassian.net/servicedesk/customer/portal/8">
                        <i className="icon fas fa-headset" aria-hidden="true"/>
                        {t('support')}
                      </a>
                    </li>
                    }
                    <li className="box-icon" style={{ display: editCredentials ? '' : 'none' }}>
                      <Link
                        to={
                          (registerCard === 0 && typeUser === 'buyer') ? '/register-card' :
                            /*(pendingPaymentCondition.length !== 0) && (typeUser === 'buyer') ? '/accept-discount-rate' : */
                            '/edit-credentials'}>
                        <i className="icon far fa-id-badge" aria-hidden="true"/>
                        {t('profile')}
                      </Link>
                    </li>
                    {
                      roles.map((rol, index) => (
                        <li className="box-icon" key={index}
                            style={{ display: (rol.name !== currentRole ? '' : 'none') }}>
                          <a onClick={updateUser.bind(this, index)}>
                            <i className="icon far fa-eye" aria-hidden="true"/>
                            {t(rol.name)}
                          </a>
                        </li>
                      ))
                    }
                    {
                      (typeUser === 'buyer' || typeUser === 'seller') &&
                      <li className="box-icon">
                        <Link
                          to="/support">
                          <i className="icon fas fa-book" aria-hidden="true"/>
                          {t('support')}
                        </Link>
                      </li>
                    }
                    <li className="box-icon">
                      <div className="control has-icons-left">
                        <div className="select is-full-width" style={{ width: '100%' }}>
                          <div className="icon is-small is-left">
                            <i className="fas fa-globe"/>
                          </div>
                          <select
                            value={language}
                            style={{ width: '100%' }}
                            onChange={({ target: { value } }) => {
                              setLanguage(value);
                              i18n.changeLanguage(value);
                              // eslint-disable-next-line no-undef
                              window.location.reload();
                            }}
                          >
                            <option value="es">{t('es')}</option>
                            <option value="pt">{t('pt')}</option>
                            <option value="en">{t('en')}</option>
                          </select>
                        </div>
                        <div className="icon is-small is-left">
                          <i className="fas fa-globe"/>
                        </div>
                      </div>
                    </li>
                    <li
                      className="box-icon"
                    >
                      <Link
                        onClick={logout}
                        to="/#"
                      >
                        <i className="icon fas fa-power-off" aria-hidden="true"/>
                        {t('logout')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Fragment>
          )
        }
      </Toggle>
    </div>
  );
};
Dropdown.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    changeLanguage: PropTypes.func.isRequired,
  }).isRequired,
  logout: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  lastName: PropTypes.string,
  name: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  colorAvatar: PropTypes.string.isRequired,
  typeUser: PropTypes.string.isRequired,
  labelTypeUser: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  mailboxNumber: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  mailboxRoute: PropTypes.string,
  adminName: PropTypes.string,
};

Dropdown.defaultProps = {
  lastName: '',
  mailboxNumber: 0,
  mailboxRoute: '',
};

export default withNamespaces()(Dropdown);
