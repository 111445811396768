import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { Aside, Dropdown, Footer } from './components';
import { authActions } from '../../state/ducks/auth';
import { RouteWithSubRoutes } from '../../config/routes';
import './styles.scss';
import { API_URL } from '../../config/constants';

const leadNumber = (list) => {
  if (Object.is(list)) {
    if (Object.keys(list).length > 0) {
      return 1;
    }
    return 0;
  }
  if (Array.isArray(list)) {
    return list.length;
  }
  return 0;
};

const Layout = (props) => {
  const {
    auth,
    auth: {
      user: {
        b2b: { confirm_information, type_user, label_type_user, paths, admin_name, admin_lastname, roles, registered_card, saved_credentials, resource, pending_payment_condition },
        name,
        lastname,
        name_abbreviation,
        colorAvatar,
        profile,
        profile: { role: currentRole },
      },
      language: defaultLanguage,
    },
    logout,
    location,
    routes,
    transactions: { pending_invoices, reject_invoices, emit_invoices },
    lead: { list: leadList },
    t,
    i18n,
    stats: { payment_request_pending },
  } = props;

  const [language, setLanguage] = useState(defaultLanguage);

  const updateUser = (index) => {
    const {
      auth: {
        user: {
          b2b: { roles },
          profile: { user_id }
        },
      },
      changeRole,
      history,
    } = props;

    const data = {
      user_id,
      new_role: roles[index].id,
    };
    changeRole({
      data,
      callback: () => {
        history.push('/login');
      },
    });
  };

  const editCredentials = () => {
    const {
      auth: {
        user: {
          b2b: { type_user, paths },
        },
      },
    } = props;

    let edit = 0;
    if (type_user === 'buyer') {
      paths.forEach(function (path) {
        if (path === '/edit-credentials') {
          edit = 1;
        }
      });
    }
    return edit;
  };

  return (
    <div className="Layout">
      <ToastContainer/>
      <div className="be-wrapper">
        {((resource !== null) /*|| (type_user === "main_bank_issuing") || (type_user === "buyer")*/) &&
        <div className="santander-bar" /*hide-logo*/>
          <img src={`${API_URL}b2b/resource-file?nameFile=${resource}`}/>
        </div>}
        <Dropdown
          logout={logout}
          name={name === null ? '' : name}
          lastName={lastname === null ? '' : lastname}
          adminName={admin_name}
          shortName={name_abbreviation}
          colorAvatar={colorAvatar}
          typeUser={type_user}
          labelTypeUser={profile ? profile.role === 'admin' ? t('adminRol') : t(profile.role) : t(type_user)}
          mailboxNumber={type_user === 'issuing_bank' || type_user === 'acquiring_bank' ? payment_request_pending : type_user == 'seller' ? emit_invoices : type_user === 'buyer' ? pending_invoices : leadNumber(leadList)}
          mailboxRoute={type_user === 'issuing_bank' || type_user === 'acquiring_bank' ? '/' + 'payment-request-received' : type_user === 'seller' ? '/' + 'pending-invoices' : type_user === 'buyer' ? '/review-invoices' : '/lead-list'}
          //mailboxNumber={type_user === 'buyer' ? pending_invoices : leadNumber(leadList)}
          //mailboxRoute={type_user === 'buyer' ? '/review-invoices' : '/lead-list'}
          t={t}
          i18n={i18n}
          language={language}
          setLanguage={setLanguage}
          roles={roles}
          currentRole={currentRole}
          updateUser={updateUser}
          registerCard={registered_card}
          savedCredentials={saved_credentials}
          editCredentials={editCredentials()}
          pendingPaymentCondition={pending_payment_condition}
          adminLastname={admin_lastname}
          confirm_information={confirm_information}
        />
        {/*<Aside
          location={location}
          auth={auth}
        />*/}
        <div>
          <Aside
            location={location}
            auth={auth}
          />
        </div>
        <div className="be-content">
          <div className="main-content container is-fluid">
            <Switch>
              {routes.map(
                route => paths.some(path => path.includes(route.path)) &&
                  <RouteWithSubRoutes key={`${route.path}`} {...route} />,
              )}
              <Redirect to="/dashboard"/>
            </Switch>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

Layout.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  location: PropTypes.shape({}).isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
    exact: PropTypes.bool.isRequired,
  }).isRequired).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  lead: PropTypes.shape(Object).isRequired,
  logout: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({}).isRequired,
  changeRole: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, transactions, lead, stats, }) => ({
  auth,
  transactions,
  lead,
  stats,
});

export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withNamespaces(),
)(Layout);
