import React, { useState } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Toggle } from '../../../../components';
import getMenuOptions from '../../../../config/asideOptions';
import { Menu } from './components';
import { withPermissions } from '../../../../hoc';
import './styles.scss';

const returnUrlFromPathname = (pathname, menus) => {
  let url = '';
  menus.forEach((menu) => {
    const { submenu, url: menuUrl } = menu;
    if (submenu !== undefined) {
      const cantSubMenu = submenu.items.length;
      for (let i = 0; i < cantSubMenu; i += 1) {
        if (pathname === submenu.items[i].url) {
          url = menuUrl;
        }
      }
    } else {
      url = menuUrl;
    }
  });
  return url;
};

const Aside = (props) => {
  const {
    location: { pathname },
    userHasRoles,
    hasAccessTo,
  } = props;

  const menu = getMenuOptions(hasAccessTo, userHasRoles);

  const [selected, setSelected] = useState(returnUrlFromPathname(pathname, menu));

  return (
    <div
      className="Aside"
    >
      <Toggle>
        {
          ({ toggled, onToggle }) => (
            <div
              className="be-left-sidebar animated fadeInUp"
              style={{ animationDelay: '0.5s' }}
            >
              <div className="left-sidebar-wrapper">
                <span
                  role="presentation"
                  className="left-sidebar-toggle"
                  onClick={onToggle}
                >
                  <i
                    className="fas fa-bars"
                    aria-hidden="true"
                  />
                </span>
                <div
                  className={`${toggled ? 'show-up-down' : 'hide-down-up'}`}
                >
                  <div className="left-sidebar-scroll">
                    <div className="left-sidebar-content">
                      <ul className="sidebar-elements">
                        {menu.map((value, key) => (
                          <Menu
                            key={key}
                            name={value.name}
                            icon={value.icon}
                            url={value.url}
                            isHidden={value.isHidden}
                            pathname={pathname}
                            selected={selected}
                            setSelected={setSelected}
                            submenu={value.submenu}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Toggle>
    </div>
  );
};

Aside.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  hasAccessTo: PropTypes.func.isRequired,
  userHasRoles: PropTypes.func.isRequired,
};

export default compose(
  withPermissions,
  withNamespaces(),
)(Aside);
