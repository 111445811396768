import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading } from '../../components';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { TOAST_CONFIG } from '../../config/constants';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { itemActions } from '../../state/ducks/item';
import { leadActions } from '../../state/ducks/lead';
import PersonForm from '../../components/PersonForm';
import { roleActions } from '../../state/ducks/role';
import { clientsActions } from '../../state/ducks/clients';
import { flowApprovalActions } from '../../state/ducks/flowApproval';
import { cardActions } from '../../state/ducks/card';
import { isThisISOWeek } from 'date-fns';
import { formatNumber2 } from '../../util/index';

class CreateCustomer extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    name: '',
    documentType: '',
    documentNumber: '',
    email: '',
    emailConfirmation: '',
    password: '',
    address: '',
    percentage: 0,
    period: -1,
    installments: 1,
    items: [],
    industryCategory: [],
    provider: '',
    startCountingFrom: 1,
    baseProvider: null,
    baseDays: 0,
    discountRate: 0,
    adminName: '',
    adminLastname: '',
    adminPosition: '',
    adminDocumentType: [],
    adminDocumentNumber: '',
    receiverDiscountShare: 100,
    clientPayment: 0,
    typeFlow: -1,
    edit: false,
    hidden: false,
    last4Digits: '',
    cuttingCycleCard: -1,
    countCycleCard: 0,
    billing_cycles: [{
      id: 0,
      last_four: '',
      cycleCounting: -1,
      billing_cycle_id: '',
      billing_cycle_index: -1,
    }
    ],
    phoneNumber: '',
    provinces: [],
    province: -1,
    cantons: [],
    canton: -1,
    districts: [],
    district: -1,
    inputValue: '',
    inputValueExecutive: '',
    executives: [],
    business_name: '',
    unformattedRut: '',
    unformattedAdminRut: '',
    phoneNumberAdmin: '',
    newBankingExec: [],
    countBankingExec: 0,
    typePerson: '',
    typeDocument: [],
    documentTypeUser: '',
    type_legal_person: '',
    street: '',
    number: '',
    otherSign: '',
    cif: '',
    categories: [],
    category: '',
  });

  componentDidMount() {
    const {
      t,
      fetchDocumentTypes,
      //fetchItems,
      fetchCategories,
      fetchCommunes,
      fetchRegions,
      fetchRoles,
      getWorkFlowTypes,
      getCycleCard,
      getExecutive,
      auth: { user: { first_login, user_id, b2b: { type_user, roles } } },
      router: { location: { pathname } },
      getClient,
    } = this.props;
    
    if(type_user == 'buyer' && first_login == 0){
      getClient({
        params: {
          id: user_id,
        },
      });
    }
    
    fetchDocumentTypes({
      params: { legal_type: 'F' },
      callback: (data) => {
        this.setState({ adminDocumentType: data });
      }
    });
    fetchCommunes({
      //providerList.seller[0].province.id,
      callback: (response) => {
        this.setState({
          cantons: response,
          //canton: response.findIndex(x => x.id == providerList.seller[0].canton.id)
        });
      }
    });
    //fetchItems();
    fetchCategories({
      callback: (response) => {
        this.setState({ categories: response });
      }
    });
    getExecutive({
      params: { owner: roles[0].owner_id },
      callback: (response) => {
        const exc = [];
        let j = 0;
        for (let i = 0; i < response.length + 1; i++) {
          if (i === 0) {
            exc.push({
              bank_id: response.length === 0 ? roles[0].owner_id : response[i].bank_id,
              lastname: '',
              id: -1,
              email: t('businessExecutive'),
              name: t('addNew'),
            });
          } else {
            exc.push({
              bank_id: response[j].bank_id,
              email: response[j].email,
              id: response[j].id,
              lastname: response[j].lastname,
              name: response[j].name,
            });
            j += 1;
          }
        }
        this.setState({ exc: exc });
      }
    });
    fetchRegions({
      callback: (response) => {
        this.setState({ provinces: response });
      }
    });
    getWorkFlowTypes({});
    getCycleCard({});
    fetchRoles({
      params: {
        type: 'external',
        name: 'buyer'
      },
    });

    /* if (pathname === '/create-customer') {
      const document = (documentTypeList.map(
        (documentTypeMap, index) => ({
          ...documentTypeMap,
          index
        })
      ).filter(documentTypeFil => documentTypeFil.document_type === 'RUC')[0].index);
      this.setState({
        documentType: document,
        hidden: true
      });
    }*/
    if (pathname === '/edit-customer') this.setState(this.arrayCustomer());
  }

  arrayCustomer = () => {
    const {
      clients: { list: clientList },
      documentType: { list: documentTypeList },
      card: { cycle },
      item: { list: listCategories },
      fetchCommunes,
      fetchRegions,
      fetchCities,
      fetchDocumentTypes,
    } = this.props;
    let documentType = -1;

    if (clientList.buyer[0].type_legal_person === 'JUR') {
      fetchDocumentTypes({
          params: {
            legal_type: clientList.buyer[0].type_legal_person === 'JUR' ? 'J' : 'F'
            },
        callback: (data) => {
            this.setState({
                documentTypeUser: -1,
                typeDocument: data,
                typePerson: 'legal',
                type_legal_person: 'JUR',
              });
          }
      });
    }

    if (clientList.buyer[0].idPerson.idDocumentType !== null) {
      fetchDocumentTypes({
        params: {
          legal_type: clientList.buyer[0].type_legal_person === null ?
            clientList.buyer[0].idPerson.idDocumentType.document_type === 'Cédula jurídica' ? 'J' : 'F'
            :
            clientList.buyer[0].type_legal_person === 'JUR' ? 'J' : 'F'
        },
        callback: (data) => {
          documentType = (data.map(
            (documentTypeMap, index) => ({
              ...documentTypeMap,
              index
            })
          )
            .filter(documentTypeFil => documentTypeFil.document_type
              === clientList.buyer[0].idPerson.idDocumentType.document_type)[0].index);
          this.setState({
            documentTypeUser: documentType,
            typeDocument: data,
            type_legal_person: clientList.buyer[0].idPerson.idDocumentType.document_type === 'Cédula jurídica' ? 'JUR' : 'FIS',
            typePerson: clientList.buyer[0].idPerson.idDocumentType.document_type === 'Cédula jurídica' ? 'legal' : 'person',
          });
        }
      });

      /*documentType = clientList.buyer[0].idPerson.idDocumentType === null ? -1 :
        documentTypeList.map(
          (documentTypeMap, index) => ({
            ...documentTypeMap,
            index
          })
        )
          .filter(documentTypeFil => documentTypeFil.document_type
            === clientList.buyer[0].idPerson.idDocumentType.document_type)[0].index;*/
    }

    if (clientList.buyer[0].admin_document_type !== null) {
      fetchDocumentTypes({
        params: { legal_type: 'F' },
        callback: (data) => {
          documentType = (data.map(
            (documentTypeMap, index) => ({
              ...documentTypeMap,
              index
            })
          )
            .filter(documentTypeFil => documentTypeFil.document_type
              === clientList.buyer[0].admin_document_type)[0].index);
          this.setState({
            documentType: documentType,
          });
        }
      });
    }
    const item = [];
    for (let i = 0; i < clientList.items.length; i++) {
      item.push({
        id: clientList.items[i].id,
        classification: clientList.items[i].classification,
        code: clientList.items[i].code,
        description: clientList.items[i].description
      });
    }
    const executives = [];
    for (let i = 0; i < clientList.banking_executive.length; i++) {
      executives.push({
        id: clientList.banking_executive[i].executive.id,
        name: clientList.banking_executive[i].executive.name,
        lastname: clientList.banking_executive[i].executive.lastname,
        email: clientList.banking_executive[i].executive.email
      });
    }

    const period = clientList.buyer[0].paymentConditionAsBuyer[0] !== null ?
      (this.getDays(clientList.buyer[0].paymentConditionAsBuyer[0].days)) : -1;

    /*const billing = [];
    for (let i = 0; i < clientList.buyer[0].billing_cycles.length; i++) {
      let index = cycle.map(
        (cycleMap, index) => ({
          ...cycleMap,
          index
        })
      )
        .filter(cycleFil => cycleFil.cycle
          === clientList.buyer[0].billing_cycles[i].billingCycle.cycle)[0].index;
      billing.push({
        id: i,
        last_four: clientList.buyer[0].billing_cycles[i].last_four,
        cycleCounting: clientList.buyer[0].billing_cycles[i].billingCycle.cycle,
        billing_cycle_id: clientList.buyer[0].billing_cycles[i].billingCycle.id,
        billing_cycle_index: index,
      });
    }

    let idComuna = 0,
      idRegion = 0;*/

    /*fetchRegions({
      callback: (response) => {
        idRegion = response.map(
          (communeMap, index) => ({
            ...communeMap,
            index
          })
        )
          .filter(communeFil => communeFil.name
            === clientList.buyer[0].region.name)[0].index;
        this.setState({
          region: idRegion,
        });
      }
    });
    fetchCommunes({
      region_id: clientList.buyer[0].region !== null ? clientList.buyer[0].region.id : '0',
      callback: (response) => {
        idComuna = response.map(
          (communeMap, index) => ({
            ...communeMap,
            index
          })
        )
          .filter(communeFil => communeFil.name
            === clientList.buyer[0].commune.name)[0].index;
        fetchRegions({
          callback: (response) => {
            idRegion = response.map(
              (communeMap, index) => ({
                ...communeMap,
                index
              })
            )
              .filter(communeFil => communeFil.name
                === clientList.buyer[0].region.name)[0].index;
          }
        });
        this.setState({
          communes: response,
          commune: idComuna,
          city: clientList.buyer[0].city !== null ? clientList.buyer[0].city.name : '',
          //region: idRegion,
        });
      }
    });*/

    /*let num = clientList.buyer[0].idPerson.document_number;

    var	MM = num.substr(0,2); //Primeros 2 digitos
    var CC = num.substr(2,3); //Siguientes 3 digitos
    var DD = num.substr(5,3); //Ultimos 3 digitos antes del guión
    var last = num.substr(8,1); // Digito o K después del guión
    var RUT = MM+CC+DD+"-"+last;

    let next_num =  clientList.buyer[0].admin_document_number;

    var	aMM = next_num.substr(0,2); //Primeros 2 digitos
    var aCC = next_num.substr(2,3); //Siguientes 3 digitos
    var aDD = next_num.substr(5,3); //Ultimos 3 digitos antes del guión
    var aLast = next_num.substr(8,1); // Digito o K después del guión
    var adminRUT = aMM+aCC+aDD+"-"+aLast;

    this.setState({
      unformattedRut: num,
      unformattedAdminRut: next_num,
    })*/

    if (clientList.buyer[0].canton !== null) {
      fetchCommunes({
        //providerList.seller[0].province.id,
        callback: (response) => {
          this.setState({
            canton: response.findIndex(x => x.id == clientList.buyer[0].canton.id),
          });
        }
      });
      fetchCities({
        canton_id: clientList.buyer[0].canton.id,
        callback: (response) => {
          this.setState({
            districts: response,
            district: response.findIndex(x => x.id == clientList.buyer[0].district.id)
          });
        }
      });
    }

    /*if(clientList.buyer[0].province !== null){
      fetchRegions({
        callback: (response) => {
          this.setState({province: response.findIndex(x => x.id == clientList.buyer[0].province.id)});
        }
      });
      fetchCommunes({
        province_id: clientList.buyer[0].province.id,
        callback: (response) => {
          this.setState({
            cantons: response,
            canton: response.findIndex(x => x.id == clientList.buyer[0].canton.id)
          });
        }
      });
      fetchCities({
        canton_id: clientList.buyer[0].canton.id,
        callback: (response) => {
          this.setState({
            districts: response,
            district: response.findIndex(x => x.id == clientList.buyer[0].district.id)
          });
        }
      });
    }*/
    const categoryToReturn = () => {
      let categoryArray = undefined;
      if (clientList.items.length !== 0) {
        categoryArray = clientList.items.length !== null ? listCategories.findIndex(x => x.id === parseInt(clientList.items[0].category_id)) : -1
      }
      if (clientList.categories.length !== 0) {
        if (clientList.categories.length !== 0) {
          categoryArray = clientList.categories[0].id !== null ? listCategories.findIndex(x => x.id === parseInt(clientList.categories[0].id)) : -1
        }
        return categoryArray;
      }
    }
    return {
      category: categoryToReturn(),
      name: clientList.buyer[0].idPerson.name,
      documentTypeUser: documentType,
      documentType,
      documentNumber: clientList.buyer[0].idPerson.document_number,
      cif: clientList.buyer[0].cif !== null ? clientList.buyer[0].cif : '',
      address: clientList.buyer[0].address_full,
      street: clientList.buyer[0].address !== null ? clientList.buyer[0].address.street : '',
      number: clientList.buyer[0].address !== null ? clientList.buyer[0].address.number : '',
      otherSign: clientList.buyer[0].address !== null ? clientList.buyer[0].address.other_sign : '',
      adminName: clientList.buyer[0].admin_name,
      adminLastname: clientList.buyer[0].admin_lastname,
      adminDocumentNumber: clientList.buyer[0].admin_document_number,
      /*adminDocumentType: clientList.buyer[0].admin_document_type === 'CI' ||
      clientList.buyer[0].admin_document_type === 'Cédula' ? 0 : 1,*/
      adminPosition: clientList.buyer[0].admin_position,
      email: clientList.buyer[0].idPerson.idEmailActive.email,
      emailConfirmation: clientList.buyer[0].idPerson.idEmailActive.email,
      percentage: clientList.buyer[0].paymentConditionAsBuyer[0] !== null ?
        clientList.buyer[0].paymentConditionAsBuyer[0].percentage : 0,
      period,
      typeFlow: clientList.process_type === null ? 0 : clientList.process_type,
      edit: true,
      items: item,
      hidden: true,
      billing_cycles: [],//billing,
      phoneNumber: clientList.buyer[0].phone !== null ? clientList.buyer[0].phone.phone_number : '',
      executives,
      business_name: clientList.buyer[0].business_name === null ? '' : clientList.buyer[0].business_name,
      phoneNumberAdmin: clientList.buyer[0].admin_phone_number,
    };
  };

  getDays = (period) => {
    switch (period) {
      case 365:
        return 0;
      case 30:
        return 1;
      case 1:
        return 2;
      default:
        return '';
    }
  };

  getDaysInv = (period) => {
    switch (period) {
      case 0:
        return 365;
      case 1:
        return 30;
      case 2:
        return 1;
      default:
        return '';
    }
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getTitle = () => {
    const {
      t,
      router: { location: { pathname } },
    } = this.props;

    switch (pathname) {
      case '/create-customer':
        return t('createCustomer');
      case '/edit-customer':
        return t('edit');
      default:
        return t('');
    }
  };

  handleInputChangeNewExc = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const {
      auth: { user: { b2b: { id } } },
    } = this.props;
    const value = type === 'checkbox' ? checked : targetValue;

    const executive = name.split('-');

    let newBankingExec = [...this.state.newBankingExec];

    switch (executive[0]) {
      case 'name':
        newBankingExec[executive[1]] = {
          id: Number(executive[1]),
          name: value,
          lastname: newBankingExec[executive[1]].lastname,
          email: newBankingExec[executive[1]].email,
          bank_id: id,
        };
        break;
      case 'lastname':
        newBankingExec[executive[1]] = {
          id: Number(executive[1]),
          name: newBankingExec[executive[1]].name,
          lastname: value,
          email: newBankingExec[executive[1]].email,
          bank_id: id,
        };
        break;
      case 'email':
        newBankingExec[executive[1]] = {
          id: Number(executive[1]),
          name: newBankingExec[executive[1]].name,
          lastname: newBankingExec[executive[1]].lastname,
          email: value,
          bank_id: id,
        };
        break;
    }

    this.setState({ newBankingExec });
  };

  handleInputChangeTypeDocument = (event) => {
    const {
      documentNumber,
      documentTypeUser,
      fetchDocumentTypes,
    } = this.props;
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    fetchDocumentTypes({
      params: { legal_type : value === 'legal' ? 'J' : 'F' },
      callback: (data) => {
        this.setState({
          type_legal_person: value === 'legal' ? 'JUR' : 'FIS',
          documentNumber: value === 'legal' ? '' : documentNumber,
          documentTypeUser: value === 'legal' ? -1 : documentTypeUser,
          typeDocument: data,
          [name]: value,
        });
      }
    });

  }

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const { typePerson, documentTypeUser, documentType } = this.state;
    const value = type === 'checkbox' ? checked : targetValue;
    
    const cycles = name.split('-');
    const inputName = cycles[0];
    const index = cycles[1];

    if ('last4Digits' === inputName && this.formatNumber(value)) {
      let billing_cycles = [...this.state.billing_cycles];
      let min = value;

      billing_cycles[index] = {
        id: index,
        last_four: min,
        cycleCounting: billing_cycles[index].cycleCounting,
        billing_cycle_id: billing_cycles[index].billing_cycle_id,
      };

      this.setState({ billing_cycles });
    }

    if(name === 'cif'){
      this.validateNIT(value);
    }

    if (name === 'documentNumber') {
      let num;
      if(typePerson === "legal") {
        num = formatNumber2(value, 13, "number").replace(new RegExp("^0+(?!$)"), "")
      } else {
        switch (documentTypeUser) {
          case 0:
            num = formatNumber2(value, 13, "textNum")
            break;
          case 1:
            num = formatNumber2(value, 13, "number").replace(new RegExp("^0+(?!$)"), "")
            break;
          default:
            num = value
            break;
        }
      }

      /*if(name === 'documentNumber'){
        this.setState({unformattedRut: num});
      }
      if(name === 'adminDocumentNumber'){
        this.setState({unformattedAdminRut: num})
      }

      let prenum = '';
      let postnum = '';

      if(num.length >= 9){
        prenum = num.slice(0,8);
        postnum = num.slice(8,9);
        num = prenum+"-"+postnum;
      }*/

      this.setState({
        [name]: (num),
      });
    } 
    if (name === 'adminDocumentNumber') {
      let num;
      switch (documentType) {
        case 0:
          num = formatNumber2(value, 13, "textNum")
          break;
        case 1:
          num = formatNumber2(value, 13, "number").replace(new RegExp("^0+(?!$)"), "")
          break;
        default:
          num = value
          break;
      }

      this.setState({
        [name]: (num),
      });
    } 
    if ((name !== 'documentNumber') && (name !== 'adminDocumentNumber')) {
      this.setState({
        [name]: value,
      });
    }
  };

  handleInputChangeAdminDocument = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const {
      adminDocumentType,
    } = this.state;

    const value = type === 'checkbox' ? checked : targetValue;

    if ((name === 'adminDocumentNumber' && adminDocumentType === 0)) {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  formatRUT = (n) => {
    return n.replace(/[^0-9Kk]/, '');
  };

  formatNumber = (n) => {
    if (n.length < 14) {
      return n.replace(/\D/g, '')
        .replace(/([0-9]{14})$/);
    } else {
      return;
    }
  };

  handleItemChange = (newValue) => {
    this.setState({ items: newValue });
  };

  handleExecutivesChange = (newValue, event) => {
    const {
      auth,
    } = this.props;
    const {
      newBankingExec,
    } = this.state;

    if (event.action === 'clear') {
      this.setState({
        executives: newValue,
        newBankingExec: [],
      });
    } else {
      if (event.action === 'remove-value' && event.removedValue.id === -1) {
        this.setState({
          executives: newValue,
          newBankingExec: [],
        });
      } else {
        if (newValue[newValue.length - 1].id === -1) {
          newBankingExec[0] = {
            id: 0,
            name: '',
            lastname: '',
            email: '',
            bank_id: auth.user.b2b.id,
          };
          this.setState({
            executives: newValue,
            newBankingExec: newBankingExec
          });
        } else {
          this.setState({
            executives: newValue
          });
        }
      }
    }
  };

  addBankingExec = () => {
    const {
      auth,
      showMessage,
      t,
    } = this.props;
    const {
      newBankingExec,
      countBankingExec,
    } = this.state;
    let count = 0,
      i = 1;
    count = countBankingExec + i;

    if (newBankingExec[newBankingExec.length - 1].email === '') {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('mandatoryFieldsRequired')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {
      let variable = {
        id: count,
        name: '',
        lastname: '',
        email: '',
        bank_id: auth.user.b2b.id,
      };
      this.state.newBankingExec.push(variable);
    }
    this.setState({
      countBankingExec: count
    });
  };

  deleteBankingExec = (index) => {
    const {
      newBankingExec,
    } = this.state;
    this.setState(newBankingExec.splice(index, 1));
  };

  handleSelectChange = (newVal, label) => {
    const {
      card: { cycle: listCycle },
      fetchCities,
      fetchItems,
    } = this.props;
    const {
      categories,
      communes,
    } = this.state;

    const cycles1 = label.name.split('-');
    const inputName = cycles1[0];
    const index = cycles1[1];

    if(label.name === 'category'){
      fetchItems({
        category_id: categories[newVal.value].id,
      });
    }

    /*if (inputName === 'commune') {
      let commune_id = communes[newVal.value].id;
      fetchCities({
        commune_id,
        callback: (response) => {
          if (response.length > 0) {
            this.setState({ city: response[0].name });
          } else {
            this.setState({ city: '' });
          }
        }
      });
    }*/
    if (inputName === 'cuttingCycleCard' && newVal.value !== '') {
      let billing_cycles = [...this.state.billing_cycles];

      billing_cycles[index] = {
        id: index,
        last_four: billing_cycles[index].last_four,
        cycleCounting: listCycle[newVal.value].cycle,
        billing_cycle_id: listCycle[newVal.value].id,
        billing_cycle_index: newVal.value,
      };
      this.setState({ billing_cycles });
    }
    if (inputName === 'category') {
      const arrayCat = [];
      arrayCat.push(categories[newVal.value]);
      this.setState({
        [label.name]: newVal.value,
        industryCategory: arrayCat,
      });
    } else {
      this.setState({
        [label.name]: newVal.value,
      });
    }
  };

  handleRegionChange = (newVal, label) => {

    const { fetchCommunes } = this.props;
    const { provinces } = this.state;

    this.setState({
      [label.name]: newVal.value,
    });

    fetchCommunes({
      province_id: provinces[newVal.value].id,
      callback: (response) => {
        this.setState({
          cantons: response,
        });
      }
    });
  }

  handleCantonChange = (newVal, label) => {

    const { fetchCities } = this.props;
    const { cantons } = this.state;

    this.setState({
      [label.name]: newVal.value,
    });

    fetchCities({
      canton_id: cantons[newVal.value].id,
      callback: (response) => {
        this.setState({
          districts: response,
        });
      }
    });
  }

  handlePercentageChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
      clientPayment: 100 - Number(value),
    });
  };

  validatePaymentCondition = () => {
    const {
      discountRate, provider,
    } = this.state;
    const { t, showMessage } = this.props;
    if (provider !== '' && discountRate > 0) {
      if (discountRate > 100) {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('discountRateOutOfLimits')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
        return;
      }
      return true;
    }
    if (provider === '' && discountRate === 0) {
      return false;
    }
    showMessage({
      message: <strong
        style={{ color: '#ffffff' }}>{t('completeComercialConditionOrEmptyAll')}</strong>,
      config: TOAST_CONFIG.ERROR,
    });
  };

  array_executives = (executives) => {
    let variable = [];

    for (let i = 0; i < executives.length; i++) {
      if (executives[i].id !== -1) {
        variable.push(Number(executives[i].id));
      }
    }

    return variable;
  };

  createProvider = () => {
    const {
      createProvider,
      editUser,
      auth,
      auth: {
        user: {
          b2b: {
            type_user,
            roles,
          },
        },
      },
      t,
      showMessage,
      deleteLead,
      providers,
      fetchLeads,
      role: { list: roleList },
      router: { location: { pathname } },
      clients: { list: clientList },
      flowApproval: { list: typeFlows },
      history,
    } = this.props;
    const {
      name,
      documentType,
      documentNumber,
      email,
      emailConfirmation,
      password,
      address,
      percentage,
      period,
      installments,
      items,
      industryCategory,
      lead,
      lead_id,
      discountRate,
      adminName,
      adminLastname,
      adminDocumentType,
      adminDocumentNumber,
      adminPosition,
      provider,
      receiverDiscountShare,
      baseDays,
      typeFlow,
      billing_cycles,
      phoneNumber,
      province,
      commune,
      provinces,
      cantons,
      canton,
      districts,
      district,
      executives,
      business_name,
      unformattedRut,
      unformattedAdminRut,
      phoneNumberAdmin,
      newBankingExec,
      //documentType, admin
      documentTypeUser,
      typeDocument,
      type_legal_person,
      street,
      number,
      otherSign,
      cif,
    } = this.state;

    const array = this.labelType(typeFlows);
    if (pathname === '/create-customer') {
      if (this.validateEmptyFields() && this.validateEmail(email, emailConfirmation)) {
        if(this.validateNIT(cif)){
          const data = {
            name,
            document_type: type_legal_person === 'JUR' ? '' : typeDocument[documentTypeUser].document_type,
            document_number: documentNumber,
            email,
            password,
            user_type: 'flecha',
            agency_user_type: 'business_admin',
            role_name: roleList[0].id,
            items,
            categories: industryCategory,
            process_type: null, //typeFlow === 0 ? null : array[typeFlow].id,
            b2b: {
              type_legal_person,
              bank_id: roles[0].owner_id,
              admin: 0,
              issuing_bank: 0,
              acquiring_bank: 0,
              seller: 0,
              buyer: 1,
              address: street,
              /*region_address_id: regions[region].id,
              commune_address_id: communes[commune].id,*/
              admin_name: adminName,
              admin_lastname: adminLastname,
              admin_document_number: adminDocumentNumber,
              admin_document_type: adminDocumentType[documentType].document_type,//'RUT',
              admin_position: adminPosition,
              admin_phone_number: phoneNumberAdmin,
              business_name,
              cif,
            },
            //billing_cycles,
            phone: {
              phone_number: phoneNumber,
              country_code: '+56',
              is_main: 1,
            },
            banking_executive: this.array_executives(executives),
            new_banking_executive: newBankingExec,
            b2b_address: {
              street,
              //number,
              //other_sign: otherSign,
              canton_id: cantons[canton].id,
              district_id: districts[district].id,
            }
          };

          createProvider({
            data,
            callback: (response) => {
              const { b2b: { id } } = response;
              /*const conditionData = {
                sender_id: id,
                receiver_id: auth.user.b2b.id,
                percentage,
                days: this.getDaysInv(period),
                installments,
                type: 'defer',
              };*/
              if (lead) {
                deleteLead({
                  params: {
                    id: lead_id,
                  },
                  callback: fetchLeads({
                    params: {
                      role_id: type_user === 'issuing_bank' ? 'buyer' : 'seller',
                    },
                  }),
                });
              }
              showMessage({
                message: t('createClientSuccess', { name }),
                config: TOAST_CONFIG.SUCCESS,
              });
              this.resetState();
              history.push('/dashboard');
              /* //Al crear Cliente en el banco emisor no tiene, condicion comercial
              createPaymentCondition({
                data: conditionData,
                callback: () => {
                  showMessage({
                    message: t('createClientSuccess', { name }),
                    config: TOAST_CONFIG.SUCCESS,
                  });
                  this.resetState();
                },
              }); */
            },
          });
        }else{
          if(!this.validateNIT(cif)){
            showMessage({
              message: <strong style={{ color: '#ffffff' }}>{t('invalidNIT')}</strong>,
              config: TOAST_CONFIG.ERROR,
            });
          }
        }
      } else {
        if (!this.validateEmptyFields()) {
          showMessage({
            message: <strong style={{ color: '#ffffff' }}>{t('mandatoryFieldsRequired')}</strong>,
            config: TOAST_CONFIG.ERROR,
          });
        } else {
          showMessage({
            message: <strong style={{ color: '#ffffff' }}>{t('emailConfirmationMsg')}</strong>,
            config: TOAST_CONFIG.ERROR,
          });
        }
      }
    }

    if(pathname === '/edit-customer') {
      if (this.validateEmptyFields() && this.validateEmail(email, emailConfirmation)) {
        if(this.validateNIT(cif)){
          const data = {
            id_user: clientList.buyer[0].id,
            name,
            type_legal_person,
            documentType: type_legal_person === 'JUR' ? null : typeDocument[documentTypeUser].id,
            documentNumber: type_legal_person === 'JUR' ? '' : documentNumber,
            email,
            items,
            //address,
            categories: industryCategory,
            admin_name: adminName,
            admin_lastname: adminLastname,
            admin_position: adminPosition,
            admin_phone_number: phoneNumberAdmin,
            percentage,
            days: this.getDaysInv(period),
            process_type: null,
            type: 'B',
            admin_document_number: adminDocumentNumber,
            admin_document_type: adminDocumentType[documentType].document_type,
            //commune_address_id: commune + 1,
            //billing_cycles,
            business_name,
            //region_address_id: regions[region].id,
            //commune_address_id: communes[commune].id,
            phone: {
              phone_number: phoneNumber,
              country_code: '+56',
              is_main: 1,
            },
            banking_executive: this.array_executives(executives),
            new_banking_executive: newBankingExec,
            b2b_address: {
              street,
              number,
              other_sign: otherSign,
              district_id: districts[district].id,
            },
            cif,
          };

          editUser({
            data,
            callback: () => {
              history.push('/dashboard');
              this.resetState();
              showMessage({
                message: t('editClientSuccess', { name }),
                config: TOAST_CONFIG.SUCCESS,
              });
            },
          });
        }else{
          if(!this.validateNIT(cif)){
            showMessage({
              message: <strong style={{ color: '#ffffff' }}>{t('invalidNIT')}</strong>,
              config: TOAST_CONFIG.ERROR,
            });
          }
        }
      } else {
        if (!this.validateEmptyFields()) {
          showMessage({
            message: <strong style={{ color: '#ffffff' }}>{t('mandatoryFieldsRequired')}</strong>,
            config: TOAST_CONFIG.ERROR,
          });
        } else {
          showMessage({
            message: <strong style={{ color: '#ffffff' }}>{t('emailConfirmationMsg')}</strong>,
            config: TOAST_CONFIG.ERROR,
          });
        }
      }
    }
  };

  isEmail = n => /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/.test(n);

  validateEmptyFields = () => {
    const {
      name,
      street,
      adminName,
      adminLastname,
      adminDocumentNumber,
      //adminDocumentType,
      documentNumber,
      //documentType,
      email,
      emailConfirmation,
      adminPosition,
      //items,
      //executives,
      phoneNumber,
      //region,
      //commune,
      //billing_cycles,
      canton,
      district,
      type_legal_person,
    } = this.state;

    /*if (billing_cycles > 0) {
      billing_cycles.forEach(function (billing) {
        if (billing.last_four !== '' || billing.cycleCounting === -1) {
          band = -1;
        }
      });
    }*/

    return !(
      name === '' || 
      street === '' ||
      type_legal_person === 'JUR' ? documentNumber !== '' : documentNumber !== '',
      adminName === '' ||
      adminLastname === '' ||
      //region === -1 ||
      //commune === -1 ||
      //documentNumber === '' ||
      /* documentType === -1 || */
      email === '' ||
      emailConfirmation === '' ||
      adminPosition === '' || phoneNumber === '' ||
      canton === -1 ||
      district === -1
      /* || billing_cycles.length <= 0 || band === -1 ||
      items.length <= 0 || executives.length === 0*/);
  };

  validateEmail = (email, emailConfirmation) => {
    if (email === emailConfirmation && email && emailConfirmation) {
      return true
    } 
    return false
  }

  validateNIT = (nit) => {
    let result = /^(\d+)-(\d|K)$/.test(nit.toUpperCase());
    let validation = false;

    if(result === true){

      let divided = nit.toUpperCase().split("-");
      let separatedNIT = divided[0];
      let validatorDigit = divided[1];
      let chunks = [];

      for (let i = 0;  i < separatedNIT.length; i++) {
        chunks.push(separatedNIT[i]);
      }

      let suma = 0;
      chunks.reverse().forEach((n, index) => {
        suma += parseInt(n) * (index + 2);
      });

      let result = (11 - (suma % 11)) % 11;
      if((result == validatorDigit && result < 10) || (result == 10 && validatorDigit == "K")){
        validation = true;
      }
    }else{
      validation = false;
    }
    return validation;
  }

  labelType = (typeList) => {
    const {
      t,
    } = this.props;
    const types = [];
    types.push({
      name: t('dontPose')
    });
    for (let i = 0; i < typeList.length; i++) {
      types.push({
        id: typeList[i].id,
        name: t(typeList[i].name)
      });
    }
    return types;
  };

  addCuttingCycle = () => {
    const {
      countCycleCard,
    } = this.state;
    let count = 0,
      i = 1;
    count = countCycleCard + i;

    let variable = {
      id: count,
      last_four: '',
      cycleCounting: -1,
      billing_cycle_id: '',
      billing_cycle_index: '-1',
    };
    this.state.billing_cycles.push(variable);

    this.setState({
      countCycleCard: count
    });
  };

  handleOnInputChange = (newValue) => {
    this.setState({ inputValue: newValue });
    return newValue;
  };

  handleOnInputChangeExecutive = (newValue) => {
    this.setState({ inputValueExecutive: newValue });
    return newValue;
  };

  
  deleteCuttingCycle = (index) => {
    const {
      billing_cycles,
    } = this.state;
    this.setState(billing_cycles.splice(index, 1));
  };

  cancelSave = () => {
    const { history } = this.props;
    history.push('/dashboard');
  }

  render() {
    const {
      t,
      auth: { user: { b2b: { type_user, }, }, },
      providers: { loading: providerLoading },
      clients: { loading: clientLoading },
      item: { list: itemList },
      flowApproval: { list: typeFlows },
      card: { cycle, list },
    } = this.props;
    const {
      name,
      documentNumber,
      email,
      emailConfirmation,
      password,
      address,
      percentage,
      baseDays,
      items,
      documentType,
      discountRate,
      adminName,
      adminLastname,
      adminDocumentType,
      adminDocumentNumber,
      adminPosition,
      receiverDiscountShare,
      clientPayment,
      typeFlow,
      period,
      edit,
      hidden,
      last4Digits,
      cuttingCycleCard,
      billing_cycles,
      phoneNumber,
      provinces,
      province,
      cantons,
      canton,
      districts,
      district,
      inputValue,
      inputValueExecutive,
      city,
      executives,
      business_name,
      phoneNumberAdmin,
      exc,
      newBankingExec,
      typePerson,
      typeDocument,
      documentTypeUser,
      street,
      number,
      otherSign,
      cif,
      categories,
      category,
    } = this.state;
    return (
      <div>
        {(providerLoading || clientLoading) && <Loading />}
        <div className="columns">
          <div className="column is-full">
            <PersonForm
              t={t}
              title={this.getTitle()}
              onSubmit={this.createProvider}
              handleInputChange={this.handleInputChange}
              handleInputChangeAdminDocument={this.handleInputChangeAdminDocument}
              name={name}
              noRetain={typePerson}
              handleSelectChange={this.handleSelectChange}
              handleItemChange={this.handleItemChange}
              handleExecutivesChange={this.handleExecutivesChange}
              documentTypesList={typeDocument}
              documentsEcuador={this.state.documentsEcuador}
              documentNumber={documentNumber}
              address={address}
              documentType={documentType}
              //items={items}
              itemList={itemList}
              email={email}
              emailConfirmation={emailConfirmation}
              isEmailEqual={this.validateEmail(email, emailConfirmation)}
              password={password}
              //persons={providerList}
              baseDays={baseDays}
              percentage={percentage}
              discountRate={discountRate}
              client
              adminName={adminName}
              adminLastname={adminLastname}
              adminDocumentType={adminDocumentType}
              adminDocumentNumber={adminDocumentNumber}
              adminPosition={adminPosition}
              handlePercentageChange={this.handlePercentageChange}
              receiverDiscountShare={receiverDiscountShare}
              clientPayment={clientPayment}
              typeFlows={this.labelType(typeFlows)}
              typeFlow={typeFlow}
              period={period}
              edit={edit}
              hidden={hidden}
              last4Digits={last4Digits}
              cuttingCycle={cycle}
              cuttingCycleCard={cuttingCycleCard}
              addCuttingCycle={this.addCuttingCycle}
              deleteCuttingCycle={this.deleteCuttingCycle}
              cycles={billing_cycles}
              phoneNumber={phoneNumber}
              provinces={provinces}
              province={province}
              handleRegionChange={this.handleRegionChange}
              handleCantonChange={this.handleCantonChange}
              cantons={cantons}
              canton={canton}
              districts={districts}
              district={district}
              handleOnInputChange={this.handleOnInputChange}
              handleOnInputChangeExecutive={this.handleOnInputChangeExecutive}
              inputValue={inputValue}
              inputValueExecutive={inputValueExecutive}
              //city={city}
              executivesList={exc}
              executives={executives}
              business_name={business_name}
              phoneNumberAdmin={phoneNumberAdmin}
              newBankingExec={newBankingExec}
              addBankingExec={this.addBankingExec}
              deleteBankingExec={this.deleteBankingExec}
              handleInputChangeNewExc={this.handleInputChangeNewExc}
              handleInputChangeTypeDocument={this.handleInputChangeTypeDocument}
              documentTypeUser={documentTypeUser}
              street={street}
              number={number}
              otherSign={otherSign}
              cif={cif}
              cancelSave={this.cancelSave}
              categories={categories}
              category={category}
              typeUser={type_user}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreateCustomer.propTypes = {
  providers: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  item: PropTypes.shape(Object).isRequired,
  location: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
  deleteLead: PropTypes.func.isRequired,
  fetchLeads: PropTypes.func.isRequired,
  getExecutive: PropTypes.func.isRequired,
  createProvider: PropTypes.func.isRequired,
  createPaymentCondition: PropTypes.func.isRequired,
  getProviders: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  router: PropTypes.shape(Object).isRequired,
  clients: PropTypes.shape(Object).isRequired,
  history: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({
                          providers,
                          documentType,
                          auth,
                          item,
                          role,
                          router,
                          clients,
                          flowApproval,
                          card,
                        }) => ({
  providers,
  documentType,
  auth,
  item,
  role,
  router,
  clients,
  flowApproval,
  card,
});
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...itemActions,
    ...leadActions,
    ...roleActions,
    ...clientsActions,
    ...flowApprovalActions,
    ...cardActions,
  }),
  withNamespaces(),
)(CreateCustomer);
