import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import { Loading, Panel, TableSimple, Toggle, } from '../../components';
import DateInput from '../../components/DateInput';
import { roleActions } from '../../state/ducks/role';
import { authActions } from '../../state/ducks/auth';
import { logActions } from '../../state/ducks/log';
import TextArea from '../../components/TextArea';
import {
  modifyDate,
  formatLogDate,
  formatCurrencyCl,
  dateToUTC
} from '../../util';
import TextInput from '../../components/TextInput';
import { TOAST_CONFIG } from '../../config/constants';

class LogView extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(new Date());
  }

  getInitialState = (currentDate) => ({
    params: '',
    respJson: '',
    respXml: '',
    response: '',
    pathname: '',
    startDate: modifyDate(currentDate, 7),
    endDate: currentDate,
  });

  componentDidMount() {
    const {
      fetchLog,
      transactionLog,
      router: { location: { pathname } },
    } = this.props;
    switch (pathname) {
      case '/log-transaction-webpay':
        transactionLog({
          callback: (response) => {
            this.setState({
              response: response,
              pathname: 'log-webPay'
            });
          }
        });
        break;
      case '/log-view':
        const currentDate = new Date()
        fetchLog({
          params: {
            start: dateToUTC(modifyDate(currentDate, 7), 'start'),
            end: dateToUTC(currentDate, 'end'),
          },
          callback: (response) => {
            this.setState({
              response: response,
              pathname: 'log-view'
            });
          }
        });
        break;
    }
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = (onToggle) => {
    let columns = null;
    let data = null;
    let title = null;
    const {
      t,
    } = this.props;
    const {
      response,
      pathname,
    } = this.state;
    switch (pathname) {
      case 'log-view':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('date'),
            accessor: d => `${formatLogDate(d.date)} `,
          },
          {
            id: 'response', // Required because our accessor is not a string
            Header: t('response'),
            accessor: d => (d.response_json.mensaje) ? (d.response_json.mensaje) : (d.response_json.status),
          },
          {
            id: 'source', // Required because our accessor is not a string
            Header: t('source_log'),
            accessor: d => (d.source) ? (d.source) : '',
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        this.setState({
                          source_log: JSON.stringify(cellInfo.original.source),
                          params: JSON.stringify(cellInfo.original.params),
                          respJson: JSON.stringify(cellInfo.original.response_json),
                          respXml: JSON.stringify(cellInfo.original.response_xml),
                        }, () => {
                          onToggle({});
                        });
                      }}
                    >
                      <i className="far fa-eye mr-8px" aria-hidden="true"/>
                      {t('view')}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        break;
      case 'log-webPay':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('date'),
            accessor: d => `${d.date} ${d.time}`
          },
          {
            id: 'status', // Required because our accessor is not a string
            Header: t('status'),
            accessor: d => `${d.status_transaction}`,
          },
          {
            id: 'invoice_number', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            accessor: d => (d.invoice_number) !== null ? `${d.invoice_number}` : '',
          },
          {
            id: 'base_amount', // Required because our accessor is not a string
            Header: t('condition_amount'),
            accessor: d => `${formatCurrencyCl(d.base_amount)}`,
          },
          {
            id: 'client', // Required because our accessor is not a string
            Header: t('client'),
            accessor: d => (d.sender) !== null ? `${d.sender.idPerson.name}` : '',
          },
          {
            id: 'provider', // Required because our accessor is not a string
            Header: t('provider'),
            accessor: d => (d.receiver) !== null ? `${d.receiver.idPerson.name}` : '',
          },
        ];
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data: response,
      title: pathname === 'log-view' ? t('logView') : t('logTransaction'),
    };
  };

  closeModal = (onToggle) => {
    onToggle();
  };

  handleDateChange = (dateName, newDate) => {
    this.setState({
      [dateName]: newDate,
    });
  };

  getLogData = () => {
    const { fetchLog } = this.props;
    const { startDate, endDate } = this.state;

    const start = dateToUTC(startDate, 'start')
    const end = dateToUTC(endDate, 'end')

    fetchLog({
      params: { start, end },
      callback: (response) => {
        this.setState({
          response: response,
          pathname: 'log-view'
        });
      }
    });
  };

  render() {
    const {
      showMessage,
      t,
      log: { loading },
      router: { location: { pathname } },
    } = this.props;
    const { source_log, params, respJson, respXml, startDate, endDate } = this.state;
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle}>
                        <Panel headingText={t('logView')}>
                          <div className="field is-horizontal">
                            <div className="field-body columns">
                              <div className="column">
                                <TextInput
                                  name="source_log"
                                  label={t('source_log')}
                                  value={source_log}
                                  onChange={this.closeModal}
                                  type="text"
                                  inputClasses="has-text-left"
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="field is-horizontal">
                            <div className="field-body columns">
                              <div className="column">
                                <TextArea
                                  name="params"
                                  label={t('params')}
                                  value={params}
                                  onChange={this.closeModal}
                                  type="text"
                                  inputClasses="has-text-right"
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="field is-horizontal">
                            <div className="field-body columns">
                              <div className="column">
                                <TextArea
                                  name="respJson"
                                  label={t('respJson')}
                                  value={respJson}
                                  onChange={this.closeModal}
                                  type="text"
                                  inputClasses="has-text-right"
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="field is-horizontal">
                            <div className="field-body columns">
                              <div className="column">
                                <TextArea
                                  name="respXml"
                                  label={t('respXml')}
                                  value={respXml}
                                  onChange={this.closeModal}
                                  type="text"
                                  inputClasses="has-text-right"
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="is-fullwidth has-text-right">
                            <button
                              type="button"
                              className="button is-primary"
                              onClick={() => onToggle({})}
                            >
                              {t('accept')}
                            </button>
                          </div>
                        </Panel>
                      </Modal>
                    )
                  }
                  {data
                  && (
                    <>
                      <div className="column is-full">
                        <Panel headingText={title}>
                        { pathname === '/log-view' ? 
                            <div className="field is-horizontal">
                              <div className="field-body columns">
                                <form autoComplete='off'>
                                  <div className="columns is-centered" style={{ width: '100%' }}>
                                    <div className="column">
                                      <DateInput
                                        name="invoice_date"
                                        label={t('since')}
                                        value={startDate}
                                        onChange={(value) => this.handleDateChange('startDate', value)}
                                        type="text"
                                        fieldClasses="is-expanded"
                                        controlClasses="has-icons-left"
                                        icon="far fa-calendar-alt"
                                        iconPosition="is-left"
                                      />
                                    </div>
                                    <div className="column">
                                      <DateInput
                                        name="invoice_date"
                                        label={t('until')}
                                        value={endDate}
                                        onChange={(value) => this.handleDateChange('endDate', value)}
                                        type="text"
                                        fieldClasses="is-expanded"
                                        controlClasses="has-icons-left"
                                        icon="far fa-calendar-alt"
                                        iconPosition="is-left"
                                      />
                                    </div>
                                    <div className="column">
                                      <div style={{ marginTop: '24px' }}>
                                        <button
                                          type="button"
                                          className="button is-primary"
                                          onClick={() => { (!startDate || !endDate) ?
                                            showMessage({
                                              message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
                                              config: TOAST_CONFIG.ERROR,
                                            }) : (startDate.getTime() > endDate.getTime()) ? 
                                            showMessage({
                                              message: <strong style={{ color: '#ffffff' }}>{t('errorDate')}</strong>,
                                              config: TOAST_CONFIG.ERROR,
                                            }) : this.getLogData()
                                          }}
                                        >
                                          {t('search')}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div> : ''
                          }
                          <TableSimple data={data} columns={columns}/>
                        </Panel>
                      </div>
                    </>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

LogView.propTypes = {
  t: PropTypes.func.isRequired,
  fetchLog: PropTypes.func.isRequired,
};

const mapStateToProps = ({ role, auth, log, router, }) => (
  {
    role,
    auth,
    log,
    router,
  }
);
export default compose(
  connect(mapStateToProps, { ...roleActions, ...authActions, ...logActions }),
  withNamespaces(),
)(LogView);
