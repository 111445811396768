/**
 * Created by Nelson on 29/10/18.
 */

import React from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Card, Form, Panel, SelectSingle, TextInput, } from '../index';
import DateInput from '../DateInput';
import { getPath, removeCommas } from '../../util';
import ReactTooltip from 'react-tooltip';
import './styles.scss';

const LoadInvoicesForm = (props) => {
  const {
    t,
    disabled,
    formData,
    handleInputChange,
    handleFileChange,
    handleDateChange,
    handleClientChange,
    invoice_amount,
    invoiceDate,
    description,
    invoiceNumber,
    clients,
    selectedClient,
    onSubmit,
    file,
    fileName,
    detailAmount,
    handleOnInputChange,
    inputValue,
    //currency,
    infoSii,
    onToggle,
    type,
    handleSelectChange,
    typeList,
    //gateway,
    descriptionCommerceCode,
    commerceCodes,
    commerceCode,
    handleSelectCommerce,
    currency,
    tax_amount,
    base_amount,
    handleAmountChange,
    handleTaxChange,
  } = props;
  return (
    <div className="columns">
      <div className="column is-full">
        <Panel headingText={t('loadInvoice')}>
          <Form
            submitText={t('send')}
            onSubmit={(event) => {
              event.preventDefault();
              onSubmit(formData);
            }}
            //buttonClasses={`mr-8px ${true ? 'is-hidden' : 'is-hidden'}`}
            buttonClasses="mr-8px"
            disabled={disabled}
          >
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-two-third" data-type='info' data-tip={t('sameInvoiceDate')}>
                  <DateInput
                    name="invoice_date"
                    label={t('invoiceDate')}
                    value={invoiceDate}
                    onChange={handleDateChange}
                    type="text"
                    fieldClasses="is-expanded"
                    controlClasses="has-icons-left"
                    icon="fas fa-calendar-alt"
                    iconPosition="is-left"
                    dateFormat="dd/MM/yyyy"
                    disabled={true}
                  />
                </div>
                <ReactTooltip/>
                <div className="column is-two-third">
                  <TextInput
                    name="invoice_number"
                    type="text"
                    label={t('invoicesNumber')}
                    value={invoiceNumber === null ? '' : invoiceNumber}
                    onChange={handleInputChange}
                    placeholder={t('invoicesNumber')}
                    maxlength="20"
                    controlClasses="is-expanded has-icons-left"
                    inputClasses="has-text-right"
                    iconPosition=""
                    icon=""
                  />
                </div>
                <div className="column is-two-third">
                  <TextInput
                    name="invoice_amount"
                    label={t('amountTotal')}
                    value={invoice_amount}
                    onChange={handleInputChange}
                    placeholder="00,00"
                    title={t('amountTotal')}
                    type="text"
                    controlClasses="is-expanded has-icons-left"
                    inputClasses="has-text-right"
                    min="0.10"
                    max="999999999999999.99"
                    //width="88%"
                    width={currency === 'L' ? "95%" : "88%" }
                    currency={currency}
                    // step="0.01"`
                    //disabled
                  />
                </div>
                {/*<div className="column is-one-third select-lead-require">
                  <div className="width-select-lead-require">
                    <SelectSingle
                      name="client"
                      label={t('customers')}
                      placeholder={t('SelectOption')}
                      onChange={handleClientChange}
                      options={handleOnInputChange === null || inputValue.length > 1 ? [
                        ...clients.map((client, index) => ({
                          label: `${getPath(['idPerson', 'name'], client)} - ${getPath(['idPerson', 'idDocumentType', 'document_type'], client)} ${getPath(['idPerson', 'document_number'], client)}`,
                          value: index,
                        })),
                      ] : [{}]}
                      selectClasses="is-fullwidth"
                      controlClasses="has-icons-left"
                      icon="far fa-user"
                      iconPosition="is-left"
                      value={ selectedClient + 1 }
                      isSearchable
                      onInputChange={handleOnInputChange}
                      inputValue={inputValue}
                    />
                  </div>
                </div>
                currency === 'USD' && (
                  <div className="column is-two-third">
                    <TextInput
                      name="invoice_amount"
                      label={t('paymentAmount')}
                      value={invoiceAmount === null ? '' : invoiceAmount}
                      onChange={handleInputChange}
                      placeholder="00,00"
                      title={t('valorNeto')}
                      type="text"
                      controlClasses="is-expanded has-icons-left"
                      inputClasses="has-text-right"
                      min="0.10"
                      max="999999999999999.99"
                      // step="0.01"
                      iconPosition="is-left"
                      icon="far fa-usd"
                      currency={currency}
                      width="89%"
                    />
                  </div>
                )*/}
                {/*currency === 'CLP' && (
                  <div className="column is-two-third">
                    <TextInput
                      name="invoice_amount"
                      label={t('paymentAmount')}
                      value={invoiceAmount === null ? '' : invoiceAmount}
                      onChange={handleInputChange}
                      placeholder={t('valorNeto')}
                      title={t('intNumber')}
                      type="number"
                      controlClasses="is-expanded has-icons-left"
                      inputClasses="has-text-right"
                      min="1"
                      max="999999999999999"
                      // step="0.01"
                      iconPosition="is-left"
                      icon="far fa-usd"
                      currency={currency}
                      width="89%"
                    />
                  </div>
                )*/}
              </div>
            </div>
            {/*<div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column">
                  <TextInput
                    name="base_amount"
                    label={t('baseAmount')}
                    value={base_amount}
                    onChange={handleAmountChange}
                    placeholder="00,00"
                    title={t('baseAmount')}
                    type="text"
                    controlClasses="is-expanded has-icons-left"
                    inputClasses="has-text-right"
                    min="0.10"
                    max="999999999999999.99"
                    width="90%"
                    currency={currency}
                    // step="0.01"
                  />
                </div>
                <div className="column">
                  <TextInput
                    name="tax_amount"
                    label={t('tax')}
                    value={tax_amount === 0 ? '' : tax_amount}
                    onChange={handleTaxChange}
                    placeholder="00,00"
                    title={t('tax')}
                    type="text"
                    controlClasses="is-expanded has-icons-left"
                    inputClasses="has-text-right"
                    min="0.10"
                    max="999999999999999.99"
                    width="90%"
                    //currency={currency}
                    // step="0.01"
                  />
                </div>
                <div className="column">
                  <TextInput
                    name="invoice_amount"
                    label={t('amountTotal')}
                    value={invoice_amount}
                    //onChange={handleInputChange}
                    placeholder="00,00"
                    title={t('amountTotal')}
                    type="text"
                    controlClasses="is-expanded has-icons-left"
                    inputClasses="has-text-right"
                    min="0.10"
                    max="999999999999999.99"
                    width="90%"
                    //currency={currency}
                    // step="0.01"`
                    disabled
                  />
                </div>
              </div>
            </div>*/}
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-one-third select-lead-require">
                  <div className="width-select-lead-require">
                    <SelectSingle
                      name="client"
                      label={t('customer')}
                      placeholder={t('SelectOption')}
                      onChange={handleClientChange}
                      options={/*handleOnInputChange === null || inputValue.length > 1 ?*/ [
                        ...clients.map((client, index) => ({
                          label: `${getPath(['idPerson', 'name'], client)} - ${getPath(['idPerson', 'idDocumentType', 'document_type'], client)} ${getPath(['idPerson', 'document_number'], client)}`,
                          value: index,
                        })),
                      ] /*: [{}]*/}
                      selectClasses="is-fullwidth"
                      controlClasses="has-icons-left"
                      icon="far fa-user"
                      iconPosition="is-left"
                      value={selectedClient}
                      isSearchable
                      onInputChange={handleOnInputChange}
                      inputValue={inputValue}
                    />
                  </div>
                </div>
                <div className="column is-two-third">
                  <TextInput
                    name="description"
                    type="text"
                    label={t('description')}
                    value={description}
                    onChange={handleInputChange}
                    placeholder={t('description')}
                    maxlength="120"
                    controlClasses="is-expanded"
                    inputClasses="has-text-left"
                  />
                </div>
              </div>
            </div>
            <div className="field is-horizontal" style={{ display: 'none' }}>
              <div className="field-body columns">
                <div className="column is-two-third">
                  <SelectSingle
                    name="type"
                    label={t('documentType')}
                    selectClasses="is-fullwidth"
                    placeholder={t('Select')}
                    onChange={handleSelectChange}
                    options={[
                      ...typeList.map((item, index) => ({
                        label: `${t(item.description)} `,
                        value: item.type,
                      })),
                    ]}
                    value={type}
                    //isRequired={required}
                    //isDisabled={hidden ? hidden : edit}
                    //isRequired={!edit}
                  />
                </div>
              </div>
            </div>
            {/*<div className="columns is-centered">
              {detailAmount
                && Object.keys(detailAmount).map(key => (
                  <div className="column is-2">
                    <div className="tags has-addons">
                      <span className="tag">{key}</span>
                      <span className="tag is-link">{detailAmount[key] || '-'}</span>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-one-third select-lead-require">
                  <div className="width-select-lead-require">
                    <SelectSingle
                      name="client"
                      label={t('buyer')}
                      placeholder={t('SelectOption')}
                      onChange={handleClientChange}
                      options={handleOnInputChange === null || inputValue.length > 1 ? [
                        ...clients.map((client, index) => ({
                          label: `${getPath(['idPerson', 'name'], client)} - ${getPath(['idPerson', 'idDocumentType', 'document_type'], client)} ${getPath(['idPerson', 'document_number'], client)}`,
                          value: index,
                        })),
                      ] : [{}]}
                      selectClasses="is-fullwidth"
                      controlClasses="has-icons-left"
                      icon="far fa-user"
                      iconPosition="is-left"
                      value={ selectedClient + 1 }
                      isSearchable
                      onInputChange={handleOnInputChange}
                      inputValue={inputValue}
                    />
                  </div>
                </div>
                <div className="column is-two-third">
                  <TextArea
                    name="invoice_description"
                    label={t('description')}
                    value={description}
                    onChange={handleInputChange}
                    placeholder=""
                    controlClasses="is-expanded"
                    maxLength="120"
                  />
                </div>
              </div>
            </div>*/}
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-one-third select-lead-require">
                  <div className="width-select-lead-require">
                    <SelectSingle
                      name="commerceCode"
                      label={t('affiliateNumber')}
                      selectClasses="is-fullwidth"
                      placeholder={t('Select')}
                      onChange={handleSelectCommerce}
                      options={
                        [
                          ...commerceCodes.map((item, index) => ({
                            label: item.commerce_code,
                            value: index,
                          })),
                        ]}
                      value={commerceCode}
                    />
                  </div>
                </div>
                <div className="column is-two-third">
                  <TextInput
                    name="descriptionCommerceCode"
                    label={t('description')}
                    value={descriptionCommerceCode}
                    onChange={handleInputChange}
                    placeholder={t('description')}
                    type="text"
                    controlClasses="is-expanded"
                    title="Three letter country code"
                    disabled={true}
                    isRequired
                  />
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-full">
                  <TextInput
                    name="file"
                    label={t('selectInvoice')}
                    value={file}
                    fileName={fileName}
                    onChange={handleFileChange}
                    // placeholder="Suba un documento"
                    type="file"
                    // fieldClasses="file has-name"
                    //  labelClasses="file-label"
                    //  controlClasses="is-expanded has-icons-left file-label"
                    //  inputClasses="has-text-right "
                    //  iconPosition="is-left"
                    //  icon="fas fa-upload"
                  />
                  <strong> <span className="texto-file"
                                 style={{ color: '#A6192E' }}>{t('allowedFormatsPDF')}</span></strong>
                </div>
              </div>
            </div>
            {selectedClient != null
              && (
                <div className="columns">
                  <div className="column is-8-tablet is-offset-2-tablet mt-24px">
                    <Card
                      title={t('customerInformation')}
                      subtitle={`${getPath(['idPerson', 'name'], clients[selectedClient])}`}
                      items={[
                        {
                          name: 'documentNumber',
                          value: `${getPath(['idPerson', 'idDocumentType', 'document_type'], clients[selectedClient])} ${getPath(['idPerson', 'document_number'], clients[selectedClient])}`,
                        }, {
                          name: 'address',
                          value: removeCommas(`${getPath(['address_full'], clients[selectedClient])}`),
                        }, {
                          name: 'email',
                          value: `${getPath(['idPerson', 'idEmailActive', 'email'], clients[selectedClient])}`,
                        }, {
                          name: 'arrangedDays',
                          value: ((commerceCodes.length) > 0 && (commerceCode !== -1)) ? commerceCodes[commerceCode].payment_condition[0].days+" días" : "",
                        }, {
                          name: 'startCountingFrom',
                          value: clients[selectedClient].paymentConditionAsBuyer[0].due_from_approval == 0 ? t('fromReceptionDate') : t('approvalDate'),
                        },//{
                        //   name: 'numberInstallments',
                        // eslint-disable-next-line max-len
                        //   value: `${clients[selectedClient].paymentConditionAsBuyer[0].installments}`,
                        // },
                      ]}
                    />
                  </div>
                </div>
            )}
            {/*<div className="column is-full" style={{ display: 'none' }}>
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="terms-box">
                    <p>{t('acceptTermsGateway')}</p>
                  </div>
                </div>
              </div>
            </div>*/}
            <br/>
            {/*<div className="field is-horizontal">
              <div className="field-body columns">
                <div className="field is-grouped is-grouped-right"
                     style={{ display: true ? '' : 'none' }}>
                  <button
                    type="button"
                    className="button is-primary"
                    onClick={infoSii.bind(this, onToggle)}
                    disabled={disabled}
                  >
                    {t('consult')}
                  </button>
                  &nbsp;&nbsp;
                </div>
              </div>
            </div>*/}
          </Form>
        </Panel>
      </div>
    </div>
  );
};

LoadInvoicesForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleClientChange: PropTypes.func.isRequired,
  invoiceAmount: PropTypes.string,
  fileName: PropTypes.string,
  disabled: PropTypes.bool,
  formData: PropTypes.shape({}).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf(PropTypes.object),
  detailAmount: PropTypes.shape({}),
  invoiceDate: PropTypes.instanceOf(Date).isRequired,
  description: PropTypes.string,
  invoiceNumber: PropTypes.string,
  selectedClient: PropTypes.number,
  file: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  inputValue: PropTypes.string,
  handleOnInputChange: PropTypes.func,
};

LoadInvoicesForm.defaultProps = {
  disabled: false,
  clients: [],
  detailAmount: {},
  invoiceAmount: '',
  description: '',
  invoiceNumber: '',
  selectedClient: '',
  file: '',
  fileName: '',
  handleOnInputChange: null,
  inputValue: '',
};


export default withNamespaces()(LoadInvoicesForm);
