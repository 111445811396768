import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';
import {
  Panel,
  TableSimple,
  Toggle,
  Loading,
} from '../../components';
import { routeActions } from '../../state/ducks/route';
import RouteForm from '../../components/RouteForm';
import './styles.scss';

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
    name: '',
  });

  componentDidMount() {
    const { fetchRoutes } = this.props;
    fetchRoutes({});
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = (onToggle) => {
    const {
      t, route: { list }, deleteRoute,
    } = this.props;

    const columns = [
      {
        id: 'name', // Required because our accessor is not a string
        Header: t('name'),
        accessor: d => `${d.path} `,
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        accessor: d => `${d.id} `,
        Cell: cellInfo => (
          <td>
            <Link
              className="button is-danger is-inverted"
              to="/route-list"
              onClick={() => deleteRoute({ data: { route_id: cellInfo.value }, t })}
            >
              <i className="fas fa-times mr-8px" aria-hidden="true" />
              {t('delete')}
            </Link>
            <button
              className="button is-primary is-inverted"
              onClick={() => {
                this.setState({
                  id: cellInfo.original.id,
                  name: cellInfo.original.path,
                }, () => {
                  onToggle({
                    isEdit: true,
                    route: cellInfo.original,
                  });
                });
              }}
            >
              <i className="far fa-edit mr-8px" aria-hidden="true" />
              {t('edit')}
            </button>
          </td>
        ),
      },
    ];
    const data = list;
    const title = t('routes');
    return {
      columns,
      data,
      title,
    };
  };

  updateRoute = (onToggle) => {
    const { editRoute, fetchRoutes } = this.props;
    const { id, name } = this.state;
    const data = {
      id,
      path: name,
    };
    editRoute({
      data,
      callback: () => {
        fetchRoutes({});
        onToggle();
        this.resetState();
      },
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { t, route: { loading } } = this.props;
    const { name } = this.state;

    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading />}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle}>
                        <RouteForm
                          t={t}
                          onSubmit={() => this.updateRoute(onToggle)}
                          handleInputChange={this.handleInputChange}
                          name={name}
                        />
                      </Modal>
                    )
                  }
                  {data
                    && (
                      <div className="column is-full">
                        <Panel headingText={title}>
                          <div className="panel-top-content">
                            <Link
                              className="button is-primary"
                              to="/register-route"
                              onClick={() => onToggle({
                                // transaction: cellInfo.original,
                                // paymentCondition: advance_condition,
                                // onSubmit: this.advancePayment,
                              })}
                            >
                              <i className="fas fa-plus mr-8px" aria-hidden="true" />
                              {t('newRoute')}
                            </Link>
                          </div>
                          <TableSimple data={data} columns={columns} />
                        </Panel>
                      </div>
                    )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

Routes.propTypes = {
  t: PropTypes.func.isRequired,
  fetchRoutes: PropTypes.func.isRequired,
  deleteRoute: PropTypes.func.isRequired,
  editRoute: PropTypes.func.isRequired,
  route: PropTypes.shape().isRequired,
};


const mapStateToProps = ({ route }) => (
  {
    route,
  }
);
export default compose(
  connect(mapStateToProps, { ...routeActions }),
  withNamespaces(),
)(Routes);
