import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading, Panel, TableSimple, Toggle, } from '../../components';
import { authActions } from '../../state/ducks/auth';
import { clientsActions } from '../../state/ducks/clients';
import { providersActions } from '../../state/ducks/providers';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { getPath } from '../../util';
import { statsActions } from '../../state/ducks/stats';

class CommerceCode extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
  });

  getTitle = () => {
    const {
      stats: { sellers },
      providers: { receiverCommerce },
    } = this.props;

    let name;
    let post = 0;
    if (receiverCommerce.hasOwnProperty('user')) {
      post = sellers.map(
        (listClientMap, index) => ({
          ...listClientMap,
          index,
        }),
      )
        .filter(listClientFil => listClientFil.id
          === receiverCommerce.user)[0].index;
      name = getPath(['idPerson', 'name'], sellers[post]);
    }
    return name;
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = () => {
    let columns;
    let data;
    let title;
    const {
      providers: { receiverCommerce },
      t,
    } = this.props;

    columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: 'commerce_code', // Required because our accessor is not a string
        Header: t('affiliateNumber'),
        width: 280,
        accessor: d => `${d.commerce_code}`,
      },
      {
        id: 'terminal', // Required because our accessor is not a string
        Header: t('eCommerceTerminal'),
        accessor: d => `${d.terminal}`,
      },
      {
        id: 'currency_id', // Required because our accessor is not a string
        Header: t('currency'),
        width: 180,
        accessor: d => d.currency_id !== null ? `${d.currency_id}` : '',
      },
      {
        id: 'description', // Required because our accessor is not a string
        Header: t('description'),
        accessor: d => `${d.description}`,
      },
    ];

    if(receiverCommerce !== null){
      data = (typeof(receiverCommerce) !== "undefined" && receiverCommerce.hasOwnProperty('commerce_codes')) ? receiverCommerce.commerce_codes : [];
      title = (typeof(receiverCommerce) !== "undefined" && receiverCommerce.hasOwnProperty('commerce_codes')) ?
        t('vendorMerchantAffiliateCodes', { provider: this.getTitle() }) : t('vendorMerchantAffiliateCodes'); /*: t('vendorMerchantAffiliateCodes');*/  
    }else{
      data = [];
      title = [];
    }

    return {
      columns,
      data,
      title,
    };
  };

  render() {
    const {
      t,
      history,
      getProviderCommerceCode,
      clients: { loading: clientLoading },
      providers: { receiverCommerce, loading: providerLoading },
    } = this.props;

    const loading = clientLoading || providerLoading;
    return (
      <Toggle>
        {({}) => {
          const { title, columns, data } = this.getGridSettings();
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    data && (
                      <div className="column is-full">
                        <Panel headingText={title}>
                          <div className="panel-top-content">
                            <button
                              className="button is-primary"
                              onClick={() => {
                                getProviderCommerceCode({
                                  params: {
                                    id: receiverCommerce.user,
                                  },
                                  callback: () => {
                                    history.push({
                                      pathname: '/commerce-code',
                                    });
                                  }
                                });
                              }}
                            >
                              <i className="fas fa-sync-alt mr-8px" aria-hidden="true"/>
                              {t('updateInformation')}
                            </button>
                          </div>
                          <TableSimple data={data} columns={columns}/>
                          <br/>
                          <div className="return-box">
                            <div className="field is-grouped is-grouped-right">
                              <button
                                type="button"
                                className="button is-primary is-danger"
                                style={{
                                  position: 'absolute',
                                  bottom: '3.2em',
                                  right: '2em',
                                }}
                                onClick={() => {
                                  history.push('/dashboard');
                                }}
                              >
                                {t('goBack')}
                              </button>
                            </div>
                          </div>
                        </Panel>
                      </div>
                    )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

CommerceCode.propTypes = {
  t: PropTypes.func.isRequired,
  providers: PropTypes.shape(Object).isRequired,
  getProviderCommerceCode: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({
                           auth,
                           clients,
                           providers,
                           stats,
                         }) => (
  {
    auth,
    clients,
    providers,
    stats,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...authActions,
    ...clientsActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...statsActions,
  }),
  withNamespaces(),
)(CommerceCode);
