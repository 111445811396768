import { paymentConditionsActions, paymentConditionsTypes } from '../ducks/paymentConditions';

const url = '/b2b-payment-conditions';
const savePaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.CREATE_PAYMENT_CONDITION) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url,
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const editPaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.EDIT_PAYMENT_CONDITION) {
    return;
  }

  const {
    id,
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'PUT',
        url: `${url}/${id}`,
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const deletePaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.DELETE_PAYMENT_CONDITION) {
    return;
  }

  const {
    data: {
      id,
      is_request = false,
      person,
    },
    callback = () => {
    },
    //t = () => {},
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `b2b-payment-conditions/delete-condition?id=${id}&is_request=${is_request}&person=${person}`,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      /* onComplete: () => {
        callback();
        dispatch(paymentConditionsActions.showMessage({
          message: t('deletePaymentCondition'),
          config: TOAST_CONFIG.SUCCESS,
        }));
      },
      onError: () => {
        dispatch(paymentConditionsActions.showMessage({
          message: t('cantDelete'),
          config: TOAST_CONFIG.INFO,
        }));
      },*/
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const getPaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.GET_PAYMENT_CONDITION) {
    return;
  }

  const {
    params: {
      person = '',
      isClient = '',
      idCondition = '',
    } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b/conditions-providers?person=${person}&isClient=${isClient}&idCondition=${idCondition}`,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(paymentConditionsActions.savePaymentCondition(data));
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const paymentRequest = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.PAYMENT_REQUEST) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `${url}/approve-payment-request`,
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const requestReceived = ({ dispatch, getState }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.REQUEST_BANK) {
    return;
  }

  const {
    auth: { user: { profile: { role } } },
    router: {
      location: { pathname }
    },
  } = getState() || {};

  const {
    params: {
      idBank = '',
      receiver = pathname !== '/payment-request-sent',
      is_bank = (role === 'buyer' || role === 'seller') ? false : true,
    } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `${url}/requests-bank?id=${idBank}&receiver=${receiver}&is_bank=${is_bank}`,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          if (receiver === true) {
            dispatch(paymentConditionsActions.saveListReceiver(data));
          } else {
            dispatch(paymentConditionsActions.savePaymentCondition(data));
          }
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const requestPaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.REQUEST_PAYMENT_CONDITION) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-payment-conditions/create-payment-request',
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const approveProductPayment = ({dispatch}) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.APPROVE_PRODUCT_PAYMENT) {
    return;
  }
  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-payment-conditions/approve-payment-assist-card',
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const unsuscribeProductPayment = ({dispatch}) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.UNSUSCRIBE_PRODUCT_PAYMENT) {
    return;
  }
  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-payment-conditions/approve-request-unsubscribe-product',
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const requestUnsubscribeProduct = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.REQUEST_UNSUBSCRIBE_PRODUCT) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-payment-conditions/create-request-unsubscribe-product',
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const acceptPaymentCondition = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.ACCEPT_PAYMENT_CONDITION) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'b2b-payment-conditions/accept-discount-rate',
        data,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const getPaymentConditionLog = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.GET_PAYMENT_CONDITION_LOG) {
    return;
  }

  const {
    params: {
      idUser = '',
    } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `${url}/log?user=${idUser}`,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(paymentConditionsActions.paymentConditionLog(data));
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

const getLogUsers = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== paymentConditionsTypes.GET_LOG_USERS) {
    return;
  }

  const {
    params: {
      user = '',
      counterpart = '',
    } = {},
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: paymentConditionsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `${url}/log-users?user=${user}&counterpart=${counterpart}`,
      },
      authorization: true,
      onStart: paymentConditionsActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(paymentConditionsActions.paymentConditionLog(data));
        }
      },
      onEnd: paymentConditionsActions.endFetch,
    },
  });
};

export default [savePaymentCondition, editPaymentCondition, deletePaymentCondition, getPaymentCondition, paymentRequest, requestReceived, requestPaymentCondition, approveProductPayment, unsuscribeProductPayment, requestUnsubscribeProduct, acceptPaymentCondition, getPaymentConditionLog, getLogUsers];
