import React from 'react';
import './styles.scss';
import AmexLogo from '../../../../resources/images/assets/amex_logo2.png';

const Footer = () => {
  const d = new Date();
  const n = d.getFullYear();
  return (
    <footer>
      <div>
        <figure className="image mr-16px">
          <img src={AmexLogo} alt="logo"/>
        </figure>
        <b>
          Copyright ©
          {` ${n} `}
        </b>
        Business Link. Powered by American Express. All rights reserved.
      </div>

    </footer>
  );
};

export default Footer;