/* eslint-disable linebreak-style,template-curly-spacing */
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_VERSION, ENDPOINTS, ENV } from './env';

import logo from '../resources/images/auth/feedback.png';
import bgNavResponsive from '../resources/images/assets/logo.svg';
import bgGeneral from '../resources/images/bg-general.png';
import avatar from '../resources/images/assets/avatar.png';
import groupUserFile from '../resources/files/Carga_Masiva_de_Usuarios_BAC-GT.xlsx';
import logoB2B from '../resources/images/assets/logo.svg';

// Se usa para manejar una version, del estado de la aplicación,
// cuando se cambia, setea el storage del cliente por el estado por defecto.
export const STATE_VERSION = 0.38;

//comment test
// Constante para definir la edad minima del usuario para usar la app
// export const MAX_AGE = 18;

// Colores base de la aplicación
export const COLORS = {};

// Imagenes de toda la aplicacion separadas por categorias
export const IMG = {
  logo,
  bgNavResponsive,
  avatar,
  bgGeneral,
  logoB2B,
};

export const FILES = {
  groupUserFile,
};

export const TOAST_CONFIG = {
  SUCCESS: {
    type: toast.TYPE.SUCCESS,
    autoClose: 10000,
    position: toast.POSITION.TOP_CENTER,
  },
  ERROR: {
    type: toast.TYPE.ERROR,
    autoClose: 10000,
    position: toast.POSITION.TOP_CENTER,
  },
  WARNING: {
    type: toast.TYPE.WARNING,
    autoClose: 10000,
    position: toast.POSITION.TOP_CENTER,
  },
  INFO: {
    type: toast.TYPE.INFO,
    autoClose: 10000,
    position: toast.POSITION.TOP_CENTER,
  },
};

// Se genera la URL base de acuerdo a los endpoints del entorno
// Opcionalmente se puede usar versionamiento de la API
const { API } = ENDPOINTS;
export const URL = API[ENV];
export const API_URL = `${URL}/${API_VERSION}/`;
export const chileAvatarColors = ['#FF6D6A', '#35C4B5',
  '#FDB92D', '#A7A8AA',
  '#CCD1DE', '#66A9E2',
  '006FCF'];
// En dado caso que se use axios
axios.defaults.baseURL = API_URL;
axios.defaults.withCredentials = true;

// axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'POST GET PUT DELETE';

export const captchaKey = '6LfUd8AUAAAAABvw5O60FwEebP_fkOqiaDQsxCfi';