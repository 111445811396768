import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';


/**
 * Created by Feedback Software on 25/10/18.
 * @param name
 * @param placeholder
 * @param value
 * @param onChange function to handle the state in the father components
 * @param type
 * @param helpText
 * @param label if empty doesn't show
 * @param disabled boolean to disable input
 * @param fieldClasses array of classes for the field
 * @param inputClasses array of classes for the input
 * @param controlClasses array of classes for the control
 * @param icon font awesome class of icon to display
 * @param iconPosition can receive 'is-left' or 'is-right' to position as class
 * @param min minimal amount used when the input is number
 * @param step incremental amount
 * @returns {*}
 * @constructor
 */
const TextInput = ({
                     name,
                     placeholder,
                     value,
                     fileName,
                     onChange,
                     type = 'text',
                     helpText,
                     label,
                     disabled = false,
                     fieldClasses,
                     inputClasses,
                     controlClasses,
                     labelClasses,
                     icon = null,
                     iconPosition = null,
                     min = null,
                     max = null,
                     step = null,
                     pattern = '',
                     isRequired,
                     title,
                     maxlength,
                     currency,
                     width = '100%',
                     band,
                     login = false,
                     isEmailEqual,
                     onClickIcon,
                     onKeyPress,
                   }) => (
  <div className={`field ${fieldClasses}`}>
    <label className={`label ${labelClasses}`}>
      {label}
      <label>{isRequired ? ' *' : ''}</label>
      <div className={`control ${controlClasses}`}>
        {width !== '100%' && (
          <label>
            {currency} &nbsp;
          </label>
        )}
        <input
          className={`input ${inputClasses}`}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          min={min}
          max={max}
          step={step}
          pattern={pattern}
          required={isRequired}
          title={title}
          maxLength={maxlength}
          onKeyPress={onKeyPress}
          style={width !== '100%' ? {
            maxWidth: width,//'88%',
            width: width
          } : {
            maxWidth: width,
            width: width,
            height: login ? '50px' : '',
            borderRadius: login ? '10px' : '',
            marginLeft: login ? '5px' : '',
          }}
        />
        {type === 'file' && band === true && (
          <Fragment>
        <span className="file-cta">
          <span className="file-icon">
            <i className={icon}></i>
          </span>
          <span className="file-label">
            {placeholder}
          </span>
        </span>
            <span className="file-name">
          {fileName}
        </span>
          </Fragment>
        )
        }
        {
          (!!icon && type !== 'file') && (
            <span className={`icon ${iconPosition}`} onClick={onClickIcon}>
              <i className={`${icon}`}/>
            </span>
          )
        }
        { type === "email" && (
          <>
            <span className="icon is-small is-left">
              <i className="fas fa-envelope"></i>
            </span>
            {
            name === "emailConfirmation" &&
            <span className={`icon is-small is-right has-text-${isEmailEqual ? 'success' : 'danger'}`}>
              <i className={`fas ${isEmailEqual ? 'fa-check' : 'fa-times'}`}></i>
            </span>
            }
          </>
        )}
        {/*type === 'file' && (
          <Fragment>
            {/* <span className="file-cta">
               <span className="file-icon">
                <i className={icon} />
              </span>
              <span className="file-label">
                {placeholder}
              </span>
            </span> */}
        {/* <span className="file-name">
              {fileName}
            </span> */}
        {/* </Fragment>
        )
        */}
        {/*
          (!!icon && type !== 'file') && (
            <span className={`icon ${iconPosition}`}>
              <i className={`${icon}`}/>
            </span>
          )
        */}
      </div>
    </label>
    <p className="help is-danger" style={{ marginLeft: '10px' }}>{helpText}</p>
  </div>
);

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]),
  fileName: PropTypes.string,
  onChange: PropTypes.func,
  onClickIcon: PropTypes.func,
  type: PropTypes.string,
  helpText: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  fieldClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  controlClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
  pattern: PropTypes.string,
  isRequired: PropTypes.bool,
  isEmailEqual: PropTypes.bool,
  title: PropTypes.string,
  maxlength: PropTypes.number,
  min: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  max: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  step: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

TextInput.defaultProps = {
  placeholder: null,
  value: '',
  type: 'text',
  fileName: '',
  onChange: () => { },
  onClickIcon: () => { },
  helpText: null,
  label: null,
  disabled: false,
  fieldClasses: '',
  inputClasses: '',
  controlClasses: '',
  labelClasses: '',
  icon: null,
  iconPosition: null,
  min: null,
  max: null,
  step: null,
  pattern: null,
  isRequired: false,
  title: '',
  maxlength: null,
  width: '100%',
  band: false,
  login: false,
  isEmailEqual: false,
  onkeydown: '',
};

export default TextInput;
