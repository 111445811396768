const START_FETCH = 'providers/START_FETCH';
const END_FETCH = 'providers/END_FETCH';
const END_ERROR = 'providers/END_ERROR';
const API_CALL = 'providers/API_CALL';
const ADD_PROVIDER = 'providers/ADD_PROVIDER';
const FETCH_TRANSACTIONS = 'providers/FETCH_TRANSACTIONS';
const EXTRACT = 'providers/EXTRACT';
const TRANSFER = 'providers/TRANSFER';
const UPDATE_TRANSACTIONS = 'providers/UPDATE_TRANSACTIONS';
const MESSAGE = 'providers/MESSAGE';
const CLEAR = 'providers/CLEAR';
const AUTHORIZE_TRANSACTION = 'providers/AUTHORIZE_TRANSACTION';
const UPDATE_CHARGE = 'providers/UPDATE_CHARGE';
const UPDATE_BALANCE = 'providers/UPDATE_BALANCE';
const CHECK_BALANCE = 'providers/CHECK_BALANCE';
const LOAD_INVOICE = 'providers/LOAD_INVOICE';
const ADVANCE_INVOICE = 'providers/ADVANCE_INVOICE';
const CREATE_PROVIDER = 'providers/CREATE_PROVIDER';
const GET_PROVIDERS = 'providers/GET_PROVIDERS';
const GET_PROVIDERS_CONDITIONS = 'providers/GET_PROVIDERS_CONDITIONS';
const SAVE_PROVIDERS = 'providers/SAVE_PROVIDERS';
const EDIT_PROVIDER = 'providers/EDIT_PROVIDER';
const ALTA_REQUIRE = 'providers/ALTA_REQUIRE';
const QR_READER = 'providers/QR_READER';
const SET_PROVIDERS_CONDITIONS = 'providers/SET_PROVIDERS_CONDITIONS';
const GET_PROVIDER = 'providers/GET_PROVIDER';
const SET_PROVIDER = 'providers/SET_PROVIDER';
const LOAD_SII_INVOICE = 'providers/LOAD_SII_INVOICE';
const INFO_SII_INVOICE = 'providers/INFO_SII_INVOICE';
const GET_TYPE_DTE = 'providers/GET_TYPE_DTE';
const GET_TYPE = 'providers/GET_TYPE';
const PROVIDER_COMMERCE_CODE = 'providers/PROVIDER_COMMERCE_CODE';
const GET_PROVIDER_COMMERCE_CODE = 'PROVIDERS/GET_PROVIDER_COMMERCE_CODE';
const GET_PAYMENT_COMMERCE_CODE = 'providers/GET_PAYMENT_COMMERCE_CODE';
const GET_COMMERCE_CODE = 'providers/GET_COMMERCE_CODE';
const USER_FILE_LOAD = 'providers/USER_FILE_LOAD';
const LOAD_PROVIDERS_GROUP = 'providers/LOAD_PROVIDERS_GROUP';
const BACK_PRE_INVOICE = 'providers/BACK_PRE_INVOICE';
const LOAD_INVOICE_GROUP = 'providers/LOAD_INVOICE_GROUP';
const LOAD_PRE_INVOICE = 'providers/LOAD_PRE_INVOICE';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  ADD_PROVIDER,
  FETCH_TRANSACTIONS,
  EXTRACT,
  TRANSFER,
  UPDATE_TRANSACTIONS,
  MESSAGE,
  CLEAR,
  AUTHORIZE_TRANSACTION,
  CHECK_BALANCE,
  UPDATE_CHARGE,
  UPDATE_BALANCE,
  LOAD_INVOICE,
  ADVANCE_INVOICE,
  CREATE_PROVIDER,
  GET_PROVIDERS,
  GET_PROVIDERS_CONDITIONS,
  SAVE_PROVIDERS,
  EDIT_PROVIDER,
  ALTA_REQUIRE,
  QR_READER,
  SET_PROVIDERS_CONDITIONS,
  GET_PROVIDER,
  SET_PROVIDER,
  LOAD_SII_INVOICE,
  INFO_SII_INVOICE,
  GET_TYPE_DTE,
  GET_TYPE,
  PROVIDER_COMMERCE_CODE,
  GET_PROVIDER_COMMERCE_CODE,
  GET_PAYMENT_COMMERCE_CODE,
  GET_COMMERCE_CODE,
  USER_FILE_LOAD,
  LOAD_PROVIDERS_GROUP,
  BACK_PRE_INVOICE,
  LOAD_INVOICE_GROUP,
  LOAD_PRE_INVOICE,
};
