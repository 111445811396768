/**
 * Created by Jonas on 02/09/19.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import Form from '../Form';
import TextInput from '../TextInput';
import SelectSingle from '../SelectSingle';
import { CreatableMultiSelect } from '..';
import ReactTooltip from 'react-tooltip';

const PersonForm = ({
                      t,
                      onSubmit,
                      handleInputChange,
                      handleInputChangeAdminDocument,
                      handleSelectChange,
                      handleItemChange,
                      name,
                      documentTypesList,
                      documentNumber,
                      adminDocumentType,
                      adminDocumentNumber,
                      documentType,
                      address,
                      items,
                      itemList,
                      email,
                      emailConfirmation,
                      isEmailEqual,
                      password,
                      persons,
                      baseDays,
                      // deferralDays,
                      percentage,
                      discountRate,
                      client,
                      adminName,
                      adminLastname,
                      adminPosition,
                      receiverDiscountShare,
                      handlePercentageChange,
                      clientPayment,
                      title,
                      period,
                      typeFlows,
                      typeFlow,
                      edit,
                      hidden,
                      code,
                      role,
                      cuttingCycle,
                      addCuttingCycle,
                      cycles,
                      phoneNumber,
                      phoneNumberAdmin,
                      user,
                      handleOnInputChange,
                      provinces,
                      province,
                      cantons,
                      canton,
                      districts,
                      district,
                      handleRegionChange,
                      handleCantonChange,
                      inputValue,
                      city,
                      paymentCurrency,
                      currency,
                      executives,
                      executivesList,
                      handleExecutivesChange,
                      handleOnInputChangeExecutive,
                      inputValueExecutive,
                      assistCard,
                      exist,
                      business_name,
                      newBankingExec,
                      addBankingExec,
                      deleteBankingExec,
                      handleInputChangeNewExc,
                      deleteCuttingCycle,
                      handleInputChangeTypeDocument,
                      documentTypeUser,
                      noRetain,
                      street,
                      number,
                      otherSign,
                      tradeInformation,
                      addTradeInformation,
                      deleteTradeInformation,
                      handleInputChangeTradeInformation,
                      handleSelectCurrency,
                      username_csp,
                      password_csp,
                      /*affiliateNumber,
                      eCommerceTerminal,
                      descriptionTradeInformation,*/
                      cif,
                      cancelSave,
                      categories,
                      category,
                      confirm,
                      send_notification,
                      pathName,
                      typeUser,
                    }) => (
  <Panel headingText={title}>
    <Form
      submitText={((role === 'seller') || (role === 'buyer')) ? t('confirm') : t('save')}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      buttonClasses={`mr-8px ${confirm ? '' : 'is-hidden'}`}
    >
      <div className="field is-horizontal subtitle is-5 text-black">
        {t('demographicInformation')}
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="name"
              label={typeUser === 'issuing_bank' ? t('customerName') : t('commerceName')}
              value={name}
              onChange={handleInputChange}
              placeholder={t('name')}
              type="text"
              controlClasses="is-expanded"
              //disabled={edit}
              isRequired={true}
            />
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column is-two-third">
            <label><strong>{` ${t('person')}`}</strong></label>
            <br />

            <input
              name="typePerson"
              value={'person'}
              onChange={handleInputChangeTypeDocument}
              type="radio"
              checked={noRetain === 'person'}
            />
            {` ${t('physical')}`}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input
              name="typePerson"
              value={'legal'}
              onChange={handleInputChangeTypeDocument}
              type="radio"
              checked={noRetain === 'legal'}
            />
            {` ${t('legal')}`}
          </div>
          <div className="column">
            <SelectSingle
              name="documentTypeUser"
              label={t('documentType')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={[
                ...documentTypesList.map((item, index) => ({
                  label: item.document_type,
                  value: index,
                })),
              ]}
              value={documentTypeUser}
              isDisabled={noRetain === 'legal'}
              isRequired
            />
          </div>
          <div className="column">
            <TextInput
              name="documentNumber"
              label={t('idNumber')/*client ? t('RUC') : t('documentNumber')*/}
              value={documentNumber}
              onChange={handleInputChange}
              placeholder={
                (noRetain === 'legal') ? '0000000000' :
                (documentTypeUser === 0) ? 'AA000000' :
                (documentTypeUser === 1) ? '0 0000 0000' : t('idNumber')
              }
              type="text"
              controlClasses="is-expanded"
              inputClasses="has-text-right"
              disabled={noRetain === 'legal'}
              isRequired={true}
              /*pattern={
                (noRetain === 'legal') ? '[0-9]{10}' :
                (documentTypeUser === 1) ? "(?=.*?[0-9])(?=.*?[A-Za-z]).+" :
                null             
              }*/
              title={
                (noRetain === 'legal') ? t('cedulaJuridicaDesc') :
                (documentTypeUser === 1) ? t('cifPattern') :
                ""
              }
              maxlength={13}
            />
          </div>
          <div className="column" style={{ display: pathName ? 'none' : '' }}>
            <TextInput
              name="cif"
              label={t('CIF')}
              value={cif}
              onChange={handleInputChange}
              placeholder="00000000-K"
              type="text"
              controlClasses="is-expanded"
              inputClasses="has-text-right"
              //disabled={edit}
              isRequired={!pathName}
              maxlength={12}
              //pattern="(?=.*?[0-9])(?=.*?[A-Za-z]).+"
              title={t('cifPattern')}
            />
          </div>
          {client &&
          <div className="column">
            <TextInput
              name="phoneNumber"
              label={t('contactPhone')}
              value={phoneNumber}
              onChange={handleInputChange}
              placeholder={t('phone')}
              type="text"
              controlClasses="is-expanded"
              //disabled={edit}
              maxlength={12}
              isRequired={true}
            />
          </div>}
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="street"
              label={t('address')}
              value={street}
              onChange={handleInputChange}
              placeholder={t('address')}
              type="text"
              controlClasses="is-expanded"
              //disabled={edit}
              isRequired={true}
            />
          </div>
          {/*<div className="column">
            <TextInput
              name="number"
              label={t('number')}
              value={number}
              onChange={handleInputChange}
              placeholder={t('number')}
              type="text"
              controlClasses="is-expanded"
              maxlength={12}
              pattern={'[0-9]{1,12}'}
              //title={'(Máximo 12 dígitos)'}
              //disabled={edit}
              //isRequired={true}
            />
          </div>
          <div className="column">
            <TextInput
              name="otherSign"
              label={t('otherSign')}
              value={otherSign}
              onChange={handleInputChange}
              placeholder={t('otherSign')}
              type="text"
              controlClasses="is-expanded"
              //disabled={edit}
              //isRequired={true}
            />
          </div>
          <div className="column">
            <SelectSingle
              name="province"
              label={t('province')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleRegionChange}
              options={[
                ...provinces.map((item, index) => ({
                  label: item.name,
                  value: index,
                })),
              ]}
              value={province}
              isRequired={true}
            />
          </div>*/}
          <div className="column">
            <SelectSingle
              name="canton"
              label={t('department')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleCantonChange}
              options={[
                ...cantons.map((item, index) => ({
                  label: item.name,
                  value: index,
                })),
              ]}
              value={canton}
              isRequired={true}
            />
          </div>
          <div className="column">
            <SelectSingle
              name="district"
              label={t('municipality')}
              selectClasses="is-fullwidth"
              placeholder={t('municipality')}
              onChange={handleSelectChange}
              options={[
                ...districts.map((item, index) => ({
                  label: item.name,
                  value: index,
                })),
              ]}
              value={district}
              isRequired={true}
            />
          </div>
          {/*<div className="column">
            <TextInput
              name="address"
              label={t('address')}
              value={address}
              onChange={handleInputChange}
              placeholder={t('address')}
              type="text"
              controlClasses="is-expanded"
              disabled={edit}
            />
          </div>*/}
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <SelectSingle
              name="category"
              label={t('categories')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={[
                ...categories.map((item, index) => ({
                  label: item.description,
                  value: index,
                })),
              ]}
              value={category}
              isRequired={confirm ? false : true}
              //isDisabled={hidden ? hidden : edit}
            />
          </div>
          { items !== undefined ?
          <div className="column">
            <CreatableMultiSelect
              name="items"
              label={t('items')}
              value={items}
              onChange={handleItemChange}
              placeholder={t('selectOrTypeItems')}
              noOptionsMessage={() => t('noItemsAvailable')}
              formatCreateLabel={inputValue => t('createNewItem', { inputValue })}
              getOptionLabel={option => option.classification === undefined ? '' : option.classification + ' - ' + option.code + ' - ' + option.description}
              getOptionValue={option => option.id}
              /*getNewOptionData={(inputValue, optionLabel) => ({
                id: optionLabel,
                description: optionLabel,
              })
              }*/
              controlClasses="is-expanded"
              options={itemList}//{handleOnInputChange === null || inputValue.length > 1 ? itemList : [{}]}
              onInputChange={handleOnInputChange}
              inputValue={inputValue}
              isRequired={true}
            />
          </div>
          : null }
        </div>
      </div>
      {/* !client
      && (
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column">
              <label htmlFor="send_notification" className="checkbox" style={{ width: '100%' }}/>
              <input
                name="send_notification"
                value={send_notification}
                onChange={handleInputChange}
                type="checkbox"
                checked={send_notification}
              />
              {` ${t('notifyCardPaymentAuthorization')}`}
            </div>
          </div>
        </div>
      )*/}
      {((role === 'acquiring_bank') || (role === 'seller')) &&
      <div style={{ display: pathName ? 'none' : '' }}>
        <div className="field is-horizontal subtitle is-5 text-black">
          {t('tradeInformation')}
        </div>
        {/* <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column">
                <TextInput
                  name="deferralDays"
                  label={t('deferralDays')}
                  value={deferralDays}
                  onChange={handleInputChange}
                  placeholder="0"
                  type="number"
                  controlClasses="is-expanded has-icons-right"
                  inputClasses="has-text-right"
                  min="0"
                  step="1"
                  />
            </div>
          </div>
        </div> */}
        {/*<div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column">
              <TextInput
                name="username_csp"
                label={t('user')}
                value={username_csp}
                onChange={handleInputChange}
                placeholder={t('user')}
                type="text"
                controlClasses="is-expanded"
                isRequired={(!client && !pathName)}
              />
            </div>
            <div className="column">
              <TextInput
                name="password_csp"
                label={t('password')}
                value={password_csp}
                onChange={handleInputChange}
                placeholder={t('password')}
                type="text"
                controlClasses="is-expanded"
                isRequired={(!client && !pathName)}
              />
            </div>
          </div>
        </div>
        */
        }
        <ul>
          {tradeInformation.map((val, index) => (
            <li>
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column">
                    <TextInput
                      id={index}
                      name={'commerce_code-' + index}
                      label={t('affiliateNumber')}
                      value={val.commerce_code}
                      onChange={handleInputChangeTradeInformation}
                      placeholder={t('affiliateNumber')}
                      //type={(role === 'acquiring_bank') ? "number" : "text"}
                      type="text"
                      controlClasses="is-expanded"
                      //maxlength={15}
                      minlength={8}
                      title={t('minimumCharacter')}
                      isRequired={(!client && !pathName)}
                    />
                  </div>
                  <div className="column">
                    <TextInput
                      id={index}
                      name={'terminal-' + index}
                      label={t('eCommerceTerminal')}
                      value={val.terminal}
                      onChange={handleInputChangeTradeInformation}
                      placeholder={t('eCommerceTerminal')}
                      type="text"
                      controlClasses="is-expanded"
                      maxlength={8}
                      //pattern="^[a-zA-Z]{4}[0-9]{4}"
                      title={t('eCommerceTerminal')}
                      // title="AAAA0000"
                      isRequired={(!client && !pathName)}
                    />
                  </div>
                  <div className="column">
                    <SelectSingle
                      id={index}
                      name={'currency-' + index}
                      label={t('paymentCurrency')}
                      selectClasses="is-fullwidth"
                      placeholder={t('Select')}
                      onChange={handleSelectCurrency}
                      options={[
                        ...paymentCurrency.map((item, index) => ({
                          label: item.description,
                          value: index,
                        })),
                      ]}
                      value={val.currency}
                      isRequired={(!client && !pathName)}
                    />
                  </div>
                  <div className="column">
                    <TextInput
                      id={index}
                      name={'description-' + index}
                      label={t('description')}
                      value={val.description}
                      onChange={handleInputChangeTradeInformation}
                      placeholder={t('description')}
                      type="text"
                      controlClasses="is-expanded"
                      //maxlength={15}
                      title={t('commerceCodeDesc')}
                      isRequired={(!client && !pathName)}
                    />
                  </div>
                  <div style={{ marginTop: '36px', }}>
                    <button
                      className="button is-info is-outlined is-pulled-right"
                      type="button"
                      onClick={addTradeInformation}
                      disabled={index === tradeInformation.length - 1 ? false : true}//{index === index - 1 ? true : false}

                    >
                      <i className="fas fa-plus-circle"/>
                    </button>
                  </div>
                  <div style={{
                    marginTop: '36px',
                    marginLeft: '.5em'
                  }}>
                    <button
                      className="button is-danger is-outlined"
                      type="button"
                      onClick={deleteTradeInformation.bind(this, index)}
                      disabled={index === 0 ? true : false}
                    >
                      <i className="fas fa-minus-circle mr-10px"/>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      }
      <div style={{ display: pathName ? 'none' : '' }}>
        <div className="field is-horizontal subtitle is-5 text-black">
          {t('businessExecutive')}
        </div>
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column">
              <CreatableMultiSelect
                name="executives"
                label={t('businessExecutive')}
                value={executives}
                onChange={handleExecutivesChange}
                placeholder={t('Select')}
                noOptionsMessage={() => t('noExecutivesAvailable')}
                formatCreateLabel={inputValueExecutive => t('createNewExecutive', { inputValueExecutive })}
                getOptionLabel={option => option.name === undefined ? '' : option.name + ' ' + option.lastname + ' - ' + option.email}
                //getOptionLabel={option => option.id === undefined ? '' : option.name + ' ' + option.lastname + ' - ' + option.email}
                getOptionValue={option => option.id}
                /*getNewOptionData={(inputValueExecutive, optionLabel) => ({
                  id: optionLabel,
                  description: optionLabel,
                })
                }*/
                controlClasses="is-expanded"
                options={executivesList}
                //options={handleOnInputChangeExecutive === null || inputValueExecutive.length > 1 ? executivesList : [{}]}
                onInputChange={handleOnInputChangeExecutive}
                inputValue={inputValueExecutive}
                //isRequired={true}
              />
            </div>
          </div>
        </div>
        {newBankingExec.length > 0 && (
          <div>
            <div className="field is-horizontal subtitle is-4 text-black">
              {t('addNew') + ' ' + t('businessExecutive')}
            </div>
            <ul>
              {newBankingExec.map((val, index) => (
                <li>
                  <div className="field is-horizontal">
                    <div className="field-body columns">
                      <div className="column">
                        <TextInput
                          id={index}
                          name={'name-' + index}
                          label={t('name')+"(s)"}
                          value={val.name}
                          onChange={handleInputChangeNewExc}
                          placeholder={t('name')}
                          inputClasses="has-text-left"
                          type="text"
                          controlClasses="is-expanded"
                          //isRequired={true}
                        />
                      </div>
                      <div className="column">
                        <TextInput
                          id={index}
                          name={'lastname-' + index}
                          label={t('adminLastname')+"(s)"}
                          value={val.lastname}
                          onChange={handleInputChangeNewExc}
                          placeholder={t('adminLastname')}
                          inputClasses="has-text-left"
                          type="text"
                          controlClasses="is-expanded"
                          //isRequired={true}
                        />
                      </div>
                      <div className="column">
                        <TextInput
                          id={index}
                          name={'email-' + index}
                          label={t('email')}
                          value={val.email}
                          onChange={handleInputChangeNewExc}
                          placeholder={t('email')}
                          inputClasses="has-text-left"
                          type="text"
                          controlClasses="is-expanded"
                          isRequired={true}
                        />
                      </div>
                      <div style={{ marginTop: '36px', }}>
                        {index === newBankingExec.length - 1 ?
                          <button
                            className="button is-info is-outlined is-pulled-right"
                            type="button"
                            onClick={addBankingExec}
                            //disabled={index === newBankingExec.length - 1 ? false : true}
                          >
                            <i className="fas fa-plus-circle"/>
                          </button>
                          :
                          <button
                            className="button is-danger is-inverted"
                            type="button"
                            onClick={deleteBankingExec.bind(this, index)}
                            //disabled={route}
                          >
                            <i className="fas fa-minus-circle mr-10px"/>
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )
        }
      </div>
      {/*client && (
        <div>
          <div className="field is-horizontal subtitle is-5 text-black">
            {t('cuttingCycle')}
          </div>
          <ul>
            {cycles.map((val, index) => (
              <li>
                <div className="field is-horizontal">
                  <div className="field-body columns">
                    <div className="column">
                      <TextInput
                        id={index}
                        name={'last4Digits-' + index}
                        label={t('last4Digits')}
                        value={val.last_four}
                        onChange={handleInputChange}
                        placeholder={t('last4Digits')}
                        inputClasses="has-text-right"
                        maxlength={4}
                        type="text"
                        controlClasses="is-expanded"
                        isRequired={true}
                      />
                    </div>
                    <div className="column">
                      <SelectSingle
                        id={index}
                        name={'cuttingCycleCard-' + index}
                        label={t('cuttingCycle')}
                        selectClasses="is-fullwidth"
                        placeholder={t('Select')}
                        onChange={handleSelectChange}
                        options={[
                          ...cuttingCycle.map((item, index) => ({
                            label: item.cycle,
                            value: index,
                          })),
                        ]}
                        value={val.billing_cycle_index}
                        //isDisabled={hidden ? hidden : edit}
                        isRequired={true}
                      />
                    </div>
                    <div style={{ marginTop: '36px', }}>               
                      <button
                        className="button is-info is-outlined is-pulled-right"
                        type="button"
                        onClick={addCuttingCycle}
                  
                      >
                        <i className="fas fa-plus-circle"/>
                      </button>
                    </div>
                    <div style={{ marginTop: '36px', marginLeft: '.5em' }}>
                      <button
                        className="button is-danger is-outlined"
                        type="button"
                        onClick={deleteCuttingCycle.bind(this, index)}
                        disabled={index === 0 ? true : false}
                      >
                        <i className="fas fa-minus-circle mr-10px"/>
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )*/}
      {/*(!client && !exist) && (
        <div>
          <div className="field is-horizontal subtitle is-5 text-black">
            {t('assistCard')}
          </div>
          <div className="field is-horizontal">
            <div className="field-body columns">
              <div className="column">
                <label htmlFor="assistCard" className="checkbox" style={{ width: '100%' }}/>
                <input
                  name="assistCard"
                  value={assistCard}
                  onChange={handleInputChange}
                  type="checkbox"
                />
                {` ${t('assistCard')}`}
              </div>
            </div>
          </div>
        </div>
      )
      */}
      <div className="field is-horizontal subtitle is-5 text-black">
        {t('masterUser')}
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="adminName"
              label={t('adminName')+"(s)"}
              value={adminName}
              onChange={handleInputChange}
              placeholder={t('adminName')}
              type="text"
              controlClasses="is-expanded"
              maxlength={250}
              isRequired={true}
            />
          </div>
          <div className="column">
            <TextInput
              name="adminLastname"
              label={t('adminLastname')+"(s)"}
              value={adminLastname}
              onChange={handleInputChange}
              placeholder={t('adminLastname')}
              type="text"
              controlClasses="is-expanded"
              isRequired={true}
            />
          </div>
          {/*<div className="column">
            <SelectSingle
              name="adminDocumentType"
              label={t('documentType')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={
                [{
                  label: 'CI',
                  value: 0,
                }, {
                  label: 'Pasaporte',
                  value: 2,
                },]}
              value={adminDocumentType}
              //isDisabled={edit}
            />
          </div>*/}
          <div className="column">
            <SelectSingle
              name="documentType"
              label={t('documentType')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={[
                ...adminDocumentType.map((item, index) => ({
                  label: item.document_type,
                  value: index,
                })),
              ]}
              value={documentType}
              isRequired={true}
              //isDisabled={hidden ? hidden : edit}
            />
          </div>
          <div className="column">
            <TextInput
              name="adminDocumentNumber"
              label={t('idNumber')}
              value={adminDocumentNumber}
              onChange={handleInputChange}
              placeholder={
                (documentType === 0) ? 'AA000000' :
                (documentType === 1) ? '0 0000 0000' : t('idNumber')
              }
              type="text"
              controlClasses="is-expanded"
              inputClasses="has-text-right"
              //disabled={edit}
              isRequired={true}
              //pattern={documentType ===1 && "(?=.*?[0-9])(?=.*?[A-Za-z]).+"}
              title={documentType ===1 && t('cifPattern')}
              maxlength={13}
            />
          </div>
          <div className="column" style={{ display: 'none' }}>
            <TextInput disabled={edit}
              name="password"
              label={t('password')}
              value={password}
              onChange={handleInputChange}
              placeholder={t('password')}
              type="password"
              controlClasses="is-expanded"
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="adminPosition"
              label={t('adminPosition')}
              value={adminPosition}
              onChange={handleInputChange}
              placeholder={t('adminPosition')}
              type="text"
              controlClasses="is-expanded"
              maxlength={250}
              isRequired={true}
            />
          </div>
          <div className="column" style={{ display: !client ? 'none' : '' }}>
            <TextInput
              name="phoneNumberAdmin"
              label={t('contactPhone')}
              value={phoneNumberAdmin}
              onChange={handleInputChange}
              placeholder={t('phone')}
              type="text"
              controlClasses="is-expanded"
              inputClasses="has-text-right"
              maxlength={12}
            />
          </div>
          <div className="column">
            <TextInput
              name="email"
              label={t('email')}
              value={email}
              onChange={handleInputChange}
              placeholder={t('email')}
              type="email"
              controlClasses="has-icons-left has-icons-right"
              isRequired={true}
              disabled={pathName}
              isEmailEqual={isEmailEqual}
            />
          </div>
          <div className="column" data-type='info' data-tip={t('emailConfirmationMsg')}>
            <TextInput
              name="emailConfirmation"
              label={t('emailConfirmation')}
              value={emailConfirmation}
              onChange={handleInputChange}
              placeholder={t('emailConfirmation')}
              type="email"
              controlClasses="has-icons-left has-icons-right"
              isRequired={true}
              disabled={pathName}
              isEmailEqual={isEmailEqual}
            />
          </div>
          <ReactTooltip/>
        </div>
      </div>
      {/*<div>
        <div className="field is-horizontal subtitle is-5 text-black">
          {t('configuration')}
        </div>
        <div className="field is-horizontal">
          <div className="field-body columns">
            <div className="column">
              <SelectSingle
                name="typeFlow"
                label={t('typeOfApprovalFlow')}
                selectClasses="is-fullwidth"
                placeholder={t('Select')}
                onChange={handleSelectChange}
                options={[
                  ...typeFlows.map((item, index) => ({
                    label: item.name,
                    value: index,
                  })),
                ]}
                value={typeFlow}
              />
            </div>
            <div className="column">
            </div>
          </div>
        </div>
      </div>
      <br/>
      <div className="field is-horizontal subtitle is-5 text-black"
        style={{ display: 'none' }}>
        {t('commercialConditions')}
      </div>
      <div className="field is-horizontal subtitle is-4 text-black"
        style={{ display: 'none' }}>
        {t('flexibleConditions')}
      </div>
      <div className="field is-horizontal"
        style={{ display: 'none' }}>
        <div className="columns field-body">
          <div className="column">
            <TextInput
              name="percentage"
              label={t('interestRate')}
              value={percentage}
              onChange={handleInputChange}
              placeholder="0"
              type="number"
              controlClasses="is-expanded has-icons-right"
              inputClasses="has-text-right"
              min="0"
              step="0.01"
              iconPosition="is-right"
              icon="fas fa-percent"
            />
          </div>
          <div className="column">
            <SelectSingle
              name="period"
              label={t('period')}
              //placeholder={t('SelectOption')}
              onChange={handleSelectChange}
              options={
                [{
                  label: t('yearly'),
                  value: 0,
                }, {
                  label: t('monthly'),
                  value: 1,
                }, {
                  label: t('daily'),
                  value: 2,
                },]}
              value={period}
              selectClasses="is-fullwidth"
              controlClasses="has-icons-left"
              icon="far fa-user"
              iconPosition="is-left"
              isClearable={false}
            />
          </div>
        </div>
      </div>*/}
      <div className="field is-horizontal">
        <label>{t('requiredFields')}</label>
      </div>
      <div className="field is-grouped is-grouped-right" style={{display: confirm ? 'none' : ''}}>
        <button
          type="button"
          className="button is-primary"
          onClick={onSubmit.bind(this)}
        >
          {((role === 'seller') || (role === 'buyer')) ? t('confirm') : t('save')}
        </button>
        &nbsp;&nbsp;
        <button
          type="button"
          className="button is-primary"
          onClick={cancelSave}
        >
          {t('cancel')}
        </button>
      </div>
    </Form>
  </Panel>
);

PersonForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  documentTypesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  documentNumber: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleItemChange: PropTypes.func.isRequired,
  itemList: PropTypes.arrayOf(PropTypes.object).isRequired,
  email: PropTypes.string.isRequired,
  emailConfirmation: PropTypes.string.isRequired,
  isEmailEqual: PropTypes.bool.isRequired,
  documentType: PropTypes.number.isRequired,
  password: PropTypes.string.isRequired,
  persons: PropTypes.arrayOf(Object).isRequired,
  baseDays: PropTypes.number.isRequired,
  // deferralDays: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  discountRate: PropTypes.number,
  client: PropTypes.bool,
  adminName: PropTypes.string,
  adminLastname: PropTypes.string.isRequired,
  adminDocumentNumber: PropTypes.string.isRequired,
  adminDocumentType: PropTypes.number.isRequired,
  adminPosition: PropTypes.string,
  receiverDiscountShare: PropTypes.number.isRequired,
  handlePercentageChange: PropTypes.func.isRequired,
  clientPayment: PropTypes.number.isRequired,
  typeFlows: PropTypes.arrayOf(PropTypes.object).isRequired,
  typeFlow: PropTypes.number.isRequired,
};

PersonForm.defaultProps = {
  discountRate: 0,
  client: false,
  adminName: '',
  adminLastname: '',
  adminPosition: '',
  pathName: false,
  confirm: false,
};

export default PersonForm;
