const START_FETCH = 'stats/START_FETCH';
const END_FETCH = 'stats/END_FETCH';
const API_CALL = 'stats/API_CALL';
const MESSAGE = 'stats/MESSAGE';
const FETCH_STATS = 'stats/FETCH_STATS';
const UPDATE_STATS = 'stats/UPDATE_STATS';
const FETCH_RELATED_USERS = 'stats/FETCH_RELATED_USERS';
const UPDATE_RELATED_USERS = 'stats/UPDATE_RELATED_USERS';
const GET_RELATED_CLIENT_ASSIST = 'stats/GET_RELATED_CLIENT_ASSIST';
const UPDATE_RELATED_ASSIST = 'stats/UPDATE_RELATED_ASSIST';
const FETCH_STATS_ADMIN = 'stats/FETCH_STATS_ADMIN';

export default {
  START_FETCH,
  END_FETCH,
  API_CALL,
  MESSAGE,
  FETCH_STATS,
  UPDATE_STATS,
  FETCH_RELATED_USERS,
  UPDATE_RELATED_USERS,
  GET_RELATED_CLIENT_ASSIST,
  UPDATE_RELATED_ASSIST,
  FETCH_STATS_ADMIN,
};
