import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Form, Loading, Modal, Panel, Toggle, TableSimple } from "../../components";
import { providersActions } from "../../state/ducks/providers";
import TextInput from "../../components/TextInput";
import { TOAST_CONFIG, FILES } from "../../config/constants";
import './styles.scss';

class GroupProvider extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        file_providers: [],
        fileName: "",
        file: "",
        binaryFile: "",
        is_default: false,
        successUsers: [],
        failedUsers: [],
        array: false,
        cant: false,
        cantError: false,
    });

    componentDidMount = () => {
        const {
            providers: { receiver },
            router: { location: { pathname } },
        } = this.props;
        
        const { array } = this.state;

        if(pathname == '/list-provider'){
        
            let arrayS = Array.isArray(array) ? array : receiver.success_users;
            let arrayF = Array.isArray(array) ? array : receiver.failed_users;
            let errors = 0;
            
            arrayF.forEach(function (detail) {
                if(detail.name !== null){
                    errors++;
                    arrayF = detail;
                }
            });

            let filtered = receiver.failed_users.filter(function(value){
                return value.name !== null;
            });

            this.setState({
                cant: arrayS.length,
                cantError: errors,
                failedUsers: filtered,
            });
        }
    }

    resetState = () => {
        this.setState(this.getInitialState());
    };

    handleFileChange = (event) => {
        const {
            target: { name },
        } = event;
        const { t, showMessage } = this.props;
        const files = Array.from(event.target.files);
        const arrays = files[0].name;
        let ext = arrays.split(".");
        let extLength = ext.length - 1;

        if (name === "file") {
        if (ext[extLength] === "xls" || ext[extLength] === "xlsx") {
            this.setState({
            binaryFile: files[0],
            file: event.target.value,
            });
        } else {
            showMessage({
            message: t("errorLoad", { type: "xlsx" }),
            config: TOAST_CONFIG.WARNING,
            });
        }
        }
    };

    getGridSuccessProviders = () => {

        const { 
            t,
            providers: { receiver },
        } = this.props;
        const { 
            is_default, 
        } = this.state;

        const columnsSuccess = [
            {
                id: 'name',
                Header: t('commerceName'),
                accessor: d => d.name,
            },
            {
                id: 'type_legal_person',
                Header: t('personType'),
                accessor: d => d.b2b.type_legal_person === 'JUR' ? t('legal') : t('physical'),
            },
            {
                id: 'document_type',
                Header: t('documentType'),
                accessor: d => d.document_type,
            },
            {
                id: 'document_number',
                Header: t('documentNumber'),
                accessor: d => d.document_number,
            },
            {
                id: 'code',
                Header: t('mccCode'),
                accessor: d => d.items.length > 0 ? d.items[0].code : '',
            },
            {
                id: 'email',
                Header: t('email'),
                accessor: d => d.email,
            },
            {
                id: 'select', // Required because our accessor is not a string
                Header: t('selected'),
                Cell: cellInfo => (
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <label htmlFor="noRetain" className="checkbox"/>
                                    <input
                                        label={t('flowApprovalCheck')}
                                        name={'is_default-' + cellInfo.original.email}
                                        value={is_default}
                                        onChange={this.handleInputChange}
                                        type="checkbox"
                                        checked={cellInfo.original.check === null ? true : cellInfo.original.check}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )
            },
        ];
        const dataSuccess = receiver.success_users;
        const titleSuccess = 'Title';
        return {
            columnsSuccess,
            dataSuccess,
            titleSuccess,
        }
    };

    renderSuccessRowSubComponent = ({ row }) => {
        const { _original: data } = row;
        const {
            t,
            auth: { user: { b2b: { type_user } } },
        } = this.props;
        var fields = data.b2b_address.direction.split(',');
        return (
            <Fragment>
                <div className="field is-horizontal">
                    <div className="columns row-sub-component" style={{ padding: '1rem', display: 'flex' }}>
                        <div className="column">
                            <h4>
                                {`${t('masterUser')}:`}
                            </h4>
                            <span>{`${data.b2b.admin_name+" "+data.b2b.admin_lastname}`}</span>
                        </div>
                        <div className="column">
                            <h4>
                                {`${t('documentType') +' '+ t('masterUser')}:`}
                            </h4>
                            <span>{`${data.b2b.admin_document_type}`}</span>
                        </div>
                        <div className="column">
                            <h4>
                                {`${t('documentNumber') +' '+ t('masterUser')}:`}
                            </h4>
                            <span>{`${data.b2b.admin_document_number}`}</span>
                        </div>
                        <div className="column">
                            <h4>
                                {`${t('CIF')}:`}
                            </h4>
                            <span>{`${data.b2b.ciff !== null ? data.b2b.cif : '' }`}</span>
                        </div>
                        <div className="column">
                            <h4>
                                {`${t('adminPosition')}:`}
                            </h4>
                            <span>{`${data.b2b.admin_position}`}</span>
                        </div>
                        <div className="column">
                            <h4>
                                {`${t('businessExecutive')}:`}
                            </h4>
                            <span>{`${data.new_banking_executive.length > 0 ? data.new_banking_executive[0].email : '' }`}</span>
                        </div>
                    </div>
                </div>       
                <div className="field is-horizontal">
                    <div className="columns row-sub-component" style={{ padding: '1rem', display: 'flex' }}>
                        <div className="column">
                            <h4>
                                {`${t('address')}:`}
                            </h4>
                            <span>{`${data.b2b_address.direction !== null && typeof(fields[fields.length-3]) !== 'undefined' ? fields[fields.length-3] : ''}`}</span>
                        </div>
                        <div className="column">
                            <h4>
                                {`${t('department')}:`}
                            </h4>
                            <span>{`${data.b2b_address.direction !== null && typeof(fields[fields.length-1]) !== 'undefined' ? fields[fields.length-1] : ''}`}</span>
                        </div>
                        <div className="column">
                            <h4>
                                {`${t('municipality')}:`}
                            </h4>
                            <span>{`${data.b2b_address.direction !== null && typeof(fields[fields.length-2]) !== 'undefined' ? fields[fields.length-2] : ''}`}</span>
                        </div>
                        <div className="column">
                            <h4>
                                {`${t('items')}:`}
                            </h4>
                            <span>{`${data.items[0] !== null ? data.items[0].description : ''}`}</span>
                        </div>
                    </div>
                </div>                 
                <div className="field is-horizontal">
                    <div className="columns row-sub-component" style={{ padding: '1rem', display: 'flex' }}>
                        <div className="column">
                            <h4>
                                {`${t('merchantAffiliateCode')}:`}
                            </h4>
                            <span>{`${data.commerce_code[0].commerce_code}`}</span>
                        </div>
                        <div className="column">
                            <h4>
                                {`${t('eCommerceTerminal')}:`}
                            </h4>
                            <span>{`${data.commerce_code[0].terminal}`}</span>
                        </div>
                        <div className="column">
                            <h4>
                                {`${t('currency')}:`}
                            </h4>
                            <span>{`${data.commerce_code[0].currency_id}`}</span>
                        </div>
                        <div className="column">
                            <h4>
                                {`${t('description')}:`}
                            </h4>
                            <span>{`${data.commerce_code[0].description}`}</span>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    getGridFailedProviders = () => {

        const { 
            t,
            auth,
            providers: { receiver },
        } = this.props;
        const { 
            is_default, 
            failedUsers,
        } = this.state;

        const columnsFail = [
            {
                id: 'name',
                Header: t('commerceName'),
                accessor: d => d.name,
            },
            {
                id: 'type_legal_person',
                Header: t('personType'),
                accessor: d => d.b2b.type_legal_person === 'JUR' ? t('legal') : t('physical'),
            },
            {
                id: 'document_type',
                Header: t('documentType'),
                accessor: d => d.document_type,
            },
            {
                id: 'document_number',
                Header: t('documentNumber'),
                accessor: d => d.document_number,
            },
            {
                id: 'code',
                Header: t('mccCode'),
                accessor: d => d.items.length > 0 ? d.items[0].code : '',
            },
            {
                id: 'email',
                Header: t('email'),
                accessor: d => d.email,
            },
        ];
        const dataFail = failedUsers;
        const titleFail = 'Title';
        return {
            columnsFail,
            dataFail,
            titleFail,
        }
    };

    renderFailRowSubComponent = ({ row }) => {
        const { _original: data } = row;
        const {
            t,
            auth: { user: { b2b: { type_user } } },
        } = this.props;
        let errors = "";
        errors = data.errors.slice(0, data.errors.length-1);

        return (
            <div className="columns row-sub-component" style={{ padding: '1rem', display: 'flex' }}>
                <div className="column">
                    <h4>
                        {`${t('errors')}:`}
                    </h4>
                    <br/>
                    <span>{`${errors.replaceAll(",", ", ")}`}</span>
                </div>
            </div>
        );
    };

    handleInputChange = (event) => {
        const {
            array,
            cant,
        } = this.state;
        const {
            providers: { receiver },
        } = this.props;
        const {
            target: {
                checked, value: targetValue, type, name,
            },
        } = event;
        
        const value = type === 'checkbox' ? checked : targetValue;
    
        const id = name.split('-');
        let cant1 = 0;
        let array1 = Array.isArray(array) ? array : receiver.success_users;

        array1.forEach(function (detail) {
            if (detail.email === id[1]) {
                if (detail.check === null) {
                    detail.check = false;
                    cant1 = cant + 1;
                } else {
                    if (detail.check === true) {
                        detail.check = false;
                        cant1 = cant - 1;
                        return;
                    } else {
                        detail.check = true;
                        cant1 = cant + 1;
                        return;
                    }
                }
            }
        });
        let total = 0;
        if (Array.isArray(array1)) {
        array1.forEach(function (detail) {
            if (detail.check === true) {
            total += (+1);
            }
        });
        }
        this.setState({
            [name]: value,
            array: array1,
            cant: total,
        });
    };

    cancelSave = () => {
        const { history } = this.props;
        history.push("/dashboard");
    };

    backLoad = () => {
        const { history } = this.props;
        history.push("/provider-group-load");
    };

    loadProvidersGroup = () => {
        const {
            t,
            auth: { user: { user_id } },
            history,
            userFileLoad,
            showMessage,
        } = this.props;

        const {
            binaryFile,
        } = this.state;

        const data = {
            bank_id: user_id,
            role: 'seller',
            users_file: binaryFile,
        };

        if( binaryFile !== ''){
            userFileLoad({
                data,
                callback: (response) => {
                    history.push({
                        pathname: '/list-provider',
                        state: {
                            successUsers: response.success_users,
                        }
                    })
                }
            });
        }else{
            showMessage({
                message: t('errorLoad')+" xlsx o xls",
                config: TOAST_CONFIG.WARNING,
            });
        }
    }

    sendProviders = () => {
        const {
            t,
            providers: { receiver },
            loadProvidersGroup,
            history,
            showMessage,
        } = this.props;
        const { array } = this.state;
        let arraySave = Array.isArray(array) ? array : receiver.success_users;

        let arrayProviders = [];

        arraySave.forEach(function (detail){
            if(detail.check === true){
                arrayProviders.push(detail);
            }
        });

        const data = { 
            users: arrayProviders,
        }

        loadProvidersGroup({
            data,
            callback: () => {
                history.push('/provider-group-load');
                showMessage({
                    message: t('providersSaved'),
                    config: TOAST_CONFIG.SUCCESS,
                });
                this.resetState();
            }
        });
    }

    totalProviderSuccess = (band) => {
        const {
            array,
        } = this.state;
        const {
            providers: { receiver },
        } = this.props;

        let total = 0;
        let array1 = Array.isArray(array) ? array : receiver.success_users;

        if (Array.isArray(array1)) {
            array1.forEach(function (detail) {
                if (detail.check === true) {
                    total += (+1);
                }
            });
        }
        if (band) {
            return total;
        }
    }

    closeModal = (onToggle) => {
        onToggle();
    };

    arrayDemographic = () => {
        const {
            t,
        } = this.props;
    
        return  [t('commerceName'), t('personTypeFile'), t('documentNumberFile'), t('documentNumber'), t('NIT'), t('address'), t('departmentFile'), t('municipalityFile'), t('mccCodeFile')];
    };

    arrayMaster = () => {
        const {
            t,
        } = this.props;
    
        return  [t('name'), t('adminLastname'), t('documentType'), t('documentNumber'), t('charge'), t('email')];
    };

    arrayCommerce = () => {
        const {
            t,
        } = this.props;
    
        return  [t('affiliateNumber'), t('eCommerceTerminal'), t('paymentCurrency'), t('description')];
    };

    render() {
        const { 
            t, 
            router: { location: { pathname } },
            clients: { loading: clientLoading },
            providers: { loading: providerLoading },
            history,
        } = this.props;

        const { file, fileName, array, cant, cantError } = this.state;
        const loading = clientLoading || providerLoading;
        return (
            <Toggle>
                {({ toggled, onToggle, toggleProps}) => {
                    const { titleSuccess, columnsSuccess, dataSuccess } = this.getGridSuccessProviders(onToggle);
                    const { titleFail, columnsFail, dataFail } = this.getGridFailedProviders();
                return(
                <div className="dashboard">
                    {loading && <Loading/>}
                    <Fragment>
                        {pathname !== '/list-provider' &&(
                        <Panel headingText={t("loadProvidersGruop")}>
                            <Form
                                submitText={t("loadProviders")}
                                onSubmit={(event) => {
                                event.preventDefault();
                                this.loadProvidersGroup();
                                }}
                                buttonClasses="mr-8px"
                            >
                                <div className="field is-horizontal">
                                    <div className="field-body columns">
                                        <div className="column is-full">
                                        <TextInput
                                            name="file"
                                            label={t("selectFile")}
                                            value={file}
                                            fileName={fileName}
                                            onChange={this.handleFileChange}
                                            // placeholder="Suba un documento"
                                            type="file"
                                            // fieldClasses="file has-name"
                                            //  labelClasses="file-label"
                                            //  controlClasses="is-expanded has-icons-left file-label"
                                            //  inputClasses="has-text-right "
                                            //  iconPosition="is-left"
                                            //  icon="fas fa-upload"
                                        />
                                        <strong>
                                            {" "}
                                            <span className="texto-file">{t("allowedFormatsXLS")}</span>
                                        </strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="field is-grouped is-grouped-right">
                                    <button
                                        type="button"
                                        className="button is-primary cancel-massive"
                                        onClick={this.cancelSave}
                                    >
                                        {t("cancel")}
                                    </button>
                                </div>
                            </Form>
                            <div className="terms-box" /*style={{ display: (amexOwner ? 'none' : '') }}*/>
                                <p style={{ marginBottom: '.5em' }}>{t('uploadProviderGroupfile')}</p>
                                <div className="content">
                                    <ul> 
                                        <li>
                                            <p style={{fontWeight: 500}}>{t('demographicInformation')}</p>
                                            <ul>
                                            {this.arrayDemographic().map((info) =>
                                                <li key={info}>{info}</li>
                                            )}
                                            </ul>
                                        </li>
                                        <br/>
                                        <li>
                                            <p style={{fontWeight: 500}}>{t('optionalBackOffice')}</p>
                                            <ul>
                                                <li>{t('email')}</li>
                                            </ul>
                                        </li>
                                        <br/>
                                        <li>
                                            <p style={{fontWeight: 500}}>{t('masterUser')}</p>
                                            <ul>
                                            {this.arrayMaster().map((info) =>
                                                <li key={info}>{info}</li>
                                            )}
                                            </ul>
                                        </li>
                                        <br/>
                                        <li>
                                            <p style={{fontWeight: 500}}>{t('tradeInformation')}</p>
                                            <ul>
                                            {this.arrayCommerce().map((info) =>
                                                <li key={info}>{info}</li>
                                            )}
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <a 
                                    href={FILES.groupUserFile}
                                    className="button is-primary"
                                >
                                    {t('downloadFile')}
                                </a>
                                {/*<p>{t('anExample')}</p>
                                    {t('example')}
                                <p>{t('note')}</p>*/}
                            </div>
                        </Panel>
                        )}
                        {dataSuccess && pathname === '/list-provider' && (
                            <div className="column is-full">
                                <Panel headingText={t("loadProvidersGruop")}>
                                <TableSimple data={dataSuccess} columns={columnsSuccess}  subComponent={this.renderSuccessRowSubComponent}/>
                                <div className="field is-horizontal" style={{marginTop: '1.5em'}}>
                                    <div className="field-body columns">
                                        <div className="column">
                                            <div className="is-fullwidth has-text-right">
                                                <button
                                                    className="button is-primary"
                                                    onClick={() => {this.backLoad()}}
                                                >
                                                    {t('goBack')}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="column">
                                            <div className="is-fullwidth has-text-left">
                                                <button
                                                    className="button is-primary"
                                                    disabled={this.totalProviderSuccess(true) > 0 ? false : true}
                                                    onClick={() => {
                                                        this.setState(
                                                        (this.totalProviderSuccess(false)), () => {
                                                            onToggle({});
                                                        });
                                                    }}
                                                >
                                                    {t('loadProviders')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </Panel>
                            </div>
                        )}
                        {dataFail && pathname === '/list-provider' && (
                            <div className="column is-full">
                                <Panel headingText={t("errorProviders")}>
                                    <TableSimple data={dataFail} columns={columnsFail} subComponent={this.renderFailRowSubComponent}/>
                                </Panel>
                            </div>
                        )}
                        { toggled && (
                            <Modal onToggle={onToggle}> {loading && <Loading/>}
                                <Panel>
                                    <div style={{textAlign: 'center'}}>
                                        <p
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '1.25em',
                                                marginTop: '1em',
                                            }}    
                                        >
                                            {cant+" "+t('cantProviders')}
                                        </p>
                                        <p style={{ marginTop: '1em' }}>
                                            {cantError+" "+t('failedProviders')}
                                        </p>
                                        <p
                                            style={{
                                                color: 'red',
                                                marginTop: '1em',
                                            }} 
                                        >
                                            {t('failedProvidersAlert')}
                                        </p>
                                        <p 
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '1.25em',
                                                marginTop: '1em',
                                                marginBottom: '1em',
                                            }}
                                        >
                                            {t('continueLoadingProviders')}
                                        </p>
                                    </div>
                                    <div className="is-fullwidth has-text-centered">
                                        <button
                                            type="button"
                                            className="button is-primary"
                                            style={{width: '3em'}}
                                            onClick={() => this.sendProviders()}
                                        >
                                            {t('yes')}
                                        </button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <button
                                            type="button"
                                            className="button is-primary"
                                            style={{width: '3em'}}
                                            onClick={() => this.closeModal(onToggle)}
                                        >
                                            {t('no')}
                                        </button>
                                    </div>
                                </Panel>
                            </Modal>
                        )}
                    </Fragment>
                </div>
                )}}
            </Toggle>
        );
    }
}

GroupProvider.propTypes = {
    history: PropTypes.shape(Object).isRequired,
    showMessage: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    loadProvidersGroup: PropTypes.func.isRequired,
    userFileLoad: PropTypes.func.isRequired,
};

const mapStateToProps = ({ providers, clients, auth, router }) => ({
    auth,
    providers,
    clients,
    router,
});

export default compose(
    connect(mapStateToProps, {
        ...providersActions,
    }),
    withNamespaces()
)(GroupProvider);
