import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Loading, PaymentConditionForm } from '../../components';
import { cardActions } from '../../state/ducks/card';
import 'moment/locale/es';
import { TOAST_CONFIG } from '../../config/constants';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { providersActions } from '../../state/ducks/providers';
import { clientsActions } from '../../state/ducks/clients';

class EditPaymentCondition extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    cycle_date: new Date(),
    id: '',
    baseDays: 0,
    percentage: 0,
    discountRate: 0,
    receiverDiscountShare: 0,
    client: null,
    provider: null,
    clientPayment: 0,
    days: 365,
    editable: false,
    codes: [],
    codeDescription: '',
    commerceCode: '',
    hidden: false,
    idCondition: '',
  });

  resetState = () => {
    this.setState(this.getInitialState());
  };

  componentDidMount() {
    const {
      auth: { user: { b2b: { type_user } } },
    } = this.props;
    const {
      response,
      id,
      band,
    } = this.props.location.state;

    if (type_user === 'buyer' || type_user === 'seller') {
      this.openModal(response, id, band);
    }
  };

  openModal = (response, id, edit) => {
    const {
      stats: { relatedUsers },
      auth: {
        user: { b2b: { type_user } },
      },
    } = this.props;
    const { codes } = this.state;

    let client = this.getUser(type_user, id);

    if (type_user === 'seller_assist_card') {
      //onToggle();
    } else if (type_user !== 'seller_assist_card') {
      if (response.person_commerce_code != null && codes.length > 0) {
        this.setState({
          commerceCode: codes.findIndex(function (code) {
            return code.id == response.person_commerce_code;
          }),
          codeDescription: codes[codes.findIndex(function (code) {
            return code.id == response.person_commerce_code;
          })].description,
        });
      } else {
        this.setState({
          commerceCode: '',
          codeDescription: '',
        });
      }

      this.setState({
        id: id,
        idCondition: response.id,
        clientEdit: relatedUsers.related_users[client].idPerson.name,
        //clientEdit: relatedUsers[client].user.idPerson.name,
        baseDays: response.days,
        //limitAmount: response.limit_amount !== null ? this.formatNumber(response.limit_amount) : 0,
        persons: relatedUsers.related_users,
        //persons: relatedUsers,
        startCountingFrom: response.due_from_approval,
        editable: true,
        band: edit,
        receiverDiscountShare: (Number(response.receiver_discount_share) * 100).toFixed(2),
        discountRate: Number(response.discount_rate)
          .toFixed(2),
        editPercentage: parseInt(response.percentage),
        clientPayment: 100 - Number(response.receiver_discount_share * 100),
        //clientPayment: 100 - (receiver_discount_share * 100).toFixed(2),
      });
      //onToggle();
    }
  };

  getUser = (type_user, id) => {
    const {
      stats: { relatedUsers },
    } = this.props;

    let client;
    if (type_user === 'seller') {
      // this.setState({
      client = relatedUsers.related_users.map(
        (listClientMap, index) => ({
          ...listClientMap,
          index,
        }),
      )
        .filter(listClientFil => listClientFil.id
          === id)[0].index;
      //clientEdit: ,
      //});
    } else if (type_user === 'buyer') {
      //this.setState({
      client = relatedUsers.related_users.map(
        (listClientMap, index) => ({
          ...listClientMap,
          index,
        }),
      )
        .filter(listClientFil => listClientFil.id
          === id)[0].index;
      //});
    }
    return client;
  };

  handlePercentageChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
      clientPayment: 100 - Number(value),
    });
  };

  handleSelectChange = (newVal, label) => {
    const { providers, providerCommerceCode, role: { list } } = this.props;
    const { codes } = this.state;

    let role_id = '';
    for (let i = 0; i < list.length; i++) {
      if (list[i].name === 'seller') {
        role_id = list[i].id;
      }
    }
    if (label.name === 'provider') {
      providerCommerceCode({
        params: {
          user: providers.list[newVal.value].id,
          role: role_id,
        },
        callback: (response) => {
          this.setState({ codes: response.commerce_codes });
        },
      });
    }
    if (label.name === 'commerceCode') {
      this.setState({
        commerceCode: codes[newVal.value].id,
        codeDescription: codes[newVal.value].description,
      });
    }

    this.setState({
      [label.name]: newVal.value,
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    /*if (name === 'limitAmount') {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    }else*/
    if (name === 'baseDays') {
      let number = event.key;
      if (['.','e',',','-'].includes(number)) {
          event.preventDefault();
        } else {
          this.setState({
         [name]: (value),
        });
      }
    } else if (name === 'receiverDiscountShare') {
      let percentage = 100 - value;
      this.setState({
        [name]: (value),
        clientPayment: percentage,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  onKeyPress = (event) => {
    this.handleInputChange(event);
  }

  handleOnInputChange = (newValue) => {
    // const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue: newValue });
    return newValue;
  };

  formatNumber = (n) => {
    return n.replace(/\D/g, '')
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ',');
  };

  cancelSave = () => {
    const { history } = this.props;
    history.push('/payment-conditions');
  };

  updatePaymentCondition = () => {
    const {
      auth: { user: { profile, b2b: { type_user } } },
      clients: { sender: clientList },
      providers: { receiver: providerList },
      //editPaymentCondition,
      t,
      history,
      showMessage,
      getClientsConditions,
      getProvidersConditions,
      requestPaymentCondition,
    } = this.props;
    const {
      discountRate,
      receiverDiscountShare,
      baseDays,
      startCountingFrom,
      //limitAmount,
      clientEdit,
      clientPayment,
      commerceCode,
      codes,
      sender_id,
      receiver_id,
      idCondition,
    } = this.state;

    let idList;
    if (type_user === 'acquiring_bank') {
      idList = clientList.map(
        (listClientMap, index) => ({
          ...listClientMap,
          index,
        }),
      )
        .filter(listClientFil => listClientFil.user.idPerson.name
          === clientEdit)[0].index;
    } else {
      idList = providerList.map(
        (listClientMap, index) => ({
          ...listClientMap,
          index,
        }),
      )
        .filter(listClientFil => listClientFil.user.idPerson.name
          === clientEdit)[0].index;
    }

    const days = baseDays;
    //const persons = type_user === 'acquiring_bank' ? clientList : providerList;
    //const ids = type_user === 'acquiring_bank' ? clientList[idList].condition.id : providerList[idList].condition.id;
    const update = type_user === 'acquiring_bank' ? clientList[0].condition.receiver_id : providerList[0].condition.sender_id;
    const conditionData = {
      id: idCondition,
      bank_sender_id: true,
      sender_request_id: null,
      sender_id: sender_id,//type_user === 'acquiring_bank' ? persons[idList].user.id : providerList[0].condition.sender_id,
      receiver_id: receiver_id, //type_user === 'acquiring_bank' ? clientList[0].condition.receiver_id : persons[idList].user.id,
      days,
      due_from_approval: startCountingFrom,
      discount_rate: discountRate,
      percentage: clientPayment, //percentage,
      receiver_discount_share: receiverDiscountShare / 100,
      type: 'base',
      //limit_amount: limitAmount.toString().replaceAll(',', ''),
      person_commerce_code: codes[commerceCode].id,
      role_sender: (type_user === 'acquiring_bank' || type_user === 'issuing_bank') ? profile.role_id : null,
    };

    if (days < 0 || days === '') {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('incorrectDays')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
      return;
    }

    /*if (conditionData.limit_amount < 0) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('limitAmountValidate')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {*/
    //editPaymentCondition({
    requestPaymentCondition({
      //id: ids,
      data: conditionData,
      callback: () => {
        if (type_user === 'acquiring_bank') {
          getClientsConditions({
            params: {
              id: update,
            },
          });
        } else {
          getProvidersConditions({
            params: {
              id: update,
            },
          });
        }
        //onToggle();
        showMessage({
          message: t('editionRequestSent'),
          config: TOAST_CONFIG.SUCCESS,
        });
        history.push('/dashboard');
        this.resetState();
      },
    });
    //}
  };

  saveRequest = (onToggle) => {
    const {
      auth: {
        user: {
          user_id, b2b: { type_user },
        },
      },
      t,
      history,
      showMessage,
      requestPaymentCondition,
    } = this.props;

    const {
      id,
      baseDays,
      startCountingFrom,
      //limitAmount,
      discountRate,
      receiverDiscountShare,
      clientPayment,
      codes,
      commerceCode,
      idCondition,
    } = this.state;

    const conditionData = {
      id: idCondition,
      bank_sender_id: null,
      sender_request_id: null,
      sender_id: type_user === 'seller' ? id : user_id,
      receiver_id: type_user === 'seller' ? user_id : id,
      days: baseDays,
      due_from_approval: startCountingFrom,
      //limit_amount: limitAmount.toString().replaceAll(',', ''),
      discount_rate: discountRate,
      percentage: clientPayment, //percentage,
      receiver_discount_share: receiverDiscountShare / 100,
      person_commerce_code: codes[commerceCode].id,
      type: 'base',
    };

    if (baseDays < 0 || baseDays === '') {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('incorrectDays')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
      return;
    }

    /*if (conditionData.limit_amount < 0) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('limitAmountValidate')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {*/
    requestPaymentCondition({
      data: conditionData,
      callback: () => {
        //onToggle();
        showMessage({
          message: t('editionRequestSent'),
          config: TOAST_CONFIG.SUCCESS,
        });
        history.push('/dashboard');
        this.resetState();
      },
    });
    //}
  };

  render() {
    const {
      t,
      auth: { user: { b2b: { type_user } } },
      clients: { loading: clientLoading },
      providers: { loading: providerLoading },
      paymentConditions: { loading: paymentConditionLoading },
    } = this.props;
    const {
      client,
      provider,
      baseDays,
      discountRate,
      receiverDiscountShare,
      clientPayment,
      editable,
      startCountingFrom,
      //limitAmount,
      clientEdit,
      hidden,
      codes,
      commerceCode,
      codeDescription,
    } = this.state;
    /* const {
       clientName
     } = this.props.location.state;*/
    const loading = paymentConditionLoading;
    //const persons = type_user === 'acquiring_bank' ? clientList : providerList;

    return (
      <div>
        {loading && <Loading/>}
        <PaymentConditionForm
          t={t}
          onSubmit={type_user === 'buyer' || type_user === 'seller' ? this.saveRequest : this.updatePaymentCondition}
          handleInputChange={this.handleInputChange}
          handlePercentageChange={this.handlePercentageChange}
          isClient={type_user === 'buyer' || type_user === 'seller' ? type_user !== 'seller' : type_user !== 'acquiring_bank'}
          handleSelectChange={this.handleSelectChange}
          persons={[]}
          client={client}
          provider={provider}
          baseDays={baseDays}
          discountRate={discountRate}
          receiverDiscountShare={receiverDiscountShare}
          clientPayment={clientPayment}
          startCountingFrom={startCountingFrom}
          //limitAmount={limitAmount}
          clientEdit={clientEdit}
          editable={editable}
          hidden={hidden}
          closeModal={this.cancelSave}
          codes={codes}
          commerceCode={commerceCode}
          codeDescription={codeDescription}
          onKeyPress={this.onKeyPress}
        />
        <br/>
      </div>
    );
  }
}

EditPaymentCondition.propTypes = {
  t: PropTypes.func.isRequired,
  getCycleCard: PropTypes.func.isRequired,
};

const mapStateToProps = ({ card, clients, auth, providers, role, paymentConditions, stats, }) => (
  {
    card,
    clients,
    auth,
    providers,
    role,
    paymentConditions,
    stats,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...cardActions,
    ...paymentConditionsActions,
    ...providersActions,
    ...clientsActions,
  }),
  withNamespaces(),
)(EditPaymentCondition);