/**
 * Created by Feedback Software on 24/10/18.
 * @param children contain the body of this table, it's important use th or td inside tr
 * @param totalPages if is pass by params it means that must be added the pagination nav
 * @param title it is used by pass by params to the Panel for the title of this
 * @param head contain the head of the table, it's important pass th inside tr
 * @param currentPage manage de actual page in pagination
 * @param onChangePage manage the change of the page in the pagination, must change currentPage
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Loading, LoadInvoicesForm, Panel, Toggle, } from '../../components';
import { transactionsActions } from '../../state/ducks/transactions';
import { authActions } from '../../state/ducks/auth';
import { TransferDetail } from './components';
import { TOAST_CONFIG } from '../../config/constants';
import { clientsActions } from '../../state/ducks/clients';
import { providersActions } from '../../state/ducks/providers';
import {
  formatCurrency,
  formatCurrencyCl,
} from '../../util';
import './styles.scss';
import { gatewayActions } from '../../state/ducks/gateway';

class Providers extends Component {
  constructor(props) {
    super(props);
    const { t, transactions: { currency } } = props;
    this.state = {
      transaction_type: 'transfer',
      destination_phone_number: '',
      amount: '',
      invoice_date: new Date(),
      invoice_amount: '',
      base_amount: '',
      tax_amount: 0,
      invoice_number: null,
      invoice_description: '',
      client: null,
      product: currency,
      transfer_date: Date.now(),
      detail: '',
      options: [
        {
          text: t('extractAccount'),
          value: 1,
        },
      ],
      currency: '',
      description: '',
      commerceCodes: [],
      descriptionCommerceCode: '',
      commerceCode: -1,
    };
  }

  getInitialState = () => {
    const { t, transactions: { currency } } = this.props;
    this.setState({
      transaction_type: 'transfer',
      destination_phone_number: '',
      amount: '',
      invoice_date: new Date(),
      unformatedAmount: '',
      unformatedTax: '',
      invoice_amount: '',
      base_amount: '',
      tax_amount: 0,
      invoice_number: null,
      invoice_description: '',
      client: null,
      product: currency,
      transfer_date: Date.now(),
      detail: '',
      options: [
        {
          text: t('extractAccount'),
          value: 1,
        },
      ],
      showForm: true,
      uploadQrLoading: false,
      detail_amount: null,
      inputValue: '',
      type: -1,
      gateway: false,
      commerceCodes: [],
      descriptionCommerceCode: '',
      commerceCode: -1,
      currency: '---',
    });
  };

  componentDidMount() {
    const {
      getClients,
      //getTypeDte,
      //getGatewayUser,
      auth: { user: { b2b: { id } } },
    } = this.props;
    this.getInitialState();
    getClients({
      params: {
        id,
        related: true,
      },
    });
    //getTypeDte({});

    /*getGatewayUser({
      callback: (response) => {
        if (response.length > 0) {
          if (response[0].name === 'getnet') {
            this.setState({
              gateway: true,
            });
          }
        }
      }
    });*/

    /* fetchRoles({
      params: {
        owner_id: id,
      },
    }); */
  }

  resetState = () => {
    this.getInitialState();
  };

  isNumber = n => /^-?[\d.,]+(?:e-?\d+)?$/.test(n);

  formatNumber = (n) => {
    return n.replace(/\D/g, '')
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ',');
  };

  handleAmountChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
  
    let num = this.formatNumber(event.target.value);  
    let total = num && this.state.tax_amount ? parseFloat(num.replaceAll('.', '').replaceAll(',', '.')) + parseFloat(this.state.tax_amount.replaceAll('.', '').replaceAll(',', '.')) : '';

    this.setState({
      [name]: (num),
      invoice_amount: formatCurrency(total+"").slice(2),
    });
  }

  handleTaxChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
  
    let num = this.formatNumber(event.target.value);
    let total = num && this.state.base_amount ? parseFloat(num.replaceAll('.', '').replaceAll(',', '.')) + parseFloat(this.state.base_amount.replaceAll('.', '').replaceAll(',', '.')) : '';

    this.setState({
      [name]: num,
      invoice_amount: formatCurrency(total+"").slice(2),
    });
  }

  handleInputChange = (event) => {
    const {
      auth: { user: { currency } },
    } = this.props;
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    if (name === 'invoice_amount') {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });

      /*switch (currency[0].id) {
        case 'CRC':
          this.setState({
            [name]: value,
          });
          break;
        case 'USD':
          let num = this.formatNumber(event.target.value);
          this.setState({
            [name]: (num),
          });
          break;
      }*/

      /* this.setState({
        [name]: value,
      });*/
    } else if (name !== 'invoice_amount') {
      this.setState({
        [name]: value,
      });
    }
  };

  handleFileChange = (event) => {
    const {
      t,
      showMessage,
    } = this.props;
    const files = Array.from(event.target.files);

    if (parseInt(files[0].size / 1024) > 3072) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('fileSizeExceeded')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {
      this.setState({
        binaryFile: files[0],
        file: event.target.value,
      });
    }
  };

  qrUploadInvoiceFile = (event) => {
    const {
      readInvoiceQr,
      clients: { list: clientList },
      auth: {
        user: {
          user_id,
        },
      },
    } = this.props;
    const files = Array.from(event.target.files);
    this.setState({
      uploadQrLoading: true,
      binaryFile: files[0],
      file: null,
    }, () => readInvoiceQr({
      params: {
        provider_id: user_id,
      },
      callback: ({
                   invoice_date,
                   invoice_number,
                   invoice_amount,
                   invoice_description,
                   client,
                   detail_amount,
                 }) => setTimeout(
        () => this.setState({
          uploadQrLoading: false,
          showForm: true,
          invoice_date: new Date(invoice_date),
          invoice_number,
          invoice_amount: String(invoice_amount),
          invoice_description,
          client: client === '' ? null : clientList.map((mapClient, index) => ({ index, ...mapClient }))
            // eslint-disable-next-line eqeqeq
            .filter(rowClient => rowClient.id_person == client)[0].index,
          detail_amount,
        }),
        3000,
      ),
    }));
  };

  formatDate = (date) => {
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    return day + '/' + month + '/' + date.getFullYear();
  };

  handleDateChange = (newDate) => {
    const {
      t,
      showMessage,
    } = this.props;
    const f = new Date();
    const dateNow = this.formatDate(f);

    /*if (newDate.setHours(0, 0, 0, 0) < f.setHours(0, 0, 0, 0)) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('incorrectDate', { dateNow })}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {*/
    this.setState({
      invoice_date: newDate,
    });
    //}
  };

  handleClientChange = (newDate) => {
    const {
      auth: { user: { b2b: { id } } },
      getPaymentCommerceCode,
      clients: { list },
    } = this.props;

    getPaymentCommerceCode({
      params: {
        sender: list[newDate.value].id,
        receiver: id,
      },
      callback: (response) => {
        this.setState({
          commerceCodes: response,
          commerceCode: -1,
          descriptionCommerceCode: '',
        });
      }
    });

    this.setState({
      client: newDate.value,
    });
  };

  handleCommerceChange = (newVal, label) => {
    const {
      providers: { list },
    } = this.props;

    this.setState({
      [label.name]: newVal.value,
      descriptionCommerceCode: list[newVal.value].description,
      currency: list[newVal.value].currency_id !== null ? list[newVal.value].currency_id : '---',
    });
  };

  onSubmit = () => {
    const {
      client,
      invoice_amount,
      invoice_date,
      invoice_number,
      description,
      binaryFile,
      detail_amount,
      commerceCode,
      //base_amount,
      //tax_amount,
    } = this.state;
    const {
      t,
      auth: {
        user: {
          currency,
          b2b: {
            id,
          },
        },
      },
      clients,
      loadInvoice,
      history,
      showMessage,
      providers: { list },
      //loadSiiInvoice,
    } = this.props;

    /*const year = invoice_date.getFullYear();
    const month = ("0" + (invoice_date.getMonth() + 1)).slice(-2);
    const sii_date = year+month;*/

    const data = {
      invoice_number,
      buyer_id: clients.list[client].id,
      //date_sii: sii_date,
      buyer: clients.list[client].id,
      seller: id,
      amount: invoice_amount.replaceAll(',', '').replaceAll('.', '.'),
      //base_amount: base_amount.replaceAll('.', '').replaceAll(',', '.'),
      //tax_amount: tax_amount.replaceAll('.', '').replaceAll(',', '.'),
      detail_amount,
      date: invoice_date,
      description: description,
      file: binaryFile,
      commerce_code: list[commerceCode].id,
    };

    //if(this.validateTaxAmount() === true){
      loadInvoice({
        data,
        callback: () => {
          history.push('/review-invoices');
          showMessage({
            message: t('createInvoiceSuccess', { invoice_number }),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
        },
        t,
      });
    /*}else{
      showMessage({
        message: "Monto impositivo no debe superar el 15% del monto total",
        config: TOAST_CONFIG.ERROR,
      });
    }*/

    /*loadInvoice({
      data,
      callback: () => {
        history.push('/review-invoices');
        showMessage({
          message: t('createInvoiceSuccess', { invoice_number }),
          config: TOAST_CONFIG.SUCCESS,
        });
        this.resetState();
      },
      t,
    });*/

    /*loadSiiInvoice({
      data,
      callback: () => {
        history.push('/pending-invoices');
        showMessage({
          message: t('createInvoiceSuccess', { invoice_number }),
          config: TOAST_CONFIG.SUCCESS,
        });
        this.resetState();
      },
    });*/

    /*if (data.amount < 0.1 || (invoice_amount.indexOf('.') >= 0 && currency[0].id === 'CLP')) {
      showMessage({
        message: <strong
          style={{ color: '#ffffff' }}>{data.amount < 0.1 ? t('amountValidate') : t('amountInt')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {
      loadInvoice({
        data,
        callback: () => {
          history.push('/review-invoices');
          showMessage({
            message: t('createInvoiceSuccess', { invoice_number }),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
        },
        t,
      });
    }*/

    /*loadInvoice({
      data,
      callback: () => {
        history.push('/invoices');
        showMessage({
          message: t('createInvoiceSuccess', { invoice_number }),
          config: TOAST_CONFIG.SUCCESS,
        });
        this.resetState();
      },
      t,
    });*/
  };

  handleSubmit = (event, total) => {
    event.preventDefault();
    const { ...data } = this.state;
    const { transfer, transactions: { balance } } = this.props;

    if (parseFloat(total) <= parseFloat(balance)) {
      transfer({
        data,
        callback: (detailTransfer) => {
          this.setState(() => ({
            detailTransfer,
          }));
        },
      });
    } else {
      const { showMessage, t } = this.props;
      showMessage({
        message: t('invalidAmount'),
        config: TOAST_CONFIG.INFO,
      });
    }
  };

  handleOnInputChange = (newValue/*, actionMeta*/) => {
    // const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue: newValue });
    return newValue;
  };

  onFinish = () => {
    const { checkBalance } = this.props;
    this.setState(() => ({
      detailTransfer: null,
      amount: '',
      destination_phone_number: '',
    }));
    checkBalance();
  };

  validateEmptyFields = () => {
    const { client, invoice_number, description, commerceCode, } = this.state;
    return (description === '' || invoice_number === null || client === null || commerceCode === -1);
  };

  validateTaxAmount = () => {
    const { tax_amount, invoice_amount } = this.state;
    if(parseFloat(tax_amount.replaceAll('.', '').replaceAll(',', '.')) <= parseFloat(invoice_amount.replaceAll('.', '').replaceAll(',', '.'))*0.15){
      return true;
    }else{
      return false;
    }
  }

  infoSii = (onToggle) => {
    const {
      invoice_number,
      invoice_date,
      client,
      type,
    } = this.state;
    const {
      infoSiiInvoice,
      clients,
    } = this.props;

    infoSiiInvoice({
      params: {
        invoice_number,
        date_sii: new Date(invoice_date).toISOString(),
        buyer_id: clients.list[client].id,
        type_dte: type,
      },
      callback: (response) => {
        if (response.hasOwnProperty('base_amount')) {
          onToggle({
            isEdit: false,
            isDetail: true,
            invoiceNumber: response.invoice_number,
            date_sii: response.date_sii,
            buyer: clients.list[client].idPerson.name,
            date_sii_doc: response.date_sii_doc,
            base_amount: response.base_amount,
            adjusted_amount: response.adjusted_amount,
            status_sii: response.status_sii,
            is_available: response.is_available,
          });
        } else {
          onToggle({
            isEdit: true,
            isDetail: false,
            invoiceNumber: response.invoice_number,
          });
        }
      }
    });
  };

  acceptTerm = (onToggle) => {
    const {
      loadSiiInvoice,
      clients,
      t,
      history,
      showMessage,
    } = this.props;
    const {
      invoice_number,
      invoice_date,
      client,
      type,
    } = this.state;

    const data = {
      invoice_number,
      buyer_id: clients.list[client].id,
      date_sii: invoice_date,
      type_dte: type,
    };

    loadSiiInvoice({
      data,
      callback: () => {
        history.push('/dashboard');
        showMessage({
          message: t('invoiceUploaded', { invoiceNumber: invoice_number }),
          config: TOAST_CONFIG.SUCCESS,
        });
        onToggle();
        this.resetState();
      },
    });
  };

  closeModal = () => {
    const {
      history,
    } = this.props;

    this.getInitialState();
    history.push('/dashboard');
  };

  loadInvoice = (onToggle, status) => {
    const {
      auth: {
        user: {
          b2b: {
            id,
          },
        },
      },
      t,
      showMessage,
      history,
      loadInvoice,
      providers: { list },
      clients,
    } = this.props;
    const {
      client,
      invoice_number,
      invoice_date,
      description,
      type,
    } = this.state;

    const data = {
      invoice_number,
      buyer_id: clients.list[client].id,
      date: invoice_date,
      date_sii: list.date_sii,
      date_sii_doc: list.date_sii_doc,
      buyer: clients.list[client].id,
      seller: id,
      amount: list.adjusted_amount,
      detail_amount: list.adjusted_amount,
      description: description,
      status_sii: status,
      type_dte: type,
      //file: binaryFile,
    };

    loadInvoice({
      data,
      callback: () => {
        onToggle();
        history.push('/dashboard');
        showMessage({
          message: t('createInvoiceSuccess', { invoice_number }),
          config: TOAST_CONFIG.SUCCESS,
        });
        this.resetState();
      },
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  render() {
    const {
      t,
      transactions: { balance, charge, loading: transactionLoading },
      auth: { contacts, },
      clients,
      providers: { loading: providerLoading, type },
    } = this.props;
    const {
      amount,
      detailTransfer,
      client,
      invoice_amount,
      invoice_date,
      //invoice_description,
      invoice_number,
      file,
      binaryFile,
      showForm,
      uploadQrLoading,
      detail_amount,
      inputValue,
      description,
      gateway,
      commerceCode,
      commerceCodes,
      descriptionCommerceCode,
      currency,
      tax_amount,
      base_amount,
    } = this.state;
    const loading = transactionLoading || providerLoading || uploadQrLoading;
    const charge_amount = parseFloat(amount) * (charge / 100);
    const total = parseFloat(amount) + charge_amount;
    const disabledForm = this.validateEmptyFields();

    if (detailTransfer) {
      return (
        <TransferDetail
          detailTransfer={detailTransfer}
          onFinish={this.onFinish}
        />
      );
    }

    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => (
          <Fragment>
            {loading && <Loading/>}
            {/*
              toggled && toggleProps.isEdit === false && toggleProps.isDetail === false
              && (
                <Modal onToggle={onToggle}>
                  <TableList
                    title={t('confirmTransfer')}
                    head={(
                      <tr>
                        <th>{t('transactionType')}</th>
                        <th>{t('recipient')}</th>
                        <th>{t('amount')}</th>
                        <th>{t('totalAmount')}</th>
                        <th>{t('concept')}</th>
                      </tr>
                    )}
                  >
                    <tr key={toggleProps.text}>
                      <td>{t(`${toggleProps.transaction_type}`)}</td>
                      <td>{toggleProps.destination_phone_number}</td>
                      <td>{`${toggleProps.product} ${toggleProps.amount}`}</td>
                      <td>{`${toggleProps.product} ${total}`}</td>
                      <td>{toggleProps.detail}</td>
                    </tr>
                    <tr>
                      <th colSpan="5">
                        <div className="field is-grouped is-grouped-right">
                          <p className="control">
                            <button
                              type="button"
                              className="button margin-right-5"
                              onClick={onToggle}
                            >
                              {t('goBack')}
                            </button>
                          </p>
                          <p className="control">
                            <button
                              type="button"
                              className="button is-primary"
                              onClick={(event) => {
                                this.handleSubmit(event, total);
                                onToggle();
                              }}
                            >
                              {t('confirm')}
                            </button>
                          </p>
                        </div>
                      </th>
                    </tr>
                  </TableList>
                </Modal>
              )
            */}
            {/*NO Existe en el SII*/}
            {/*toggled && toggleProps.isEdit &&
            (
              <Modal onToggle={onToggle}> {loading && <Loading/>}
                <Panel headingText={t('invoiceN', { invoiceNumber: toggleProps.invoiceNumber })}>
                  <Form
                    submitText={t('accept')}
                    onSubmit={(event) => {
                      event.preventDefault();
                      this.acceptTerm(onToggle);
                    }}
                    buttonClasses={`mr-8px ${'is-hidden'}`}
                  >
                    <div className="field is-horizontal">
                      <div className="field-body columns">
                        <div className="column is-full" style={{ textAlign: 'center' }}>

                          <label>{t('notYetFound')}</label>
                        </div>
                      </div>
                    </div>

                    <div className="field is-grouped is-grouped-right">
                      <button
                        type="button"
                        className="button is-primary is-danger"
                        onClick={this.closeModal.bind(this)}
                      >
                        {t('cancel')}
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="button"
                        className="button is-primary"
                        onClick={this.acceptTerm.bind(onToggle)}
                      >
                        {t('save')}
                      </button>
                    </div>
                  </Form>
                </Panel>
              </Modal>
            )
            */}
            {/*Existe en el SII*/}
            {/*toggled && toggleProps.isDetail &&
            (
              <Modal onToggle={onToggle}> {loading && <Loading/>}
                <Panel
                  headingText={toggleProps.is_available === 0 ? t('invoiceN', { invoiceNumber: toggleProps.invoiceNumber }) : t('invoiceDetail')}>
                  <Form
                    submitText={t('send')}
                    onSubmit={(event) => {
                      event.preventDefault();
                      this.loadInvoice(onToggle, toggleProps.status_sii);
                    }}
                    buttonClasses={`mr-8px ${toggleProps.is_available === 0 ? 'is-hidden' : ''}`}
                  >
                    {toggleProps.is_available === 0 &&
                    (
                      <div>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column is-full" style={{ textAlign: 'center' }}>
                              <label>{t('statusOfInvoice', {
                                invoiceNumber: toggleProps.invoiceNumber,
                                status: toggleProps.status_sii
                              })}</label>
                              <br/>
                              <label>{t('cannotBeUploaded')}</label>
                            </div>
                          </div>
                        </div>
                        <div className="field is-grouped is-grouped-right">
                          <button
                            type="button"
                            className="button is-primary is-danger"
                            onClick={this.closeModal.bind(this)}
                          >
                            {t('cancel')}
                          </button>
                        </div>
                      </div>
                    )}
                    {toggleProps.is_available === 1 &&
                    (
                      <div>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column is-one-third select-lead-require">
                              <div className="width-select-lead-require">
                                <TextInput
                                  name="invoice_date"
                                  label={t('dateOfEntry')}
                                  value={formatFriendlyDate(invoice_date)}
                                  onChange={this.handleInputChange}
                                  type="text"
                                  fieldClasses="is-expanded"
                                  controlClasses="is-expanded"
                                  icon=""
                                  iconPosition=""
                                  disabled={true}
                                />
                              </div>
                            </div>
                            <div className="column is-two-third">
                              <TextInput
                                name="invoice_number"
                                type="text"
                                label={t('invoicesNumber')}
                                value={toggleProps.invoiceNumber}
                                onChange={this.handleInputChange}
                                inputClasses="has-text-right"
                                iconPosition=""
                                icon=""
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column is-one-third select-lead-require">
                              <div className="width-select-lead-require">
                                <TextInput
                                  name="invoice_date"
                                  label={t('documentDate')}
                                  value={toggleProps.date_sii_doc}
                                  onChange={this.handleInputChange}
                                  type="text"
                                  fieldClasses="is-expanded"
                                  controlClasses="is-expanded"
                                  icon=""
                                  iconPosition=""
                                  disabled={true}
                                />
                              </div>
                            </div>
                            <div className="column is-two-third">
                              <TextInput
                                name="client"
                                label={t('customer')}
                                value={toggleProps.buyer}
                                onChange={this.handleInputChange}
                                type="text"
                                inputClasses="has-text-left"
                                iconPosition=""
                                icon=""
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column is-two-third">
                              <TextInput
                                name="description"
                                type="text"
                                label={t('description')}
                                value={description}
                                onChange={this.handleInputChange}
                                inputClasses="has-text-left"
                                iconPosition=""
                                icon=""
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column is-two-third">
                              <TextInput
                                name="amount"
                                type="text"
                                label={t('totalAmountToPayOnTheInvoice')}
                                value={toggleProps.base_amount}
                                onChange={this.handleInputChange}
                                inputClasses="has-text-right"
                                iconPosition=""
                                icon=""
                                disabled={true}
                              />
                            </div>
                            {/*<div className="column is-two-third">
                              <TextInput
                                name="status_sii"
                                type="text"
                                label={t('invoiceStatusInTheSii')}
                                value={toggleProps.status_sii}
                                onChange={this.handleInputChange}
                                inputClasses="has-text-left"
                                iconPosition=""
                                icon=""
                                disabled={true}
                              />
                            </div>
                            <div className="column is-two-third">
                            <TextInput
                              name="amount"
                              type="text"
                              label={t('amount')}
                              value={toggleProps.adjusted_amount}
                              onChange={this.handleInputChange}
                              inputClasses="has-text-right"
                              iconPosition=""
                              icon=""
                              //disabled={isEdit}
                            />
                          </div>*/}
            {/*</div>
                        </div>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column is-two-third">
                              <TextInput
                                name="status_sii"
                                type="text"
                                label={t('invoiceStatusInTheSii')}
                                value={toggleProps.status_sii}
                                onChange={this.handleInputChange}
                                inputClasses="has-text-left"
                                iconPosition=""
                                icon=""
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column is-full" style={{ textAlign: 'center' }}>
                              <label>{t('theInvoiceWillBeCharged',
                                {
                                  status: toggleProps.status_sii === 'PENDIENTE'
                                    ? t('toBeApproved') :
                                    toggleProps.status_sii === 'RECIBIDO' || toggleProps.status_sii === 'REGISTRO' || toggleProps.status_sii === 'ACEPTADO' ?
                                      t('approvedStatus') : ''
                                })}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Form>
                </Panel>
              </Modal>
            )
            */}
            {showForm && (
              <LoadInvoicesForm
                formData={{ ...this.state }}
                onToggle={onToggle}
                toggle={toggled}
                toggleProps={toggleProps}
                disabled={disabledForm}
                balance={balance}
                handleInputChange={this.handleInputChange}
                handleFileChange={this.handleFileChange}
                handleDateChange={this.handleDateChange}
                handleClientChange={this.handleClientChange}
                contacts={contacts}
                chargeAmount={charge_amount}
                invoice_amount={invoice_amount}
                invoiceDate={invoice_date}
                invoiceNumber={invoice_number}
                //description={invoice_description}
                description={description}
                clients={clients.list}
                selectedClient={client}
                onSubmit={this.onSubmit}
                file={file}
                fileName={binaryFile && binaryFile.name}
                detailAmount={detail_amount}
                handleOnInputChange={this.handleOnInputChange}
                inputValue={inputValue}
                currency={currency}
                infoSii={this.infoSii}
                typeList={type}
                handleSelectChange={this.handleSelectChange}
                gateway={gateway}
                handleSelectCommerce={this.handleCommerceChange}
                commerceCode={commerceCode}
                commerceCodes={commerceCodes}
                descriptionCommerceCode={descriptionCommerceCode}
                tax_amount={tax_amount}
                base_amount={base_amount}
                handleAmountChange={this.handleAmountChange}
                handleTaxChange={this.handleTaxChange}
              />
            )}
            {!showForm && (
              <Panel headingText={t('loadInvoice')}>
                <div className="columns">
                  <div className="column">
                    <div className="file has-name">
                      <label htmlFor="resume" className="file-label">
                        <input className="file-input" type="file" name="resume"
                              onChange={this.qrUploadInvoiceFile}/>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="button is-outlined is-info is-large is-fullwidth">
                          <span className="">{t('uploadInvoice')}</span>
                          <span className="icon is-small">
                            <i className="fas fa-upload"/>
                          </span>
                        </a>
                      </label>
                    </div>
                  </div>
                  <div className="column">
                    <button className="button is-outlined is-success is-large is-fullwidth"
                            onClick={() => this.setState({ showForm: true })}>
                      <span>{t('loadInvoiceData')}</span>
                      <span className="icon is-small">
                        <i className="fas fa-edit"/>
                      </span>
                    </button>
                  </div>
                </div>
              </Panel>
            )}
          </Fragment>
        )}
      </Toggle>
    );
  }
}

Providers.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  providers: PropTypes.shape(Object).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  transfer: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  readInvoiceQr: PropTypes.func.isRequired,
  clients: PropTypes.shape(Object).isRequired,
  loadInvoice: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
  showMessage: PropTypes.func.isRequired,
  checkBalance: PropTypes.func.isRequired,
};

const mapStateToProps = ({
                           transactions,
                           clients,
                           auth,
                           providers,
                           gatewayActions,
                         }) => (
  {
    transactions,
    clients,
    auth,
    providers,
    gatewayActions,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
    ...authActions,
    ...clientsActions,
    ...providersActions,
    ...gatewayActions,
  }),
  withNamespaces(),
)(Providers);
