const es = {
  translations: {
    accept: 'Aceptar',
    acceptTerms: 'Debe aceptar los términos y condiciones',
    acceptTermsAndConditions: 'He leído y acepto los términos y condiciones del Servicio',
    acceptTermsGateway: 'Aceptamos los cargos o tarifas por servicio que en su caso estén indicados en la sección Clientes y aceptamos que éstas sean cargadas por GETNET mediante deducción directa a las liquidaciones mensuales practicadas al ESTABLECIMIENTO, como se indica en el Contrato de Afiliación y en su Anexo que regula el acceso y uso de la plataforma “B2B Enterprise”',
    account: 'Cuenta',
    accountBalance: 'Saldo de la cuenta',
    accountPlural: 'Cuentas',
    accumulatedAmount: 'Monto acumulado diario',
    acquiring_bank: 'Banco Adquiriente',
    acquiringBank: 'Adquiriente',
    action: 'Acción',
    active: 'Activo',
    activeCompanies: 'Empresas activas',
    activate: 'Activar',
    administrators: 'Administradores',
    administratorsList: 'Lista de administradores',
    addComment: 'Agregar comentario ...',
    addFinanceProductSuccess: 'Producto de financiamiento creado de manera exitosa',
    addFinancingProducts: 'Agregar productos de financiamiento',
    additionalInformation: 'Información Adicional',
    addNew: 'Agregar nuevo',
    address: 'Dirección',
    admin: 'Administrar',
    administratorName: 'Nombre del administrador',
    administratorBank: 'Banco Administrador',
    adminRoutes: 'Administrar rutas',
    assignPermissions: 'Asignar permisos',
    affiliateCode: 'Código de afiliación',
    affiliateNumber: 'Número de afiliado',
    affiliateN: 'Nro. de afiliado',
    affiliateClients: 'Clientes afiliados',
    authUser: 'Usuario autorizado',
    advance: 'Anticipar',
    advanceCommercialConditions: 'Condiciones comerciales de anticipo',
    alias: 'Alias',
    allOfThem: 'Todos',
    allowedFormats: 'Formatos permitidos: JPG y PNG. Tamaño máximo 3MB.',
    allowedFormatsPDF: 'Formatos permitidos: PDF o XML. Tamaño máximo 3MB.',
    amexCard: 'Tarjeta Santander Tesorero Amex',
    amount: 'Monto',
    amountInt: 'El monto debe ser un número entero',
    amountInstallments: 'Monto de las cuotas',
    amountMoney: 'Monto de dinero',
    amountOfTransactionsToProcess: 'Monto de transacciones por procesar',
    amountToCollect: 'Total diario por cobrar',
    amountToGet: 'Facturas por cobrar',
    amountToPay: 'Monto por pagar',
    anticipatedAmount: 'Monto anticipado',
    annualQuota: 'Cuota anual',
    applicant: 'Solicitante',
    applicationDate: 'Fecha de solicitud',
    applicantName: 'Nombre del Solicitante',
    applicantBank: 'Banco solicitante (perfil)',
    applicationDeleted: 'Solicitud eliminada',
    applicationOneNotification: 'Tiene {{payment_request_pending}} solicitud',
    applicationNotification: 'Tienes {{payment_request_pending}} solicitudes',
    applicationSuccess: 'Solicitud aprobada exitosamente',
    applicationRejected: 'Solicitud rechazada exitosamente',
    approvalDate: 'Fecha de aprobación',
    approvalDateReject: 'Fecha de aprobación / rechazo',
    approvalStatus: 'Estado de aprobación',
    approve: 'Aprobar',
    approved: 'Aprobadas',
    approvedStatus: 'Aprobado',
    approveInvoice: 'Aprobar factura',
    approvedInvoices: 'Fac. Aprobadas',
    approvedInvoicesTitle: 'Facturas aprobadas',
    arrangedDays: 'Días pactados',
    asterisk: '*** {{number_card}}',
    assistCard: 'Assist card',
    assign: 'Asignar',
    assignRoute: 'Asignar rutas',
    assignedRoutes: 'Rutas asignadas',
    assignedCardNumber: 'N° tarjeta asignada',
    assignRoleRoute: 'El rol y la ruta se asignaron exitosamente',
    assignRouteSuccess: 'La ruta fue asignada exitosamente',
    authorization: 'Autorización',
    available: 'Disponible',
    avance: 'Anticipo',
    balance: 'Saldo',
    bankList: 'Lista de bancos',
    bankLogo: 'Logo del Banco',
    bankName: 'Nombre del banco',
    bankProvider: 'Banco Santander',
    banks: 'Bancos',
    bankType: 'Tipo de banco',
    baseConditions: 'Condiciones Base (Pago de Facturas)',
    benefits: 'Beneficios',
    baseAmount: 'Monto base',
    bills: 'Facturas \nenviadas',
    billPayment: 'Pago de facturas',
    billingVolume: 'Volumen de facturación',
    billingPerMonth: 'Facturación {{month}}',
    bin: 'Bin',
    business: 'Empresas',
    businessName: 'Razón social',
    businessExecutive: 'Ejecutivo de negocio',
    businessExecutives: 'Ejecutivos de negocio',
    cardPaymentDate: 'Fecha de pago de tarjeta de crédito',
    onlyCardPaymentDate: 'Fecha de pago de tarjeta',
    by_amount: 'Por monto',
    by_hierarchy: 'Por jerarquía de puestos',
    cancel: 'Cancelar',
    cancelled: 'Canceladas',
    cancelledInvoices: 'Facturas canceladas',
    cardChargeDate: 'Fecha de Cargo en la Tarjeta',
    cardholderPaymentPercentage: 'Porcentaje de Pago del Tarjetahabiente',
    cardPayment: 'TTA seleccionada para pago',
    cedulaJuridicaDesc: '10 dígitos, sin cero al inicio ni guiones',
    changeOfCommercialConditions: 'Registro de cambio de condiciones comerciales',
    charge: 'Cargo',
    chargeDate: 'Fecha de cargo',
    chargeForService: 'Cargo por servicio',
    cif: 'CIF',
    cifPattern: 'Debe contener letras y números',
    clientName: 'Nombre del cliente',
    clientPaymentCondition: 'Determina las condiciones de pago para este cliente',
    code: 'Código',
    codeArea: 'cód. área + n˚ teléfono',
    collaboratorNumber: 'Número de colaborador',
    collaboratorNumberCharacter: 'Número de colaborador, debe tener 8 caracteres',
    company: 'Compañia',
    commerceCode: 'Nro. de afiliado',
    updateInfo: 'Actualizar información',
    commercialBillingUser: 'Usuario de facturación comercial',
    commerceCodeDesc: 'Ingrese descripción para código de comercio',
    commercialConditions: 'Condiciones comerciales',
    condition: 'Condición',
    concept: 'Concepto',
    configuration: 'Configuración',
    confirm: 'Confirmar',
    confirmPassword: 'Confirmar contraseña',
    confirmUnsubscribe: '¿Está seguro que desea dar de baja la condición comercial con {{client}} y el {{product}} que involucra?',
    confirmUnsubscribeFrom: 'Confirmar baja de: {{product}}',
    confirmTaxCode: 'Confirmar Clave Tributaria',
    confirmTransfer: 'Confirme su transacción',
    continue: 'Continuar',
    consult: 'Consultar',
    productsContracted: 'Productos contratados',
    cardBind: 'Bin de tarjetas',
    cort: 'Corte',
    cortDate: 'Fecha de corte',
    createBank: 'Crear nuevo banco',
    createBankSuccess: 'Banco {{name}} creado de manera exitosa',
    createClientSuccess: 'Cliente {{name}} creado de manera exitosa',
    createCondition: 'Crear condición comercial para: {{name}}',
    createCustomer: 'Crear cliente',
    createdAt: 'Fecha de alta',
    createExecutive: 'Crear ejecutivo de negocio',
    createInvoiceSuccess: 'La factura N° {{invoice_number}} se ha registrado exitosamente',
    createNewItem: 'Crear nueva industria "{{inputValue}}"',
    createNewExecutive: 'Crear nuevo ejecutivo "{{inputValueExecutive}}"',
    createNewManaged: 'Crear nuevo administrador',
    createProvider: 'Crear proveedor',
    createProviderSuccess: 'Proveedor {{name}} creado de manera exitosa',
    createProfile: 'Crear perfil',
    createProfileSuccess: 'Perfil {{name}} creado de manera exitosa',
    createRol: 'Crear rol',
    createRolSuccess: 'Rol {{rolName}} creado de manera exitosa',
    createUser: 'Crear usuario',
    createUserSuccess: 'Usuario {{name}} creado de manera exitosa',
    createFlowApproval: 'Crear flujo de aprobación de factura',
    createFlowApprovalSuccess: 'Flujo de Aprobación de factura, creado de manera exitosa',
    creditCardSubstitution: 'Sustitución de tarjeta de crédito',
    creditPeriod: 'Período del crédito',
    cuttingCycle: 'Fecha de Corte/Pago',
    cuttingCycleAmex: 'Ciclo de corte tarjeta tesorero AMEX',
    cuttingCycleCard: 'Ciclo de corte de la tarjeta ingresada es: {{message}}',
    customer: 'Cliente',
    customerInformation: 'Información del cliente seleccionado',
    customerName: 'Nombre del cliente',
    customerPayment: 'Pago del cliente',
    customerPaymentPercentage: '% Pago cliente',
    customers: 'Clientes',
    collectionDate: 'Fecha cobro',
    changeCode: 'Debe modificar el código de comercio que corresponda al nuevo tipo de moneda seleccionada',
    changeLog: 'Registro de cambio',
    changePassword: 'Cambiar contraseña',
    changePasswordSuccess: 'Contraseña cambiada de manera exitosa',
    city: 'Ciudad',
    cycleGroup: 'Grupo de ciclo',
    cyclesList: 'Lista de Ciclos de Facturación',
    ccChargeDate: 'CC charge date',
    daily: 'Diario',
    dashboard: 'Resumen',
    dataSavedSuccessfully: 'Datos guardados con exito',
    date: 'Fecha',
    dateOfAdmission: 'Fecha de ingreso en SII',
    dateOfEntry: 'Fecha de ingreso a B2B',
    dateInvoice: 'Fecha factura',
    dateFf: 'Fecha FF',
    FF: 'FF',
    FV: 'FV',
    dateV: 'Fecha de Vencimiento',
    dataConfirmedSuccessfully: 'Datos confirmados exitosamente',
    dateNotAvailable: 'Fecha no disponible',
    dateRange: 'Rango de fechas',
    day: 'Día',
    daysNumber: 'Número de días',
    daysSince: ' días desde la ',
    defer: 'Diferido',
    deferralDays: 'Días de diferimiento',
    deferCommercialConditions: 'Condiciones comerciales de diferimiento',
    deferredAmount: 'Monto Diferido',
    delete: 'Eliminar',
    deleteInvoice: 'La factura fue eliminada',
    deletePaymentCondition: 'La condición de pago fue eliminada',
    deleteTta: 'Tarjeta AMEX eliminada con éxito',
    demographicInformation: 'Datos de identificación',
    deposit: 'Depositar',
    description: 'Descripción',
    destinationAccount: 'Cuenta destino',
    deactivate: 'Desactivar',
    detail: 'Detalle',
    digitalCertificate: 'Certificado digital',
    differ: 'Diferir',
    discountRate: 'Tasa de descuento',
    discountRateMDR: 'Tasa dcto. (MDR)',
    discountRateCharges: 'Cargos por tasa de descuento',
    discountRateInvoiceSavedSuccessfully: 'Factura por tasa de descuento guardada con éxito',
    discountRateOutOfLimits: 'Tasa de descuento fuera de los limites',
    doNotIncludeTheUsername: '* No incluir como parte de la contraseña el nombre del usuario.',
    documentNumber: 'Nro. de identificación',//'Nro. de documento',
    documentType: 'Tipo de documento',
    document: 'Documento',
    documentDate: 'Fecha del documento',
    dontPose: 'No Posee',
    eCommerceTerminal: 'Terminal E-commerce',
    edit: 'Editar',
    editBank: 'Editar manual: {{name}}',
    editCycle: 'Editar ciclo',
    editionRequestSent: 'Solicitud de edicion enviada',
    email: 'Correo electrónico',
    emailConfirmation: 'Confirmar correo electrónico',
    emailConfirmationMsg: 'Ambos correos electrónicos deben ser iguales',
    incorrectEmail: '{{email}} no parece un correo válido. Revisa que tenga el formato correcto: ejemplo@mail.com',
    emptyFields: 'Debes completar todos los campos.',
    emptyAll: 'Asegúrate de llenar toda la información',
    emptyAllRequest: 'Asegúrate de llenar toda la información, de los Campos obligatorios',
    enter: 'Ingresar',
    enterCuttingCycle: 'Ingresar Fecha de Corte/Pago',
    enterDataInternalTaxService: 'Conecte su empresa al SII - Servicio de Impuestos Internos',
    enterCodeSms: 'Verifica e ingresa el código\n que te enviamos vía SMS',
    enterTaxCode: 'Ingresar Clave Tributaria',
    errorDate: 'La fecha \'Desde\' tiene que ser menor',
    errorDateCycles: 'La fecha FF tiene que ser menor',
    errorData: 'Error al cargar los datos.',
    errorLoad: 'Debe cargar un archivo de extensión {{type}}',
    executiveData: 'Datos del ejecutivo de negocio',
    expirationDate: 'Fecha de expiración',
    expiredInvoices: 'Aprobadas / Pagadas',
    extract: 'Extraer',
    extractAccount: 'Extracción en red B2B',
    extractDetail1: 'Codigo de aprobación generado exitosamente',
    extractDetail2: 'Monto a retirar',
    extractDetail3: 'En',
    extractDetail4: 'Completa la extracción en un punto de nuestra Red',
    extractDetail5: 'Con tu número de telefono',
    extractDetail6: 'Proporciona tu numero de telefono al operador del punto para completar la extraccion iniciada',
    extractDetail7: 'Codigo QR',
    extractDetail8: 'Preséntate con el siguiente codigo en el punto de la red',
    financingProducts: 'Productos de \nfinanciamiento',
    finish: 'Finalizar',
    fiscaldocument: 'Documento fiscal',
    flexibleConditions: 'Condiciones Flexibles (Financiamiento)',
    flowApproval: 'Flujo de aprobación',
    flowsApproval: 'Flujos de aprobación',
    flowApprovalCheck: 'Flujo de aprobación por defecto',
    forgotPassword: '¿Olvidó su contraseña?',
    friday: 'Viernes',
    fullName: 'Nombre y apellido',
    goBack: 'Regresar',
    greaterThan: 'Mayor',
    lessThan: 'Menor',
    lessThanOrEqualTo: 'Menor Igual',
    general_admin: 'Usuario administrador general del banco',
    general_admin_issuing: 'Usuario administrador general del banco Emisor',
    general_admin_issuing_amex: 'Usuario administrador general del banco Emisor AMEX',
    group: 'Grupo ',
    greaterThanOrEqualTo: 'Mayor Igual',
    fileSize: 'El tamaño máximo permitido, es 3MB',
    fileSizeExceeded: 'Tu archivo supera el peso máximo de 3MB',
    handbook: 'Manual',
    identityNo: 'Nro. de identidad',
    inCharge: 'Responsable',
    import: 'Importe',
    inactive: 'Inactivo',
    inactiveBusinesses: 'Comercios inactivos',
    inactiveClients: 'Clientes inactivos',
    informationOfTheSelectedBank: 'Información del banco seleccionado',
    installmentNumber: 'Número de cuotas',
    installments: 'N° de cuotas',
    intNumber: 'Debe ser un número entero',
    interestRate: 'Tasa de interés',
    internalRevenueService: 'Servicio de Impuestos Internos',
    invalidAmount: 'Monto inválido',
    invalidRoleOrRoute: 'El rol y la ruta no pueden estar vacios',
    invoiceCount: 'Número de Facturas',
    invoiceDate: 'Fecha de factura',
    invoiceDetail: 'Detalle de Factura',
    invoiceIssueDate: 'Fecha de emisión factura',
    invoiceN: 'Factura N° {{invoiceNumber}}',
    invoiceManualResolution: 'Facturas no pagadas',
    invoiceMarkPaid: 'Factura N° {{invoiceNumber}} fue marcada como pagada',
    incorrectDate: 'No puedes ingresar una fecha anterior a, {{dateNow}}',
    incorrectDays: 'En días pactados se debe ingresar un valor mayor o igual a 0',
    invoice: 'Factura',
    invoices: 'Facturas',
    invoicesNumber: 'Número de factura',
    invoiceStatus: 'Estado de la factura en el SII es: {{status}}',
    invoiceNumber: 'N° factura',
    invoicePending: 'Por emitir',
    invoiceReject: 'Rechazar factura',
    invoiceTotalAmount: 'Monto en Facturas',
    invoiceUploaded: 'Factura N° {{invoiceNumber}} cargada en B2B Enterprise exitosamente',
    issuedInvoices: 'Facturas emitidas',
    issuing_bank: 'Banco Emisor',
    items: 'Industrias',
    kindOfProduct: 'Tipo de producto',
    legal: 'Jurídico',
    listCuttingCycle: 'Lista de ciclos de corte',
    listManuals: 'Listar Manuales',
    lastDays: 'Últimos días',
    last4Digits: 'Últimos 4 dígitos N° Tarjeta',
    lastMovements: 'Últimos Movimientos',
    lengthToCharacters: '* Longitud 12 a 64 caracteres que contengan al menos tres de las siguientes características: letras mayúsculas (A-Z), letras minúsculas (a-z), números (0-9) o caracteres especiales.',
    listInvoices: 'En revisión',
    listPayments: 'Listado de pagos',
    load: 'Cargar',
    loadManuals: 'Cargar Manuales',
    loadBillingCycle: 'Cargar Ciclos de Facturación',
    loading: 'Cargando...',
    loadingContent: 'Cargando contenido ...',
    loadInvoice: 'Cargar factura',
    loanPeriod: 'Período del préstamo',
    logs: 'Logs',
    logout: 'Salir',
    logView: 'Vista log',
    logTransaction: 'Log transacciones',
    operationLog: 'Log de Eventos',
    mandatoryFieldsRequired: '(*) Debe rellenar todos los campos marcados con un asterisco',
    mainUser: 'Usuario Principal',
    mainInvoiceNumber: 'Número de factura principal',
    markAsPaid: 'Marcar como pagado',
    manualsAndFaq: 'Manuales y FAQ\'s',
    manualLoaded: 'Manual cargado exitosamente',
    manualResolution: 'No pagado',
    mdr: 'MDR',
    monday: 'Lunes',
    minimumCharacter: 'Código de Afilación de Comercio, debe tener entre 8 y 15 caracteres',
    message: 'Mensaje',
    messageUserProcess: 'En este momento no posee un tipo de flujo de aprobación configurado. Por favor comuníquese con su representante para activar esta funcionalidad.',
    merchantAffiliateCode: 'Número de afiliado',
    month: 'Mes',
    monthly: 'Mensual',
    name: 'Nombre',
    newBank: 'Nuevo banco',
    nameFantasy: 'Nombre fantasía',
    newPassword: 'Nueva contraseña',
    newPaymentCondition: 'Nuevas Condiciones de Pago',
    newRecharge: 'Nueva Recarga',
    newRole: 'Nuevo Rol',
    newProfile: 'Nuevo usuario',
    newTransfer: 'Nueva Transferencia',
    next: 'Siguiente',
    nextCollection: 'Próximo cobro',
    nextDays: 'Próximos días',
    nextPage: 'Siguiente',
    nextPayment: 'Próximo pago',
    no: 'No',
    noCycles: 'No hay ciclos de corte cargados para: {{month}}',
    notVerified: 'No verificada',
    noCard: 'No posee tarjeta prepagada',
    noData: 'Sin información',
    noInformation: 'Sin Información',
    noItemsAvailable: 'No hay industrias disponibles',
    noExecutivesAvailable: 'No hay ejecutivo de negocio',
    nonMatchingPasswords: 'Las contraseñas ingresadas no coinciden',
    noRowsFound: 'Sin información',
    notifyCardPaymentAuthorization: 'Notificar autorización de pago con tarjeta',
    noTransactions: 'Esta tarjeta no posee movimientos',
    notYetFound: 'La factura aún no se encuentra disponible en el SII, si lo desea puede cargarla y B2B Enterprise se encargará de reintentar la búsqueda de la misma y cargarla en nuestra plataforma, de lo contrario cancele la operación e intente cargarla nuevamente más tarde',
    numberInstallments: 'Número de cuotas',
    numberTta: 'Número de TTA',
    of: 'de',
    onHold: 'En espera',
    operationVolume: 'Vol. operaciones',
    operator: 'Condición',
    originAccount: 'Cuenta origen',
    params: 'Parametros',
    page: 'Páginas',
    paid: 'Pagadas',
    paidAmount: 'Monto pagado',
    paidInvoices: 'Facturas pagadas',
    paidOut: 'Pagado',
    password: 'Contraseña',
    passReqTooltip: 'Asegurese de cumplir con las características indicadas abajo',
    confirmPassTooltip: 'Debe ser idéntica a la contraseña ingresada arriba',
    // passGral: 'Debe tener una longitud de 8 a 12 caracteres y cumplir con al menos 3 de las siguientes características:',
    passGral: 'Debe cumplir las siguientes características:',
    passSpecs1: 'Longitud 12 a 64 caracteres',
    passSpecs2: 'Letras mayúsculas (A-Z)',
    passSpecs3: 'Letras minúsculas (a-z)',
    passSpecs4: 'Números (0-9)',
    passSpecs5: 'Caracteres especiales',
    passSpecs6: 'No incluir como parte de la contraseña el nombre del usuario',
    passSpecs7: 'No deberán tener caracteres idénticos consecutivos',
    requiredConfirm: 'La confirmación de contraseña es obligatoria.',
    differentConfirmPass: 'La contraseña y la confirmación son diferentes',
    showPass: 'Mostrar contraseña',
    hidePass: 'Ocultar contraseña',
    passwordDigitalCertificate: 'Contraseña del certificado digital',
    passwordHasExpired: 'Su contraseña ha expirado, por favor ingrese su nueva contraseña',
    payedAmounts: 'Total diario facturado',
    payment: 'Pago',
    paymentCurrency: 'Moneda de pago',
    paymentDate: 'Fecha de cobro',
    paymentDateInvoice: 'Fecha de cobro de facturas:',
    datePayment: 'Fecha de pago',
    paymentDeadline: 'Plazo de pago',
    paymentOrders: 'Ordenes de Pago',
    paymentsTransfers: 'Pagos y Transferencias',
    platformAdministrator: 'Administrador plataforma',
    platformManuals: 'Manuales de la plataforma',
    person: 'Persona',
    pendingInvoices: 'Fac. Pendientes',
    pending: 'Pendiente',
    pendingToApprove: 'Pendiente por aprobar',
    period: 'Periodo',
    physical: 'Individual',
    prepaidCard: 'Tarjeta Prepagada',
    present: 'Actual',
    previous: 'Anterior',
    previous_page: 'Anterior',
    printQR: 'Imprimir QR',
    product: 'Producto',
    productUnsubscribedSuccessfully: 'Producto dado de baja exitosamente',
    productContracted: 'Solicitud de contrato del producto: {{name}}, enviada exitosamente',
    productName: 'Nombre del Producto',
    provider: 'Comercio',
    profile: 'Perfil',
    profileName: 'Nombre del perfil',
    profileList: 'Lista de usuarios',
    providerName: 'Nombre del proveedor',
    providers: 'Comercios',
    providerPaymentCondition: 'Determina las condiciones de pago para este comercio',
    qualify: 'Habilitar',
    QuoteAndRequest: 'Cotizar y Solicitar',
    rate: 'Porcentaje',
    receivedInvoices: 'Facturas \nRecibidas',
    receiverBank: 'Banco receptor',
    receptionDate: 'Fecha de emisión de factura',
    fromReceptionDate: 'Fecha de recepción',
    recharge: 'Recargar',
    recipient: 'Destinatario',
    recoverPassword: 'Recuperar contraseña',
    rejectReason: 'Motivo de Rechazo',
    rejectionDate: 'Fecha de rechazo',
    replaceTheSelectedCard: 'Está seguro de sustituir la tarjeta seleccionada.',
    requestUnsubscribe: 'Solicitar baja',
    requestSentProduct: 'Solicitud enviada',
    requiredFields: '(*) Campos obligatorios',
    insertYourEmailAddress: 'Introduce tu dirección de correo electrónico.',
    enterYourNewPassword: 'Introduzca su nueva contraseña',
    registrationStatus: 'Estado de registro',
    registerBank: 'Registrar banco',
    registeredCards: 'Tarjetas registradas del cliente: {{name}}',
    registerRole: 'Registrar rol',
    registerRoute: 'Registrar ruta',
    reject: 'Rechazar',
    rejectInvoice: 'Rechazadas',
    rejectInvoiceAll: 'Facturas rechazadas',
    rejectedInvoices: 'Fac. Rechazadas',
    relatedUsers: 'Usuarios Relacionados',
    rememberToAcceptTheTerms: 'Recuerde aceptar los términos y condiciones para contratar el producto',
    removeRouteSuccess: 'La ruta fue removida exitosamente',
    request: 'Solicitudes',
    requestEdition: 'Solicitar edición',
    requestSent: 'Enviadas',
    requestReceived: 'Recibidas',
    requiredInstallments: 'El numero de cuotas es inválido',
    requiredName: 'El nombre es inválido',
    requiredPeriod: 'El periodo es inválido',
    requiredRate: 'La tasa es invalida',
    requiredTypeFinance: 'El tipo de financiamiento es inválido',
    respJson: 'Respuesta JSON',
    respXml: 'Respuesta XML',
    response: 'Respuesta',
    return: 'Volver',
    roleCantBeEmpty: 'El rol no puede estar vacio',
    roleList: 'Lista de roles',
    roles: 'Roles',
    rol: 'Rol',
    roleType: 'Tipo del rol',
    rolName: 'Nombre del rol ',
    rolType: 'Tipo del rol',
    routeList: 'Lista de rutas',
    routes: 'Rutas',
    rows: 'Filas',
    safetyRules: 'Necesitamos conectar su empresa al SII para poder obtener las facturas emitidas por sus proveedores a su rázon social, para ello le agradecemos ingresar los siguientes datos:',
    save: 'Guardar',
    saturday: 'Sábado',
    sunday: 'Domingo',
    seeDetail: 'Ver detalle',
    seeUsers: 'Ver usuarios',
    Select: 'Seleccione ...',
    selected: 'Seleccionar',
    selectANewCommercialExecutive: 'Debe seleccionar un nuevo ejecutivo de negocio',
    selectCard: 'TTA Seleccionada',
    SelectDocumentType: 'Elige el tipo de documento para continuar',
    SelectBank: 'Seleccione un banco',
    selectCycle: 'Debe seleccionar todos los ciclos',
    selectMonth: 'Debe seleccionar un mes',
    SelectOption: 'Seleccione una opción',
    selectOrTypeItems: 'Seleccione o escriba las industrias...',
    selectOrTypeRoutes: 'Seleccione o escriba la ruta',
    selectOrTypePermissions: 'Seleccione o escriba el permiso',
    selectRecipient: 'Seleccionar destinatario',
    selectOrTypeRol: 'Seleccione o escriba los roles',
    send: 'Enviar',
    sendDate: 'Fecha envío',
    sendInvoice: 'Fecha de envío de factura',
    sentInvoices: 'Facturas \nenviadas',
    shouldNotSelect: 'No debe seleccionar el mismo Ejecutivo Comercial, que quiere eliminar',
    requestsSent: 'Solicitudes enviadas',
    requestsReceived: 'Solicitudes recibidas',
    settings: 'Ajustes',
    seeHow: 'Ver como',
    shippingDate: 'Fecha de envío',
    showQR: 'Mostrar código QR',
    since: 'Desde',
    startDate: 'Fecha de inicio',
    endingDate: 'Fecha de fin',
    startCountingFrom: 'Días pactados desde',
    summary: 'Resumen',
    successExecutive: 'Ejecutivo de negocio creado exitosamente',
    successCard: 'Su tarjeta ya fue registrada.',
    successCycles: 'Ciclos de facturación creado exitosamente.',
    successCycle: 'Fecha de Corte/Pago confirmado exitosamente.',
    successEmail: 'Se ha enviado un correo a tu dirección de correo electrónico.',
    successfullyUpdatedManual: 'Manual actualizado con exito',
    transactionsToProcess: 'Transacciones por procesar',
    targetNumber: 'Numero de tarjeta',
    TtaValidation: 'Validación de TTA',
    termsAndConditions: 'Términos y condiciones',
    toDisable: 'Deshabilitar',
    total: 'Total',
    totalAmount: 'Importe total',
    totalAmountToPayOnTheInvoice: 'Monto total a pagar en la factura',
    toBeApproved: 'Por Aprobar',
    totalBuyers: 'Total Empresas Pagadoras',
    totalBuyersDeferred: 'Empresas con Facturas Diferidas',
    totalBuyersInvoiced: 'Empresas con Facturas Aprobadas',
    totalCharged: 'Total cobrado {{month}}',
    totalDebtAmount: 'Monto total a pagar',
    totalOwedAmount: 'Monto total a cobrar',
    totalPay: 'Total a pagar',
    totalToPayCardholder: 'Total a Pagar Tarjetahabiente Cargo por Servicio',
    totalToPayInvoice: 'Total a Pagar Factura',
    totalVolumePendingPayment: 'Total de volumen pendiente por pagar',
    totalSellers: 'Comercios Afiliados',
    totalSellersCharged: 'Proveedores con Facturación Aprobada',
    totalSellersInvoiced: 'Proveedores con Facturación Pendiente',
    totalToApprove: 'Total por aprobar',
    totalToReceiver: 'Total por cobrar',
    transactionType: 'Tipo de transacción',
    transfer: 'Transferir',
    transferCharge: 'Cargo de la operación',
    transferDetail2: 'Comprobante',
    transferDetail3: 'Enviado a',
    transferDetail4: 'Monto enviado',
    transferDetail5: 'Monto total descontado',
    transferDetail: 'Transferencia Realizada',
    transferState: 'Estado',
    transferType: 'Tipo de transacción',
    tuesday: 'Martes',
    theCodeExpiresIn: 'El código vence a las: {{time}}',
    theInvoiceWillBeCharged: 'La factura se cargará en B2B Enterprise en estado, {{status}}',
    thursday: 'Jueves',
    theyMustNotHave: '* No deberán tener caracteres idénticos consecutivos, ni totalmente numéricos, ni totalmente alfabéticos.',
    type: 'Tipo',
    typeFinance: 'Tipo de financiamiento',
    typeOfApprovalFlow: 'Tipo de flujo de aprobación',
    typeOfEmitFlow: 'Tipo de flujo de emisión',
    typeAndDocumentNumber: 'Tipo y Nro. de documento',
    supplierTypeAndDocumentNumber: 'Tipo y Nro. de documento del proveedor',
    buyerTypeAndDocumentNumber: 'Tipo y Nro. de documento del cliente',
    underReview: 'En revisión',
    underReviewInvoices: 'Facturas en revisión',
    user: 'Usuario',
    userManual: 'Manual de usuario',
    username: 'Nombre de usuario',
    users: 'Usuarios',
    userQuery: 'Usuario consulta',
    userBillingCycles: 'Usuario ciclos de facturación',
    Unsubscribe: 'Dar de baja',
    until: 'Hasta',
    updatedBillingCycle: 'Ciclo de facturación actualizado',
    updatedBillingCycles: 'Ciclos de facturación actualizado',
    updateInformation: 'Actualizar información',
    virtualAccount: 'Cuenta Virtual',
    yearly: 'Anual',
    resource: 'Recurso',
    view: 'Ver',
    daysAdvance: 'Dias a adelantar',
    daysDefer: 'Dias a diferir',
    anualRate: 'Tasa anual',
    percentage: 'Porcentaje de interes anual',
    interest_rate: 'Monto de interés',
    adjusted_amount: 'Monto ajustado',
    cantDeleteRol: 'El rol no se puede eliminar porque tiene asignado una o mas rutas',
    deleteRol: 'El rol fue elminado',
    deleteRoute: 'La ruta fue eliminada',
    deleteExecutive: 'Ejecutivo de negocio eliminado',
    cantDeleteRoute: 'La ruta no se puede eliminar porque esta asignada a uno o mas roles',
    createLead: 'Requerir \n alta',
    sellerCreateLead: 'Requerir alta de proveedor',
    buyerCreateLead: 'Requerir alta de cliente',
    requireSuccess: '{{name}} requerido con exito',
    buyerName: 'Nombre del cliente',
    sellerName: 'Nombre del proveedor',
    leadList: 'Listado de \nrequeridos',
    createRoleSuccess: 'Role {{rolName}} creado exitosamente',
    // Despues de enviar a traduccion a portugues
    updateBank: 'Actualizar banco',
    adminName: 'Nombre',
    adminLastname: 'Apellido',
    adminPosition: 'Cargo',
    invoicesApproved: 'Facturas aprobadas',
    invoicesToApproved: 'Facturas por aprobar',
    approvedMount: 'Monto aprobado',
    nInvoices: 'Nro. de Facturas',
    advanceMount: 'Monto adelantado',
    deferMount: 'Monto diferido',
    totalCommissionAmount: 'Comision total',
    discountAmount: 'Monto de descuento',
    reports: 'Reportes',
    createPaymentCondition: 'Crear condición \nde pago',
    addCondition: ' Agregar condición',
    receiverDiscountShare: 'Porcentaje de pago del proveedor',
    senderDiscountShare: 'Porcentaje de pago del cliente',
    paymentClient: 'El pago del cliente es de',
    createPaymentConditionSuccess: 'Condición de pago creada exitosamente',
    paymentCondition: 'Condición',
    paymentConditionProvider: 'Condiciones comerciales para proveedores de',
    paymentConditionClients: 'Condiciones comerciales para clientes de',
    permanentlyRejectCommercialStatus: 'Rechazar condición comercial permanentemente',
    editPaymentConditionSuccess: 'Condición de pago Editada exitosamente.',
    editClientSuccess: 'Cliente {{name}} editado exitosamente',
    editProviderSuccess: 'Proveedor {{name}} editado exitosamente',
    editProfileSuccess: 'Perfil {{name}} editado exitosamente',
    editUserSuccess: 'Usuario {{name}} editado exitosamente',
    client: 'Cliente',
    download: 'Descargar',
    en: 'English',
    es: 'Español',
    pt: 'Português',
    language: 'Lenguaje',
    lead: 'Alta',
    completeComercialConditionOrEmptyAll: 'Complete los datos de las condiciones comerciales o dejelos vacios si no desea agregar condicion comercial con algun proveedor',
    tradeInformation: 'Información de comercio',
    transactions: 'Transacciones',
    totalReceiver: 'Total a cobrar',
    totalInvoices: 'Total facturas',
    quantity: 'Cantidad',
    providersReceiver: 'Factura de proveedores por cobrar',
    clientsPay: 'Clientes por pagar',
    clients: 'Clientes',
    selectedBank: 'Banco',
    uploadInvoice: 'Cargar factura',
    loadInvoiceData: 'Cargar datos de factura',
    naoReter: 'No retener IR (propaganda, publicidad, comisiones y corretaje)',
    disable: 'Deshabilitar',
    status: 'Estado',
    statusOfInvoice: 'El estado de la factura N° {{invoiceNumber}} en el SII es: {{status}}.',
    cannotBeUploaded: 'Por lo tanto la factura no se puede cargar en B2B Enterprise',
    invoiceApprovedSuccess: 'Factura aprobada exitosamente',
    invoiceRejectedSuccess: 'Factura rechazada exitosamente',
    invoiceProvidersReceiver: 'Factura de proveedores por cobrar',
    invoiceStatusInTheSii: 'Estado de la factura en el SII',
    actionNotAllowed: 'Acción no permitida',
    clientPay: 'Pagos de los clientes',
    referrer: 'Referido',
    buyer: 'Cliente',
    seller: 'Proveedor',
    limitAmount: 'Monto límite',
    limitAmountValidate: 'Debes ingresar un valor mayor o igual a 0',
    limit: 'Límite',
    new: 'Nueva',
    paymentMethod: 'Método de pago',
    target: 'Tarjeta',
    line: 'Linea',
    defaultFlow: 'Flujo por defecto',
    condition_amount: 'Monto',
    invoiceNumberValidate: 'El formato válido para el ingreso del número de factura es xxx-xxx-xxxxxxxxx',
    amountValidate: 'El valor debe ser mayor o igual a 0.1',
    cantCreateTransaction: 'Ya existe el número de factura',
    penddingInvoice: 'Tiene {{pending_invoices}} factura pendiente sin aprobar',
    penddingInvoices: 'Tienes {{pending_invoices}} facturas pendientes sin aprobar',
    registerAdministrator: 'Registrar administrador',
    registerCard: 'Registrar Tarjeta',
    registerCardAmex: 'Ingrese Tarjeta American Express',
    listCards: 'Lista de tarjetas',
    cards: 'Tarjetas',
    savedCards: 'Tarjetas de crédito guardadas',
    secureCards: 'La información de tus tarjetas de crédito serán almacenadas bajo estrictas normas de seguridad',
    rejectInvoices: 'Tienes {{reject_invoices}} factura(s) rechazadas',
    rejectedCycle: 'Ciclo de facturación rechazado exitosamente.',
    emitInvoice: 'Tiene {{emit_invoices}} factura sin emitir',
    emitInvoices: 'Tienes {{emit_invoices}} facturas sin emitir',
    search: 'Buscar',
    operationRank: 'Rango de operaciones (desde)',
    maxRank: '(hasta)',
    support: 'Asistencia',
    user_list: 'Lista de Usuarios',
    edit_user: 'Editar Usuario',
    acquiring_query: 'Banco Adquirente de Consulta',
    acquiringQuery: 'Adquirente de Consulta',
    issuing_query: 'Banco Emisor de Consulta',
    enable: 'Habilitar',
    transactionReport: 'Reporte de transacciones',
    paymentDateRange: 'Rango de fecha de cobro de facturas',
    paymentDateRangeClient: 'Rango de fecha de pago de facturas',
    identificationNumber: 'No. de Identificación',
    time: 'Hora',
    event: 'Evento',
    info: 'Info',
    infoLog: 'Información del evento',
    editFlowApprovalSuccess: 'Flujo de aprobación editado con exito',
    emissionFlow: 'Flujo de emisión',
    affiliationCode: 'Código de afiliación de comercio',
    validCard: 'Verificada',
    viewTta: 'Ver TTA',
    waitingForApproval: 'Esperando aprobación de solicitud de baja del servicio',
    invalidCard: 'Por verificar',
    RUT: 'RUT',
    conditions: 'Condiciones',
    phone: 'Teléfono',
    contactPhone: 'Teléfono de contacto',
    yes: 'Sí',
    yourDataWillBeStoredEncrypted: '*Sus datos serán almacenados encriptados bajo estrictas normas de seguridad.',
    validity: 'Vigencia',
    verificationCode: 'Código de verificación',
    verificationCodeSent: 'Ingrese el código  de verificación enviado a su correo',
    waitingBillingCycle: 'Esperando asignación de ciclo de facturación',
    wednesday: 'Miércoles',
    billingCycle: 'Ciclo de facturación',
    billingCycles: 'Ciclos de facturación',
    cardList: 'Lista de tarjetas',
    permissions: 'Permisos',
    region: 'Región',
    commune: 'Comuna',
    grant: 'Conceder',
    deny: 'Denegar',
    webpay: 'Webpay',
    getnet: 'Getnet',
    gateway: 'Gateway',
    main_bank_issuing: 'Usuario Administrador del Banco Emisor',
    currency: 'Moneda',
    bankingExecutives: 'Listado de ejecutivos',
    currentCycle: 'Ciclo Vigente',
    disabled: 'Deshabilitado',
    enabled: 'Habilitado',
    assignedUsers: 'Usuarios Asignados',
    newBankingExecutive: 'Nuevo ejecutivo de negocio',
    rate_operator: 'Operador de tarifas financieras',
    cycle_loader: 'Operador de Ciclos de Facturación',
    requestProducts: 'Solicite su seguro o asistencia',
    contractedProducts: 'Seguros contratados',
    products: 'Seguros / Asistencias',
    cost: 'Costo',
    contract: 'Contratar',
    registerProduct: 'Registrar Producto',
    insurances: 'Seguros',
    contractRequest: 'Solicitud de contrato',
    unsuscribeRequest: 'Solicitud de baja',
    productType: 'Tipo de producto',
    emailLabel: 'nombre@empresa.com',
    fantasyName: 'Nombre de fantasía',
    b2bMDR: 'Tasa B2B Enterprise (MDR)',
    providerB2BRate: 'Tasa B2B que paga el proveedor',
    clientB2BRate: 'Tasa B2B a cargo del cliente es',
    verifyHuman: 'Por favor verifica que eres humano',
    selectInvoice: 'Selecciona la factura',
    paymentAmount: 'Monto a pagar',
    valorNeto: 'Valor neto',
    vendorMerchantAffiliateCodes: 'Número de afiliado del proveedor: {{provider}}',
    volumeProcessed: 'Volumen procesado en los últimos tres meses',
    allowedFormatsXLS: 'Formatos permitidos: XLSX o XLS. Tamaño máximo 3MB.',
    loadFromFile: 'Cargar desde archivo',
    selectFile: 'Seleccionar archivo',
    invoiceTerms: 'Al aprobar facturas dentro de la plataforma B2B Enterprise, acepto el cargo que se realizará en mi Tarjeta Santander Tesorero American Express, por el monto que se individualiza en cada factura, y del monto del cargo por servicio, si correspondiese de acuerdo a las condiciones comerciales pactadas con su proveedor. Instruye irrevocablemente a Banco Santander, para que tales cargos se efectúen en la referida Tarjeta.',
    discountRateAccept: 'Condiciones de cargos por servicio a Banco Santander',
    discountRateTerms: 'Al aceptar el cargo a mi Tarjeta de Crédito Santander Tesorero American Express, el cliente se compromete a pagar la factura más los cargos por Servicio a Banco Santander en todas las facturas en las que el cliente le pague al proveedor indicado.',
    clientPercentageCharge: 'Porcentaje cargo por servicio a cargo del cliente',
    providerPercentageCharge: 'Porcentaje cargo por servicio a cargo del proveedor',
    adminBank: 'Banco Administrador',
    bankInformation: 'Información del Banco seleccionado',
    selectBank: 'Debe seleccionar un Banco',
    saveFaq: 'Manuales y FAQ',
    question: 'Pregunta',
    answer: 'Respuesta',
    supportLink: 'Link de soporte',
    createQuestionSuccess: 'Pregunta creada de manera exitosa',
    saveQuestion: 'Cargar FAQ\'s',
    savePlatformFaq: 'Cargar FAQ\'s de la Plataforma',
    updatePlatformFaq: 'Editar FAQ\'s de la Plataforma',
    listFaqs: 'Listar FAQ\'s',
    platformFaqList: 'Listar FAQ\'s de la Plataforma',
    seeQuestion: 'Ver pregunta',
    b2bInformation: 'Información sobre Business Link',
    monthlyReport: 'Reporte mensual',
    clientRUT: 'RUT Cliente',
    providerRUT: 'RUT Proveedor',
    buyerCode: 'Código del cliente',
    sellerCode: 'Código del proveedor',
    enable2FA: 'Activar verificación en dos pasos',
    disable2FA: 'Desactivar verificación en dos pasos',
    send2FASuccess: 'Código enviado correctamente',
    FMA: 'Verificación en dos pasos',
    email2FA: 'Se ha enviado un código de verificación a su correo electrónico, por favor ingréselo para continuar la operación',
    district: 'Municipio',
    canton: 'Departamento',
    province: 'Región',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    enterTheCardBillingCycle: 'Es necesario que ingrese el ciclo de facturación de la tarjeta para continuar operando con está tarjeta',
    successfullyAssignedCuttingCycle: 'Ciclo de corte asignado exitosamente',
    categories: 'Categorías',
    category: 'Categoría',
    validEmail: 'Correos que pertenecen a @yopmail.com no son permitidos',//'Debe ingresar correos que pertenezcan a @baccredomatic.com, @baccredomatic.gt',
    providerGroup: 'Grupo de proveedores',
    customerGroup: 'Grupo de clientes',
    loadProvidersGruop: 'Cargar grupo de comercios',
    loadProviders: 'Cargar empresas',
    loadCustomersGruop: 'Cargar grupo de clientes',
    loadCustomers: 'Cargar clientes',
    personType: 'Tipo de persona',
    mccCode: 'Código MCC',
    continueLoadingProviders: '¿Continuar con la carga de empresas?',
    continueLoadingCustomers: '¿Continuar con la carga de clientes?',
    cantProviders: 'empresas seleccionadas para cargar',
    cantCustomers: 'clientes seleccionados para cargar',
    failedProviders: 'empresas con errores',
    failedCustomers: 'clientes con errores',
    failedProvidersAlert: '(Empresas con errores no serán tomadas en cuenta para registrar)',
    failedCustomersAlert: '(Clientes con errores no serán tomados en cuenta para registrar)',
    errorProviders: 'Comercios con errores',
    errorCustomers: 'Clientes con errores',
    errors: 'Errores',
    providersSaved: 'Proveedores cargados exitosamente',
    loadFile: 'Cargar archivo',
    customersSaved: 'Clientes cargados exitosamente',
    suggestTradeConditionCorrection: 'Sugerir correción de condición comercial',
    rejectTradeRequest: 'Rechazar solicitud comercial',
    esp: 'ESP',
    por: 'POR',
    eng: 'ENG',
    notifyPaymentAuthorization: 'Notificar autorización de pago',
    cancelInvoiceRequest: 'Solicitar Cancelación de Factura',
    cancelInvoice: 'Cancelar factura',
    suggestInvoiceAdjustment: 'Sugerir ajuste de factura',
    editInvoiceRequest: 'Solicitud de edición de factura',
    restartDays: 'Reiniciar plazo de los días pactados',
    mantainDays: 'Mantener plazo de los días pactados (Continúa con los días pactados transcurridos)',
    update: 'Actualizada',
    issuing: 'Emisor',
    commerce: 'Comercio',
    commerces: 'Comercios',
    commerceName: 'Nombre del comercio',
    commerceTypeAndDocumentNumber: 'Tipo y Nro. de documento del comercio',
    createCommerce: 'Crear comercio',
    CIF: 'NIT',
    merchantName: 'Nombre del comercio',
    nit: 'NIT',
    masterUser: 'Usuario maestro',
    cifProvider: 'CIF Proveedor',
    accumulatedInvoicedValue: 'Valor facturado acumulado',
    typeNumberDocument: 'Tipo y número de documento',
    department: 'Departamento',
    municipality: 'Municipio',
    taxAmount: 'Monto impositivo',
    tax: 'Impuesto',
    totalAmounts: 'Monto total',
    amountTotal: 'Monto total',
    electronicVoucher: 'Voucher electrónico',
    voucherNumber: 'Número de recibo',
    dateAndHour: 'Fecha y hora',
    refNumber: 'Número de Referencia',
    authNumber: 'Número de autorización',
    commerceGroup: 'Grupo de comercios',
    alreadyRegisteredCard: 'El cliente ya tiene una tarjeta registrada',
    cutAndPayDate: 'Fecha de Corte/Pago',
    idNumber: 'Nro. de identificación',
    invalidNIT: 'NIT no tiene un formato válido',
    addAliases: 'Agregar alias',
    aliasAddedSuccessfully: 'Alias agregado exitosamente',
    editAliases: 'Editar alias',
    maximumCharacters: 'Máximo 12 caracteres',
    sameInvoiceDate: 'La fecha de la factura debe ser igual a la fecha actual',
    adminRol: 'Administrador',
    adminUser: 'Administrador',
    general_admin_acquiring: 'Administrador general del banco Adquiriente',
    main_bank: 'Banco principal',
    main_bank_acquiring: 'Administrador del Banco Adquiriente',
    main_bank_issuing_amex: 'Administrador del Banco Emisor AMEX',
    invoiceGroup: 'Grupo de facturas',
    confirmInvoices: 'Confirmar Facturas',
    loadCsv: 'Cargar CSV',
    loadZip: 'Cargar soporte ZIP',
    sendSelectedInvoices: 'Enviar facturas seleccionadas',
    sendInvoices: 'Facturas por enviar: {{ cant }}',
    invoicesError: 'Facturas con errores: {{ cant }} ',
    infoInvoices: 'Información de facturas',
    infoInvoiceSuccess: '{{cant}} facturas seleccionadas para enviar',
    infoInvoiceError: '{{cant}} facturas con errores',
    invoiceErrorInfo: '(Facturas con errores no serán tomadas en cuenta para el envío)',
    questionInfo: '¿Estás seguro que deseas enviar {{cant}} facturas?',
    invoicesSentSuccess: 'Facturas enviadas con éxito',
    infoError: 'Información de errores',
    uploadFileCsvType: 'El archivo de carga masiva es de tipo csv debe estar delimitado por comas, el orden de los campos deberá ser el siguiente:',
    customerMail: 'Correo del cliente',
    commercialCode: 'Código de comercio',
    anExample: 'Un ejemplo de un registro podría ser:',
    example: '001,450.00,prueba@gmail.com,1236545,Venta de artículos deportivos',
    note: 'NOTA: Se deberá asegurar que exista el cliente dentro de la plataforma, el código de comercio y la relación comercial entre el cliente y proveedor.',
    uploadProviderGroupfile: 'En el archivo de carga que es de tipo xlsx o xls, los campos que deberán completarse en la carga masiva de Comercios son los siguientes: ',
    uploadClientGroupfile: 'En el archivo de carga que es de tipo xlsx o xls, los campos que deberán completarse en la carga masiva de Clientes son los siguientes: ',
    personTypeFile: 'Tipo de Persona. Se rellena f para Individual y j para Jurídica.',
    departmentFile: 'Departamento. Se deberá llenar el nombre del departamento.',
    municipalityFile: 'Municipio. Se deberá llenar la información del municipio correspondiente al departamento previamente llenado',
    mccCodeFile: 'Código MCC. Se deberá indicar el código MCC correspondiente.',
    optionalBackOffice: 'Back office (opcional)',
    documentNumberFile: 'Tipo de documento. Las opciones son para persona Individual: Pasaporte, Cédula nacional, Cédula de residencia; para las personas jurídicas: NIT',
    paymentAuth: 'AUTH Pago',
    notifyPaymentAuthorizationFile: 'Notificar AUTH pago. Se deberá rellenar con s para si y con n para no.',
    downloadFile: 'Descargar archivo ejemplo',
    customerCategory: 'Categoría del cliente',
    supplierCategory: 'Categoría del comercio',
    '/create-provider': 'Crear proveedores',
    '/create-customer': 'Crear clientes',
    '/dashboard': 'Pantalla principal',
    '/load-invoice': 'Cargar facturas',
    '/customers': 'Listar, editar o deshabilitar clientes',
    // '/invoices': 'Listar facturas',
    '/expired-invoices': 'Listar facturas expiradas',
    '/related-users': 'Cliente o proveedores requeridos',
    '/register-bank': 'Crear bancos',
    '/financing-products': 'Financiar productos',
    '/bank-list': 'Listar, editar o deshabilitar bancos',
    '/register-role': 'Crear roles',
    '/role-list': 'Listar, editar y eliminar roles',
    '/register-route': 'Crear rutas',
    '/route-list': 'Listar, editar y eliminar rutas',
    '/register-financing-products': 'Crear financiamiento de productos',
    '/admin-routes': 'Asignar o quitar rutas a roles',
    '/review-invoices': 'Listar, editar, aprobar o rechazar facturas sin aprobar',
    '/approved-invoices': 'Listar, adelantar o diferir facturas aprobadas',
    '/paid-invoices': 'Listar facturas pagadas',
    '/create-lead': 'Requerir clietes o proveedores',
    '/lead-list': 'Listar, aprobar o rechazar requeridos',
    '/reports': 'Exportar a excel facturas por cliente o proveedor segun el usuario loggeado',
    '/create-payment-condition': 'Crear condición de pago',
    '/transactions': 'Facturas generadas por banco',
    '/create-profile': 'Crear perfiles',
    '/profile-list': 'Listar, editar o eliminar perfiles',
    '/create-approval-flow': 'Crear flujo de aprobacion de factura',
    '/account-profile': 'Perfil de la cuenta',
    '/invoices': 'Facturas',
    '/reject-invoices': 'Facturas rechazadas',
    '/invoice-detail': 'Detalle de factura',
    '/resp': 'Confirmación de almacenamiento de tarjeta',
    '/edit-payment-condition': 'Editar condición de pago',
    '/payment-request-sent': 'Solicitudes enviadas',
    '/payment-request-received': 'Solicitudes recibidas',
    '/support': 'Asistencia',
    '/user-commerce-code': 'Número de afiliado',
    '/register-card': 'Registrar tarjeta',
    '/replace-card': 'Sustitución de tarjeta de crédito',
    '/list-cards': 'Lista de tarjetas',
    '/manual-resolution': 'Facturas no pagadas',
    '/cancelled-invoices': 'Facturas canceladas',
    '/waiting-invoices': 'Facturas en espera',
    '/request-cancel-invoice': 'Solicitar cancelación de facturas',
    '/load-invoice-group': 'Grupo de facturas',
    '/list-invoice': 'Facturas por enviar',
  },
};

export default es;
