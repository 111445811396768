import React from 'react';
// import Panel from '../Panel';
// import Form from '../Form';
import { TextInput, SelectSingle, Panel, Form } from '../../components';

const SearchPersonForm = ({
                              t,
                              searchPerson,
                            handleInputChange,
                            document_number,
                            name,
                            email,
                            min_amount,
                            max_amount,
                            type_user,
                            user,
                            rol,
                            rut,
                            type_rol,
                            acquiring,
                            issuing_bank,
                            handleSelectChange,
                            roleList,
                            role
                          }) => (
    <Panel>
        <Form
        submitText={t('search')}
        onSubmit={(event) => {
            event.preventDefault();
            searchPerson();
        }}
        buttonClasses="mr-8px"
        >
            <div className="columns field-body" style={{ display: type_user === '' ? '' : 'none' }}>
                <div className="column">
                    <TextInput
                      name="name"
                      label={acquiring !== true ? t('customerName') : t('commerceName')}
                      value={name}
                      onChange={handleInputChange}
                      placeholder={acquiring !== true ? t('customerName') : t('commerceName')}
                      type="text"
                      controlClasses="is-expanded has-icons-left"
                      iconPosition="is-left"
                    icon="fas fa-search"
                    />
                </div>
                <div className="column">
                    <TextInput
                    name="document_number"
                    label={t('idNumber')}
                    value={document_number}
                    onChange={handleInputChange}
                    placeholder={t('idNumber')}
                    type="text"
                    controlClasses="is-expanded has-icons-left"
                    inputClasses="has-text-right"
                    iconPosition="is-left"
                    icon="fas fa-search"
                    maxlength={13}
                    />
                </div>
                <div className="column">
                    <TextInput
                      name="email"
                      label={t('email')}
                      value={email}
                      onChange={handleInputChange}
                      placeholder={t('email')}
                      type="text"
                      controlClasses="is-expanded"
                    />
                    <TextInput
                      name="max_amount"
                      value="9999999999"
                      type="hidden"
                    />
                </div>
            </div>
            <div className="columns field-body" style={{ display: type_user !== '' ? '' : 'none' }}>
                <div className="column">
                  <TextInput
                    name="user"
                    label={t('user')}
                    value={user}
                    onChange={handleInputChange}
                    placeholder={t('user')}
                    type="text"
                    controlClasses="is-expanded"
                  />
                </div>
              <div 
                className="column"
                style={{ display: type_rol === 'general_admin' ? 'none' : '' }}>
                 {type_rol.includes('main_bank') ?
                    <SelectSingle
                      name="rol"
                      label={t('rol')}
                      selectClasses="is-fullwidth"
                      placeholder={t('Select')}
                      onChange={handleSelectChange}
                      options={[
                        {label: t('allOfThem'),
                        value: ""},
                        ...roleList.map((item, index) => ({
                          label: type_user === 'main_bank_acquiring' || type_user === 'main_bank_issuing' ? `${item.label_type}` : t(item.name),
                          value: item.name,//item.id,
                        })),
                      ]}
                      value={role}
                    /> :
                    <TextInput
                      name="rol"
                      label={t('rol')}
                      value={rol}
                      onChange={handleInputChange}
                      placeholder={t('rol')}
                      type="text"
                      controlClasses="is-expanded"
                    />
                  }
              </div>
                <div className="column">
                    <TextInput
                      name="email"
                      label={t('email')}
                      value={email}
                      onChange={handleInputChange}
                      placeholder={t('email')}
                      type="text"
                      controlClasses="is-expanded"
                    />
                </div>
                <div className="column">
                    <TextInput
                      name="rut"
                      label={t('idNumber')}
                      value={rut}
                      onChange={handleInputChange}
                      placeholder={t('idNumber')}
                      type="text"
                      controlClasses="is-expanded"
                      inputClasses="has-text-right"
                    />
                </div>
            </div>
        </Form>
    </Panel>
);
SearchPersonForm.defaultProps = {
  type_user: '',
  type_rol: '',
};
export default SearchPersonForm;