import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Card, Form, Loading, Panel, TableSimple, Toggle, } from '../../components';
import SelectSingle from '../../components/SelectSingle';
import { API_URL, TOAST_CONFIG } from '../../config/constants';
import { bankActions } from '../../state/ducks/bank';
import { getPath } from '../../util';
import { roleActions } from '../../state/ducks/role';
import TextInput from '../../components/TextInput';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';

class Manuals extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    bankType: -1,
    roleType: -1,
    hidden: true,
    inputValue: '',
    inputValueRole: '',
    binaryFile: '',
  });

  componentDidMount() {
    const {
      fetchBanks,
      rolesSupport,
    } = this.props;

    fetchBanks({
      params: {
        admin: true,
      }
    });
    rolesSupport({});
  }

  handleSelectChange = (newVal, label) => {
    const {
      bank: { list },
      router: { location: { pathname } },
      getManuals,
    } = this.props;

    if (pathname === '/list-manual') {
      getManuals({
        params: {
          bank: list[newVal.value].id,
        }
      });
    }

    this.setState({
      [label.name]: newVal.value,
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleOnInputChange = (newValue) => {
    this.setState({ inputValue: newValue });
    return newValue;
  };

  handleOnInputChangeRole = (newValue) => {
    this.setState({ inputValueRole: newValue });
    return newValue;
  };

  handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    this.setState({
      binaryFile: files[0],
      file: event.target.value,
    });
  };

  saveManuals = () => {
    const {
      bankType,
      roleType,
      binaryFile,
    } = this.state;

    const {
      t,
      showMessage,
      history,
      saveManual,
      role: { list: roleList },
      bank: { list },
    } = this.props;

    const dataForm = new FormData();
    const data = {
      bank: list[bankType].id,
      role: roleList[roleType].id,
      resource: binaryFile,
    };

    if (binaryFile) {
      for (let key in data) {
        dataForm.append(key, data[key]);
      }
    }

    const dataSend = binaryFile ? dataForm : data;

    saveManual({
      data: dataSend,
      callback: () => {
        showMessage({
          message: t('manualLoaded'),
          config: TOAST_CONFIG.SUCCESS,
        });
        history.push('/dashboard');
        this.setState(this.getInitialState());
      },
    });
  };

  getGridSettings = (onToggle) => {
    const {
      t,
      role: { type: { list } }
    } = this.props;
    const {
      bankType,
    } = this.state;
    let columns;
    let data;
    let title;
    columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: 'role_id', // Required because our accessor is not a string
        Header: t('rol'),
        accessor: d => `${t(d.role.name)}`,
      },
      {
        id: 'name', // Required because our accessor is not a string
        Header: t('name'),
        accessor: d => `${d.name}`,
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              {cellInfo.original.resource
              && (
                <td>
                  <a
                    href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.resource}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button is-primary is-inverted"
                  >
                    <i className="fas fa-download mr-8px" aria-hidden="true"/>
                    {t('download')}
                  </a>
                </td>
              )
              }
              <td>
                <button
                  className="button is-primary is-inverted"
                  onClick={() => {
                    onToggle({
                      isEdit: true,
                      id: cellInfo.original.id,
                    });
                  }
                  }
                >
                  <i className="far fa-edit mr-8px" aria-hidden="true"/>
                  {t('edit')}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        ),
      }
    ];
    data = bankType !== -1 ? list : [];
    title = t('admin');
    return {
      columns,
      data,
      title,
    };
  };

  updateResource = (onToggle, id) => {
    const {
      t,
      showMessage,
      updateManual,
      getManuals,
      bank: { list },
    } = this.props;
    const {
      bankType,
      binaryFile,
    } = this.state;

    const dataForm = new FormData();
    const data = {
      id: id,
      resource: binaryFile,
    };

    if (binaryFile) {
      for (let key in data) {
        dataForm.append(key, data[key]);
      }
    }

    const dataSend = binaryFile ? dataForm : data;

    updateManual({
      data: dataSend,
      callback: () => {
        showMessage({
          message: t('successfullyUpdatedManual'),
          config: TOAST_CONFIG.SUCCESS,
        });
        getManuals({
          params: {
            bank: list[bankType].id,
          }
        });
      },
    });
    onToggle();
  };

  render() {
    const {
      hidden,
      bankType,
      roleType,
      inputValue,
      inputValueRole,
      file,
      binaryFile,
    } = this.state;
    const {
      t,
      role: { loading, list: roleList },
      bank: { list },
      router: { location: { pathname } },
    } = this.props;

    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { columns, data } = this.getGridSettings(onToggle);
          return (
            <div>
              {loading && <Loading/>}
              <Panel headingText={t('platformManuals')}>
                <Form
                  submitText={t('save')}
                  onSubmit={(event) => {
                    event.preventDefault();
                    this.saveManuals();
                  }}
                  disabled={bankType === -1 || roleType === -1 || binaryFile === ''}
                  buttonClasses={`mr-8px ${pathname !== '/list-manual' ? '' : 'is-hidden'}`}
                >
                  <div className="field is-horizontal">
                    <div className="field-body columns">
                      <div className="column is-one-third">
                        <SelectSingle
                          name="bankType"
                          label={t('bankType')}
                          selectClasses="is-fullwidth"
                          placeholder={t('Select')}
                          onChange={this.handleSelectChange}
                          isSearchable
                          options={[
                            ...list.map((item, index) => ({
                              label: item.idPerson.name + ' - ' + item.idPerson.idEmailActive.email,
                              value: index,
                            })),
                          ]}
                          value={bankType}
                          onInputChange={this.handleOnInputChange}
                          inputValue={inputValue}
                        />
                      </div>
                      {bankType !== -1
                      && (
                        <div className="column is-full">
                          <div className="columns">
                            <div className="column is-8-tablet is-offset-20-tablet mt-24px">
                              <Card
                                title={t('informationOfTheSelectedBank')}
                                subtitle={`${getPath(['idPerson', 'name'], list[bankType])}`}
                                items={[
                                  {
                                    name: 'documentNumber',
                                    value: `${getPath(['idPerson', 'idDocumentType', 'document_type'], list[bankType])} ${getPath(['idPerson', 'document_number'], list[bankType])}`,
                                  }, {
                                    name: 'email',
                                    value: `${getPath(['idPerson', 'idEmailActive', 'email'], list[bankType])}`,
                                  },
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {bankType !== -1 && pathname !== '/list-manual'
                  && (
                    <div className="field is-horizontal">
                      <div className="field-body columns">
                        <div className="column is-one-third">
                          <SelectSingle
                            name="roleType"
                            label={t('rol')}
                            selectClasses="is-fullwidth"
                            placeholder={t('Select')}
                            onChange={this.handleSelectChange}
                            isSearchable
                            options={[
                              ...roleList.map((item, index) => ({
                                label: t(item.name),
                                value: index,
                              })),
                            ]}
                            value={roleType}
                            onInputChange={this.handleOnInputChangeRole}
                            inputValue={inputValueRole}
                          />
                        </div>
                        <div className="column">
                          <TextInput
                            name="file"
                            label={t('userManual')}
                            value={file}
                            fileName={binaryFile && binaryFile.name}
                            onChange={this.handleFileChange}
                            type="file"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {pathname === '/list-manual'
                  && (
                    <div className="column is-full">
                      <div className="panel-top-content">
                        <Link
                          className="button is-primary"
                          to="/save-manual"
                        >
                          <i className="fas fa-plus mr-8px" aria-hidden="true"/>
                          {t('loadManuals')}
                        </Link>
                      </div>
                      <TableSimple data={data} columns={columns}/>
                    </div>
                  )
                  }
                </Form>
                {
                  toggled && toggleProps.isEdit
                  && (
                    <Modal onToggle={onToggle}> {loading && <Loading/>}
                      <Panel
                        headingText={t('editBank', { name: bankType !== -1 ? list[bankType].idPerson.name : '' })}>
                        <div className="FormLogin">
                          <Form
                            className="column is-full"
                            submitText={t('save')}
                            onSubmit={(event) => {
                              event.preventDefault();
                              this.updateResource(onToggle, toggleProps.id);
                            }}
                            disabled={binaryFile === ''}
                            buttonClasses={`mr-8px`}
                          >
                            <div className="column"
                                 style={{ fontFamily: 'Roboto, Arial, sans-serif' }}>
                              <TextInput
                                name="file"
                                label={t('userManual')}
                                value={file}
                                fileName={binaryFile && binaryFile.name}
                                onChange={this.handleFileChange}
                                type="file"
                              />
                            </div>
                          </Form>
                        </div>
                      </Panel>
                    </Modal>
                  )
                }
              </Panel>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

Manuals.propTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ role, bank, router, }) => (
  {
    role,
    bank,
    router,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...bankActions,
    ...roleActions,
  }),
  withNamespaces(),
)(Manuals);