import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { withNamespaces } from 'react-i18next';
import { Form, TextInput } from '../../../../../components';
import ReactTooltip from 'react-tooltip';

const FormLogin = ({
  t,
  handleSubmit,
  handleInputChange,
  data: { password, confirmPassword, email = '' },
  isPasswordExpire,
  logout,
}) => {

  const [passSpecs, setPassSpecs] = useState({
    spec1: 0,
    spec2: 0,
    spec3: 0,
    spec4: 0,
    spec5: 0,
    spec6: 0,
    spec7: 0,
  })
  const [showPass, setShowPass] = useState(false);
  const [isPassOk, setIsPassOk] = useState(false);

  useEffect(() => {
    // setIsPassOk(passSpecs.spec1 === 1 && Object.values(passSpecs).reduce((prev, curr) => prev+curr) > 3);
    setIsPassOk(Object.values(passSpecs).reduce((prev, curr) => prev+curr) === 7);
  }, [passSpecs])

  const validatePass = e => {
    const {
      target: {
        value: targetValue,
      },
    } = e;
    const upperCase = /[A-Z]/g,
    lowerCase = /[a-z]/g,
    numbers = /\d/,
    specialChar = /[^A-Za-z0-9]/,
    userName = email.substring(0, email.lastIndexOf("@")),
    consecutives = /(.)\1{1,}/;

    setPassSpecs({
      spec1: targetValue.length < 65 && targetValue.length > 11 ? 1 : 0,
      spec2: targetValue.match(upperCase) ? 1 : 0,
      spec3: targetValue.match(lowerCase) ? 1 : 0,
      spec4: targetValue.match(numbers) ? 1 : 0,
      spec5: targetValue.match(specialChar) ? 1 : 0,
      spec6: targetValue.includes(userName) || !targetValue ? 0 : 1,
      spec7: targetValue.length > 0 && !targetValue.match(consecutives) ? 1 : 0,
    })
    
  }

  return (
    <div className="FormLogin">
      <div className="column is-full" style={{
        color: 'black',
        fontFamily: 'Roboto, Arial, sans-serif'
      }}>
        <span>{isPasswordExpire === 1 ? t('passwordHasExpired') : t('enterYourNewPassword')}</span>
      </div>
      {/*<Form
        className="column is-full"
        submitText={t('send')}
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit();
        }}
        buttonClasses="button-chile center"
        band={'#878786'}
      >*/}
        <div>
          <div className="password-inputs" data-tip={t('passReqTooltip')}>
            <TextInput
              name="password"
              placeholder={t('password')}
              type={showPass ? 'text' : 'password'}
              onChange={e => {
                handleInputChange(e)
                validatePass(e)
              }}
              value={password}
              login={true}
            />
            <div className='password-inputs-BTNS'>
              <i className={`fas ${isPassOk ? 'fa-check has-text-success' : 'fa-times has-text-danger'}`}></i>
            </div>
            <ReactTooltip/>
          </div>
          <div className="password-inputs"  data-tip={t('confirmPassTooltip')}> 
            <TextInput
              name="confirmPassword"
              placeholder={t('confirmPassword')}
              type={showPass ? 'text' : 'password'}
              onChange={handleInputChange}
              value={confirmPassword}
              login={true}
              helpText={
                confirmPassword === '' ? t('requiredConfirm'):
                confirmPassword !== password ? t('differentConfirmPass') : ''
              }

            />
            <div className='password-inputs-BTNS'>
              <i className={`fas ${password && password === confirmPassword ? 'fa-check has-text-success' : 'fa-times has-text-danger'}`}></i>
            </div>
            <ReactTooltip/>
          </div>

          <div className='show-pass' onClick={() => setShowPass(!showPass)}>
          {/* <div className='show-pass' onMouseDown={() => setShowPass(true)} onMouseUp={() => setShowPass(false)}> */}
            <i className={`fas ${showPass ? 'fa-eye' : 'fa-eye-slash'}`}></i>
            <p>{!showPass ? t('showPass') : t('hidePass')}</p>
          </div>

          <div className='pass-specs-main'>
            <p>{t('passGral')}</p>
            <ul className=''>
              {
                Object.values(passSpecs).map((spec, index) => {
                  return (
                    <li key={index} className={spec && 'is-checked'}>-  {t(`passSpecs${index + 1}`)}
                      {spec ? <i className='fas fa-check'></i> : null}
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
        <div className='field is-horizontal'>
          <div className='column'>
            <button
            className="button is-primary is-fullwidth"
            type="button"
            onClick={logout}
            >
              {t('return')}
            </button>
          </div>
          <div className='column'>
            <button
              className="button is-primary is-fullwidth"
              type="button"
              // disabled={(!password || password !== confirmPassword || !isPassOk) }
              disabled={(!password || password !== confirmPassword || !isPassOk) }
              onClick={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            >
              {t("send")}
            </button>
          </div>
        </div>
      {/*</Form>*/}
    </div>
  );
};

FormLogin.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  data: PropTypes.shape({
    password: PropTypes.string.isRequired,
    confirmPassword: PropTypes.string.isRequired,
    email: PropTypes.string,
  }).isRequired,
};

export default withNamespaces()(FormLogin);
