import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading } from '../../components';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { TOAST_CONFIG } from '../../config/constants';
import { roleActions } from '../../state/ducks/role';
import { gatewayActions } from '../../state/ducks/gateway';
import UserForm from '../../components/UserForm';

class CreateUser extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    name: '',
    lastName: '',
    documentType: -1,
    documentNumber: '',
    email: '',
    position: '',
    adminPosition: '',
    roleType: -1,
    roles: [],
    edit: false,
    hidden: false,
    role: -1,
    phoneNumber: '',
    unformattedRut: '',
    collaborator_number: '',
    disabled: true,
    type_legal_person: '',
    typePerson: '',
  });

  componentDidMount() {
    const {
      auth: { user: { b2b: { id, type_user }, user_id } },
      fetchRoles,
      fetchDocumentTypes,
      router: { location: { pathname } },
      getGateway,
    } = this.props;

    fetchRoles({
      params: {
        owner_id: id,
      },
    });

    if (type_user === 'main_bank_issuing' || type_user === 'main_bank_acquiring') {
      fetchDocumentTypes({
        params: { legal_type: 'F' },
        callback: () => {
          this.setState({
              type_legal_person: 'FIS',
          })
        }
      });
    }

  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  formatRUT = (n) => {
    return n.replace(/[^0-9Kk]/, '');
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    /*if (name === 'documentNumber') {
      //let num = this.formatNumber(event.target.value);

      let num = this.formatRUT(event.target.value);
      let unformattedNum = this.formatRUT(event.target.value);
      let prenum = '';
      let postnum = '';

      if(num.length >= 9){
        prenum = num.slice(0,8);
        postnum = num.slice(8,9);
        num = prenum+"-"+postnum;
      }

      this.setState({ unformattedRut: unformattedNum});

      this.setState({
        [name]: (num),
      });
    } else 
    if (name !== 'documentNumber') {
      this.setState({
        [name]: value,
      });
    }*/
    this.setState({
      [name]: value,
    });
  };

  handleItemChange = (newValue) => {
    this.setState({ roles: newValue });
    if(newValue){
      if(newValue[0].name == 'acquiring_bank'){
        this.setState({isAcquiring: true});
      }
    }
  };

  handleSelectChange = (newVal, label) => {
    const { role: { list: roleList } } = this.props;
    const {
      collaborator_number,
    } = this.state;
    let rolename = '';

    if (label.name === 'role') {
      this.setState({
        [label.name]: newVal.value,
        disabled: !(roleList[newVal.value].name === 'acquiring_bank' ||
          roleList[newVal.value].name === 'issuing_bank'),
        collaborator_number: (roleList[newVal.value].name === 'acquiring_bank' ||
          roleList[newVal.value].name === 'issuing_bank') ? collaborator_number : '',
      });
    } else if (label.name !== 'role') {
      this.setState({
        [label.name]: newVal.value,
      });
    }

    for (let i = 0; i < roleList.length; i++) {
      if (roleList[i].id == newVal.value) {
        rolename = roleList[i].name;
        if (rolename === 'acquiring_bank') {
          this.setState({ isAcquiring: true });
        } else {
          this.setState({ isAcquiring: false });
        }
      }
    }
  };

  /*formatEmail = (email) => {
    let re = /\S+@baccredomatic\.com+/;
    let re1 = /\S+@elcallao\.com+/;
    let re2 = /\S+@baccredomatic\.gt+/;
    let re3 = /\S+@medullaris\.com+/;
    return re.test(email) || re1.test(email) || re2.test(email) || re3.test(email);
  };*/

  formatEmail = (email) => {
    let re = /\S+@yopmail\.com+/;
    return re.test(email);
  };

  createUser = () => {
    const {
      createProvider,
      editProvider,
      editUser,
      t,
      showMessage,
      documentType: { list: documentTypesList },
      role: { list: roleList },
      router: { location: { pathname } },
      history,
    } = this.props;

    const {
      name,
      lastName,
      roleType,
      documentType,
      documentNumber,
      email,
      role,
      adminPosition,
      phoneNumber,
      unformattedRut,
      collaborator_number,
      type_legal_person,
    } = this.state;

    /*if (documentType === -1) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('SelectDocumentType')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
      return;
    }*/

    //let role_name = roleList[role].id;

    /*if (roles.length === 1) {
      role_name = roles[0].id;
    } else {
      for (let i = 0; i < roles.length; i++) {
        role_name.push(roles[i].id);
      }
    }*/

    /*switch (data.document_type) {
      case 0:
        data.document_type = 'CI';
        break;
      case 1:
        data.document_type = 'Pasaporte';
        break;
      case 2:
        data.document_type = 'Pasaporte';
        break;
    }*/

    if (this.validateEmptyFields()) {
      // if (this.isEmail(email)) {
      if ((roleList[role].name === 'acquiring_bank' ||
        roleList[role].name === 'issuing_bank')) {
        if (this.formatEmail(email)) {
          showMessage({
            message: <strong style={{ color: '#ffffff' }}>{t('validEmail')}</strong>,
            config: TOAST_CONFIG.ERROR,
          });
          return;
        }
        if (collaborator_number.length < 8) {
          showMessage({
            message: <strong
              style={{ color: '#ffffff' }}>{t('collaboratorNumberCharacter')}</strong>,
            config: TOAST_CONFIG.ERROR,
          });
          return;
        }
      }
      const data = {
        name,
        lastname: lastName,
        document_type: documentType !== -1 ? documentTypesList[documentType].document_type : '',
        document_number: documentNumber,
        email,
        role_name: roleList[role].id,
        user_type: 'flecha',
        agency_user_type: 'business_admin',
        b2b: {
          type_legal_person,
          bank_id: null,
          admin: 0,
          admin_position: adminPosition,
          issuing_bank: 0,
          acquiring_bank: 0,
          seller: 0,
          buyer: 0,
          collaborator_number,
        },
        phone: {
          phone_number: phoneNumber,
          country_code: '+56',
          is_main: 1,
        },
      };

      createProvider({
        data,
        is_perfil: true,
        callback: () => {
          history.push('/dashboard');
          showMessage({
            message: t('createUserSuccess', { name }),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
        },
      });
      /*} else {
        showMessage({
          message: t('incorrectEmail', { email }),
          config: TOAST_CONFIG.WARNING,
        });
        return;
      }*/
    } else {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('emptyAll')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }
  };

  isEmail = n => /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/.test(n);

  validateEmptyFields = () => {
    const {
      name,
      lastName,
      //documentType,
      //documentNumber,
      email,
      //roles,
      adminPosition,
      //roleType,
      phoneNumber,
      role,
    } = this.state;

    return !(
      name === '' ||
      lastName === '' ||
      //documentType === -1 ||
      //documentNumber === '' ||
      email === '' ||
      adminPosition === '' ||
      role === -1 ||
      phoneNumber === '');
  };

  labelType = (roleList) => {
    const {
      t,
      auth: { user: { b2b: { type_user } } },
    } = this.props;
    const roles = [];

    for (let i = 0; i < roleList.length; i++) {
      if (type_user === 'main_bank_issuing') {
        roles.push({
          id: roleList[i].id,
          name: roleList[i].name,
          type: roleList[i].type,
          owner_id: roleList[i].owner_id,
          label_type: t(roleList[i].name)  /*=== 'issuing_bank' ? t('platformAdministrator') :
            roleList[i].name === 'issuing_query' ? t('userQuery') : roleList[i].name === 'cycle_loader' ? t('userBillingCycles') :
              roleList[i].name === 'rate_operator' ? t('commercialBillingUser') : t('commercialBillingUser')*/,
        });
      } else {
        roles.push({
          id: roleList[i].id,
          name: roleList[i].name,
          type: roleList[i].type,
          owner_id: roleList[i].owner_id,
          label_type: /*type_user === 'main_bank_acquiring' ? roleList[i].name === 'acquiring_bank' ? t('acquiringBank') : t('acquiringQuery') :*/
            t(roleList[i].name)
        });
      }
    }

    return roles;
  };

  handleInputChangeTypeDocument = (event) => {
    const {
      fetchDocumentTypes,
    } = this.props;
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    fetchDocumentTypes({
      params: { legal_type: value === 'legal' ? 'J' : 'F' },
      callback: (data) => {
        this.setState({
          type_legal_person: value === 'legal' ? 'JUR' : 'FIS',
          typeDocument: data,
          [name]: value,
        });
      }
    });
  };

  cancelSave = () => {
    const { history } = this.props;
    history.push('/dashboard');
  };

  render() {
    const {
      t,
      documentType: { list: documentTypesList, loading: documentLoading },
      role: { list: roleList, loading: roleLoading },
      providers: { loading: providerLoading },
      auth: { user: { b2b: { type_user } } },
    } = this.props;
    const {
      name,
      lastName,
      documentNumber,
      email,
      adminPosition,
      documentType,
      edit,
      hidden,
      roles,
      isAcquiring,
      gateways,
      gateway,
      phoneNumber,
      collaborator_number,
      disabled,
      typePerson,
    } = this.state;

    return (
      <div>
        {(providerLoading || roleLoading) && <Loading/>}
        <div className="columns">
          <div className="column is-full">
            <UserForm
              t={t}
              title={t('createUser')}
              onSubmit={this.createUser}
              handleInputChange={this.handleInputChange}
              name={name}
              lastName={lastName}
              handleSelectChange={this.handleSelectChange}
              handleItemChange={this.handleItemChange}
              documentTypesList={documentTypesList}
              documentNumber={documentNumber}
              documentType={documentType}
              email={email}
              position={adminPosition}
              roleList={this.labelType(roleList)}
              roles={roleList}
              edit={edit}
              hidden={hidden}
              isAcquiring={isAcquiring}
              typeUser={type_user}
              required={type_user === 'main_bank_issuing'}
              phoneNumber={phoneNumber}
              collaborator_number={collaborator_number}
              disabled={disabled}
              handleInputChangeTypeDocument={this.handleInputChangeTypeDocument}
              typePerson={typePerson}
              cancelSave={this.cancelSave}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreateUser.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  location: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  createProvider: PropTypes.func.isRequired,
  roles: PropTypes.shape(Object).isRequired,
  editUser: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({
                          providers,
                          documentType,
                          auth,
                          role,
                          router,
                        }) => ({
  providers,
  documentType,
  auth,
  role,
  router,
});
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...providersActions,
    ...roleActions,
    ...gatewayActions,
  }),
  withNamespaces(),
)(CreateUser);