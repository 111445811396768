import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { authActions } from '../../../state/ducks/auth';
import { TOAST_CONFIG } from '../../../config/constants';
import { Loading } from '../../../components';
import FormRecover from './components/FormRecover';
import Logo from '../../../resources/images/assets/logo.svg';
import './styles.scss';
import bacLogo from '../../../resources/images/assets/santander-logo.png';
import logoAmex from '../../../resources/images/assets/logo-amex.png';

class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: '',
      password: '',
      confirmPassword: '',
      email: '',
    };
  }

  componentDidMount = () => {
    const { 
      auth: { language, user: { email, b2b: { is_password_expire } } },
      location: { search },
      getUsername,
  } = this.props;
    this.setState({ language });

    //window.location.hash="no-back-button";

    if(is_password_expire === -1 || search.replace('?token=', '') !== '') {
      getUsername({
        params: {
          token: search.replace('?token=', ''),
        },
        callback: response => {
          response.success ?
          this.setState({email: response.data}) :
          this.setState({email: ''})
        }
      });
    } else {
      this.setState({email})
    }
  };

  handleInputChange = (event) => {
    const {
      target: {
        type, checked, value: targetValue, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal) => {
    const { i18n } = this.props;
    this.setState({
      language: newVal.target.value,
    });
    i18n.changeLanguage(newVal.target.value);
    // eslint-disable-next-line no-undef
    window.location.reload();
  };

  handleSubmit = () => {
    const {
      t,
      passwordResetToken,
      passwordResetLogin,
      showMessage,
      history,
      auth: { user },
      updateB2B,
    } = this.props;
    const {
      password,
      confirmPassword
    } = this.state;
    const url = window.location.href;

    // eslint-disable-next-line prefer-const
    let regex = /[?&]([^=#]+)=([^&#]*)/g,
      params = {},
      match;
    // eslint-disable-next-line no-cond-assign
    while (match = regex.exec(url)) {
      params[match[1]] = match[2];
    }

    if(user.b2b.password_changed === 0){
      passwordResetLogin({
        email: user.email,
        password,
        callback: () => {
          updateB2B({
            params: {
              user_id: /*user.b2b.roles.owner_id === null ? user.user_id :*/ user.profile.user_id,
            },
            callback: () => {
              /*if (user.profile.role === 'buyer' && user.b2b.saved_credentials === 0) {
                history.push('/save-credentials');
              } else {*/
                window.top.location.replace('/dashboard');
              //}
              showMessage({
                message: t('changePasswordSuccess'),
                config: TOAST_CONFIG.SUCCESS,
              });
            }
          });
        }
      });
    }else{
      if (password !== '' && confirmPassword !== '') {
        if (password === confirmPassword) {
          params.password = password;
          passwordResetToken({
            params,
            callback: (response) => {
              if(response.success === true){
                history.push('/dashboard');
                showMessage({
                  message: t('changePasswordSuccess'),
                  config: TOAST_CONFIG.SUCCESS,
                });
              }else{
                //history.push('/login'); //Se eliminó redirección para evitar confusión
              }
            }
          });
        } else {
          showMessage({
            message: <strong style={{ color: '#ffffff' }}>{t('nonMatchingPasswords')}</strong>,
            config: TOAST_CONFIG.ERROR,
          });
        }
      } else if (password !== '' || confirmPassword !== '') {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      }
    }
  };

  logout = () => {
    const {logout, history} = this.props;
    logout({});
    history.push('/login');
  }

  render() {
    const {
      auth: { loading, user: { b2b: { is_password_expire } } },
      t,
    } = this.props;
    const { language, password, confirmPassword } = this.state;
    /*if (logged === true) {
      return <Redirect to="/dashboard" />;
    }*/
    return (
      <div className="SignIn">
        <ToastContainer />
        {loading && <Loading />}
        <section className="hero is-info is-fullheight">
          <div className="headerLogin">
            <div>
              <div className="footer-left">
                <img src={bacLogo} alt="logo" style={{
                  width: '115px',
                  height: '60px',
                  display: 'block'
                }}/>
              </div>
              <div className="footer-right" style={{
                alignSelf: 'flex-end',
                marginBottom: '1rem',
                float: 'right'
              }}>
                <div className="field">
                  <div className="content-select">
                    <select
                      name="language"
                      value={language}
                      onChange={this.handleSelectChange}
                    >
                      <option value="es">{t('esp')}</option>
                      <option value="pt">{t('por')}</option>
                      <option value="en">{t('eng')}</option>
                    </select>
                    <i/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-body">
            <div className="container" style={{ marginTop: '10vh' }}>
              <div className="column panel fadeInUp animated" style={{ background: '#B3B2B2' }}>
                <div className="contentLogo" >
                  <div className="centerImg" >
                    <img src={Logo} alt="logo"/>
                  </div>
                </div>
                <FormRecover
                  handleSubmit={this.handleSubmit}
                  handleInputChange={this.handleInputChange}
                  data={{
                    password,
                    confirmPassword,
                    email: this.state.email,
                    // email: is_password_expire ? email : username,
                  }}
                  logout={this.logout}
                  isPasswordExpire={is_password_expire}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="footerLogin">
          <div style={{
            width: '150px',
            height: '20px',
            float: 'right'
          }}>
            <img src={logoAmex} alt="logo"/>
          </div>
        </div>
      </div>
    );
  }
}

RecoverPassword.propTypes = {
  passwordResetToken: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
  i18n: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => (
  {
    auth,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withNamespaces(),
)(RecoverPassword);
