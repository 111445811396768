import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { authActions } from '../../state/ducks/auth';
import { Form, Loading, TextInput } from '../../components';
import Logo from '../../resources/images/assets/logo.svg';
import './styles.scss';
import Fingerprint2 from 'fingerprintjs2';
import { formatDateHour } from '../../util';
import bacLogo from '../../resources/images/assets/santander-logo.png';
import logoAmex from '../../resources/images/assets/logo-amex.png';

class ValidateCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: '',
      device_info: '',
      expired: '',
    };
  }

  componentDidMount = () => {
    const { auth: { language, } } = this.props;
    this.setState({ language });

    const promise = new Promise((resolve) => {
      let options = {
        excludes: {
          touchSupport: true,
          fontsFlash: true,
          screenResolution: true,
          pixelRatio: true,
          colorDepth: true,
          availableScreenResolution: true,
        }
      };

      Fingerprint2.get(options, function (components) {
        const values = components.map(function (component) {
          return component.value;
        });
        const murmur = Fingerprint2.x64hash128(values.join(''), 31);
        resolve(murmur);
      });
    });

    promise.then(result => {
      this.setState({ device_info: result });
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        type, checked, value: targetValue, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal) => {
    const { i18n } = this.props;
    this.setState({
      language: newVal.target.value,
    });
    i18n.changeLanguage(newVal.target.value);
    // eslint-disable-next-line no-undef
    window.location.reload();
  };

  validateCode = () => {
    const {
      history,
      validateCode,
      auth: { user: { user_id } },
      logout,
    } = this.props;
    const {
      code,
      device_info,
    } = this.state;

    const data = {
      code,
      user: user_id,
      device_info,
    };

    validateCode({
      data,
      callback: (response) => {
        if (response.success === false) {
          logout({});
          history.push('/login');
        } else {
          window.top.location.replace('/login');
        }
      },
    });
  };

  render() {
    const {
      auth: { loading, user },
      t,
    } = this.props;
    const { language, code } = this.state;

    return (
      <div className="SignIn">
        <ToastContainer/>
        {loading && <Loading/>}
        <section className="hero is-info is-fullheight">
          <div className="headerLogin">
            <div>
              <div className="footer-left">
                <img src={bacLogo} alt="logo" style={{
                  width: '115px',
                  height: '60px',
                  display: 'block'
                }}/>
              </div>
              <div className="footer-right" style={{
                alignSelf: 'flex-end',
                marginBottom: '1rem',
                float: 'right'
              }}>
                <div className="field">
                  <div className="content-select">
                    <select
                      name="language"
                      value={language}
                      onChange={this.handleSelectChange}
                    >
                      <option value="es">{t('esp')}</option>
                      <option value="pt">{t('por')}</option>
                      <option value="en">{t('eng')}</option>
                    </select>
                    <i/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero-body">
            <div className="container" style={{ marginTop: '10vh' }}>
              <div className="column panel fadeInUp animated" style={{ background: '#B3B2B2' }}>
                <div className="contentLogo" >
                  <div className="centerImg" >
                    <img src={Logo} alt="logo"/>
                  </div>
                </div>
                <div className="FormLogin">
                  <div className="column is-full" style={{
                    color: 'black',
                    fontFamily: 'Roboto, Arial, sans-serif'
                  }}>
                    <span>{t('verificationCodeSent')}</span>
                  </div>
                  <Form
                    className="column is-full"
                    submitText={t('send')}
                    onSubmit={(event) => {
                      event.preventDefault();
                      this.validateCode();
                    }}
                    buttonClasses="button-chile center"
                    band={'#878786'}
                  >
                    <div style={{ fontFamily: 'Arial' }}>
                      <TextInput
                        name="code"
                        placeholder={t('verificationCode')}
                        label={user.hasOwnProperty('expired_at') ? t('theCodeExpiresIn', { time: formatDateHour(user.expired_at) }) : ''}
                        type="text"
                        onChange={this.handleInputChange}
                        value={code}
                        login={true}
                      />
                      <br/>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="footerLogin">
          <div style={{
            width: '150px',
            height: '20px',
            float: 'right'
          }}>
            <img src={logoAmex} alt="logo"/>
          </div>
        </div>
      </div>
    );
  }
}

ValidateCode.propTypes = {
  passwordResetToken: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
  i18n: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => (
  {
    auth,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withNamespaces(),
)(ValidateCode);
