import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading, TableSimple, Toggle, } from '../../components';
import { API_URL, TOAST_CONFIG } from '../../config/constants';
import { documentTypeActions } from '../../state/ducks/documentType';
import { roleActions } from '../../state/ducks/role';
import { clientsActions } from '../../state/ducks/clients';
import { authActions } from '../../state/ducks/auth';
import Panel from '../../components/Panel';
import TextInput from '../../components/TextInput';
import { providersActions } from '../../state/ducks/providers';
import Modal from '../../components/Modal';
import { formatAmount, formatDate } from '../../util';
import ReactTooltip from 'react-tooltip';

class InvoiceGroup extends Component {
  constructor(props) {
    super(props);
    this.state = { ...this.getInitialState() };
  }

  getInitialState = () => ({
    name: '',
    registerLoading: false,
    bugs: [],
    file: '',
    binaryFile: '',
    fileZip: '',
    binaryFileZip: '',
    alert: true,
    is_default: false,
    array: false,
    invoiceSuccess: '',
    invoiceError: '',
    cant: false,
    description: '',
    listCommerceCode: [],
    statusCommerceCode: false,
    commerceCode: -1,
  });

  componentDidMount() {
    const {
      auth: { user: { b2b: { id, }, }, },
      backPreInvoice,
      getProviderCommerceCode,
      router: { location: { pathname } },
    } = this.props;

    if (pathname === '/load-invoice-group') {
      getProviderCommerceCode({
        params: {
          id: id,
        },
        callback: (response) => {
          this.setState({
            listCommerceCode: response.hasOwnProperty('commerce_codes') ?
              response.commerce_codes : []
          });
        },
      });

      backPreInvoice({
        data: { receiver_id: id },
        callback: () => {
          this.resetState();
        },
      });
    }
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleInputChange = (event) => {
    const {
      array,
      cant,
    } = this.state;
    const {
      providers: { receiver },
    } = this.props;
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    const id = name.split('-');
    let cant1 = 0;
    let array1 = Array.isArray(array) ? array : receiver.success_invoices;

    array1.forEach(function (detail) {
      if (detail.transaction.id === id[1]) {
        if (detail.check === null) {
          detail.check = false;
          cant1 = cant + 1;
        } else {
          if (detail.check === true) {
            detail.check = false;
            cant1 = cant - 1;
            return;
          } else {
            detail.check = true;
            cant1 = cant + 1;
            return;
          }
        }
      }
    });

    let total = 0;
    if (Array.isArray(array1)) {
      array1.forEach(function (detail) {
        if (detail.check === true) {
          total += (+1);
        }
      });
    }

    this.setState({
      [name]: value,
      array: array1,
      cant: total,
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  handleFileChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const {
      t,
      showMessage,
    } = this.props;
    const files = Array.from(event.target.files);
    const arrays = files[0].name;
    let ext = arrays.split('.');
    let extLength = ext.length - 1;

    if (name === 'fileCsv') {
      if (ext[extLength].toLowerCase() === 'csv') {
        this.setState({
          binaryFile: files[0],
          file: event.target.value,
        });
      } else {
        showMessage({
          message: t('errorLoad', { type: 'csv' }),
          config: TOAST_CONFIG.WARNING,
        });
      }
    }
    if (name === 'fileZip') {
      if (ext[1] === 'zip') {
        if (parseInt(files[0].size / 1024) > 3072) {
          showMessage({
            message: t('fileSizeExceeded'),
            config: TOAST_CONFIG.WARNING,
          });
        } else {
          this.setState({
            binaryFileZip: files[0],
            fileZip: event.target.value,
          });
        }
      } else {
        showMessage({
          message: t('errorLoad', { type: 'zip' }),
          config: TOAST_CONFIG.WARNING,
        });
      }
    }
  };

  sendInvoices = () => {
    const {
      /*t,
      showMessage,*/
      history,
      auth: {
        user: {
          b2b: {
            id,
          },
        },
      },
      //providers: { receiverCommerce: { commerce_codes } },
      loadInvoiceGroup,
    } = this.props;
    const {
      binaryFile,
      binaryFileZip,
      //commerceCode,
    } = this.state;

    const data = {
      receiver_id: id,
      invoices_file: binaryFile,
      vouchers_file: binaryFileZip,
      //commerce_code: commerce_codes[commerceCode].id,
    };

    loadInvoiceGroup({
      data,
      callback: () => {
        history.push('/list-invoice');
        /*showMessage({
          message: t('uploadedFiles'),
          config: TOAST_CONFIG.SUCCESS,
        });*/
        this.resetState();
      },
    });
  };

  //facturas con errores
  getGridSettings = (onToggle) => {
    const {
      t,
      providers: { receiver },
    } = this.props;
    const columns = [
      {
        id: 'invoicesNumber', // Required because our accessor is not a string
        Header: t('invoicesNumber'),
        headerClassName: 'wordwrap',
        accessor: d => d.invoice_number === null ? '-' : `${d.invoice_number}`,
      },
      {
        id: 'totalPay', // Required because our accessor is not a string
        Header: t('totalPay'),
        accessor: d => d.adjust_amount === null ? '-' : (formatAmount(d.adjust_amount)),
      },
      {
        id: 'email', // Required because our accessor is not a string
        Header: t('email'),
        accessor: d => d.email === null ? '-' : `${d.email}`,
      },
      {
        id: 'description', // Required because our accessor is not a string
        Header: t('description'),
        accessor: d => d.description === null ? '-' : `${d.description}`,
      },
      {
        id: 'errors', // Required because our accessor is not a string
        Header: t('errors'),
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              <td>
                <button
                  className="button is-primary is-inverted"
                  onClick={() => {
                    this.setState({
                      bugs: cellInfo.original.bugs,
                    }, () => {
                      onToggle({
                        isBuyer: true,
                      });
                    });
                  }}
                >
                  <i className="fas fa-info-circle mr-8px" aria-hidden="true"/>
                  {t('info')}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        ),
      },
    ];
    const data = receiver.length === 0 ? receiver : receiver.failed_invoices;
    const title = t('invoicesError', { cant: receiver.length === 0 ? '0' : receiver.failed_invoices.length });
    return {
      columns,
      data,
      title,
    };
  };

  //facturas correctas
  getGridSendInvoice = (onToggle) => {
    const {
      t,
      providers: { receiver },
    } = this.props;
    const {
      is_default,
      array,
      cant,
    } = this.state;
    const columns1 = [
      {
        id: 'invoicesNumber', // Required because our accessor is not a string
        Header: t('invoicesNumber'),
        headerClassName: 'wordwrap',
        accessor: d => `${d.transaction.invoice_number}`,
      },
      {
        id: 'shippingDate',
        Header: t('shippingDate'),
        accessor: d => d.transaction.date === null ? '-' : `${formatDate(d.transaction.date)}`,
      },
      {
        id: 'totalPay', // Required because our accessor is not a string
        Header: t('totalPay'),
        accessor: d => `${formatAmount(d.transaction.adjusted_amount)}`,
      },
      {
        id: 'client', // Required because our accessor is not a string
        Header: t('client'),
        accessor: d => `${d.name}`,
      },
      {
        id: 'description', // Required because our accessor is not a string
        Header: t('description'),
        Cell: cellInfo => (
          <div>
                <span data-type="info"
                      data-tip={cellInfo.original.transaction.description}>{cellInfo.original.transaction.description}
                </span>
            <ReactTooltip/>
          </div>
        ),
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        Cell: cellInfo => (
          <table style={{ visibility: cellInfo.original.transaction.resource !== null ? '' : 'hidden' }}>
            <tbody>
            <tr>
              <td>
                <a
                  href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.transaction.resource}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button"
                >
                    <span className="icon" title={t('download')}>
                      <a className="ml-">
                        <i className="fas fa-download"/>
                      </a>
                    </span>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        ),
      },
      {
        id: 'select', // Required because our accessor is not a string
        Header: t('selected'),
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              <td>
                <label htmlFor="noRetain" className="checkbox"/>
                <input
                  label={t('flowApprovalCheck')}
                  name={'is_default-' + cellInfo.original.transaction.id}
                  value={is_default}
                  onChange={this.handleInputChange}
                  type="checkbox"
                  checked={cellInfo.original.check === null ? true : cellInfo.original.check}
                />
              </td>
            </tr>
            </tbody>
          </table>
        )
      },
    ];
    const data1 = receiver.length === 0 ? receiver : Array.isArray(array) ? array : receiver.success_invoices;
    const title1 = t('sendInvoices', { cant: (cant === false && Array.isArray(receiver.success_invoices)) ? receiver.success_invoices.length : cant });
    return {
      columns1,
      data1,
      title1,
    };
  };

  loadPreInvoice = (onToggle) => {
    const {
      auth: {
        user: {
          b2b: {
            id,
          },
        },
      },
      t,
      showMessage,
      loadPreInvoice,
      history,
      providers: { receiver },
    } = this.props;
    const {
      array,
    } = this.state;

    let arrayInvoice = [];
    let array1 = Array.isArray(array) ? array : receiver.success_invoices;

    array1.forEach(function (detail) {
      if (detail.check === true) {
        arrayInvoice.push(detail);
      }
    });

    const data = {
      receiver_id: id,
      invoices: arrayInvoice,
    };

    loadPreInvoice({
      data,
      callback: () => {
        history.push('/review-invoices');
        showMessage({
          message: t('invoicesSentSuccess'),
          config: TOAST_CONFIG.SUCCESS,
        });
        this.resetState();
      },
    });
  };

  closeModal = (onToggle) => {
    onToggle();
  };

  totalInvoiceSuccess = (band) => {
    const {
      array,
    } = this.state;
    const {
      providers: { receiver },
    } = this.props;

    let total = 0;
    let array1 = Array.isArray(array) ? array : receiver.success_invoices;

    if (Array.isArray(array1)) {
      array1.forEach(function (detail) {
        if (detail.check === true) {
          total += (+1);
        }
      });
    }
    if (band) {
      return total;
    }
    return {
      invoiceSuccess: total,
      invoiceError: receiver.length === 0 ? 0 : receiver.failed_invoices.length
    };
  };

  handleSelectChange = (newVal, label) => {
    const {
      providers: { receiverCommerce: { commerce_codes } },
    } = this.props;

    this.setState({
      [label.name]: newVal.value,
      description: commerce_codes[newVal.value].commerce_code,
    });
  };

  arrayCsv = () => {
    const {
      t,
    } = this.props;
    return  [t('invoicesNumber'), t('condition_amount'), t('customerMail'), t('commercialCode'), t('description')];
  };

  render() {
    const {
      t, history,
      auth: { user: { b2b: { id } } },
      backPreInvoice,
      role: { loading: roleLoading },
      router: { location: { pathname } },
      providers: { loading: providerLoading },
    } = this.props;
    const {
      file, binaryFile, binaryFileZip, fileZip,
      bugs, invoiceSuccess, invoiceError, //cant,
      //description, commerceCode, listCommerceCode,
    } = this.state;
    const loading = roleLoading || providerLoading;

    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          const { title1, columns1, data1 } = this.getGridSendInvoice(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <Fragment>
                {pathname !== '/list-invoice'
                && (
                  <Panel headingText={t('invoiceGroup')}>
                    <div className="column is-full">
                      <div className="field-body columns">
                        <div className="column">
                          <TextInput
                            name="fileCsv"
                            value={file}
                            fileName={binaryFile !== '' ? binaryFile.name : binaryFile}
                            onChange={this.handleFileChange}
                            placeholder={t('loadCsv')}
                            type="file"
                            fieldClasses="file has-name"
                            labelClasses="file-label"
                            controlClasses="is-expanded has-icons-left file-label"
                            inputClasses="has-text-right file-input"
                            iconPosition="is-left"
                            icon="fas fa-file-csv fa-2x"
                            band={true}
                          />
                        </div>
                      </div>
                      <div className="field-body columns">
                        <div className="column">
                          <TextInput
                            name="fileZip"
                            value={fileZip}
                            fileName={binaryFileZip !== '' ? binaryFileZip.name : binaryFileZip}
                            onChange={this.handleFileChange}
                            placeholder={t('loadZip')}
                            type="file"
                            fieldClasses="file has-name"
                            labelClasses="file-label"
                            controlClasses="is-expanded has-icons-left file-label"
                            inputClasses="has-text-right file-input"
                            iconPosition="is-left"
                            icon="fas fa-file-archive fa-2x"
                            band={true}
                          />
                          <strong> <span
                            className="texto-file">{t('fileSize', { size: '50MB' })}
                          </span></strong>
                        </div>
                      </div>
                      <div className="field-body columns">
                        <div className="column">
                          <button
                            type="button"
                            className="button is-success"//button is-primary
                            onClick={() => {
                              this.sendInvoices();
                            }}
                            disabled={!(binaryFile !== '' /*&& commerceCode !== -1*/)}
                          >
                            <i className="fas fa-check-circle mr-8px" aria-hidden="true"/>
                            {t('confirmInvoices')}
                          </button>
                          &nbsp;&nbsp;
                        </div>
                      </div>
                      <div className="terms-box" >
                        <p style={{ marginBottom: '.5em' }}>{t('uploadFileCsvType')}</p>
                        <div className="content">
                          <ul>
                            {this.arrayCsv().map((csv) =>
                              <li key={csv}>{csv}</li>
                            )}
                          </ul>
                        </div>
                        <p>{t('anExample')}</p>
                        {t('example')}
                        <p>{t('note')}</p>
                      </div>
                    </div>
                  </Panel>
                )}
                {toggled && toggleProps.isBuyer && (
                  <Modal onToggle={onToggle}>
                    <Panel headingText={t('infoError')}>
                      <div className="FormLogin">
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="column">
                              {bugs
                              && (
                                <ul style={{ marginBottom: '3px' }}>
                                  {bugs.map((val, index) => (
                                    <li key={val}>
                                      <p style={{
                                        marginLeft: '0px',
                                        marginTop: '5px',
                                      }}>
                                        &nbsp;
                                        {'- ' + val}
                                      </p>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                        <br/>
                        <div className="is-fullwidth has-text-right">
                          <button
                            type="button"
                            className="button is-primary"
                            onClick={() => onToggle({
                              setState: {
                                isBuyer: false,
                              }
                            })}
                          >
                            {t('accept')}
                          </button>
                        </div>
                      </div>
                    </Panel>
                  </Modal>
                )}
                {toggled && toggleProps.isInvoice && (
                  <Modal onToggle={onToggle}>
                    <Panel headingText={t('infoInvoices')}>
                      <div className="FormLogin" style={{
                        fontFamily: 'Roboto, Arial, sans-serif',
                      }}>
                        <p align={'center'}>
                          <span>
                            <strong> {t('infoInvoiceSuccess', { cant: invoiceSuccess })}</strong>
                            <br/>
                            {t('infoInvoiceError', { cant: invoiceError })}
                            <br/>
                            <span style={{
                              color: '#FF3933',
                              fontSize: '12px',
                            }}>{t('invoiceErrorInfo')}</span>
                            <br/><br/>
                            <strong>{t('questionInfo', { cant: invoiceSuccess })}</strong>
                          </span>
                        </p><br/>
                        <div className="is-fullwidth has-text-centered">
                          <button
                            type="button"
                            className="button is-primary"
                            onClick={() => {
                              this.loadPreInvoice(onToggle);
                              onToggle({});
                            }}
                          >
                            {t('yes')}
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            className="button is-primary"
                            onClick={() => onToggle({})}
                          >
                            {t('no')}
                          </button>
                        </div>
                      </div>
                    </Panel>
                  </Modal>
                )}
                {data1 && pathname === '/list-invoice'
                && (
                  <div className="column is-full">
                    <Panel headingText={title1}>
                      <TableSimple data={data1} columns={columns1} band={true}/>
                    </Panel>
                    <div className="field is-horizontal">
                      <div className="field-body columns">

                        <div className="column is-full">
                          <div className="is-fullwidth has-text-centered">
                            <button
                              type="button"
                              className="button is-primary"
                              // onClick={() => {
                              //   this.loadPreInvoice(onToggle);
                              //   onToggle({});
                              // }}
                              onClick={() => {
                                backPreInvoice({
                                  data: { receiver_id: id },
                                  callback: () => {
                                    history.push('/load-invoice-group');
                                    this.resetState();
                                  },
                                });
                              }}
                            >
                              <i className="fas fa-chevron-circle-left mr-8px" aria-hidden="true"/>
                              {t('goBack')}
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              className="button is-success"//"button is-primary"
                              disabled={this.totalInvoiceSuccess(true) <= 0}
                              onClick={() => {
                                this.setState(
                                  (this.totalInvoiceSuccess(false))
                                  , () => {
                                    onToggle({
                                      isInvoice: true,
                                    });
                                  });
                              }}
                            >
                              <i className="fas fa-paper-plane mr-8px" aria-hidden="true"/>
                              {t('sendSelectedInvoices')}
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {data && pathname === '/list-invoice'
                && (
                  <div className="column is-full">
                    <Panel headingText={title}>
                      <TableSimple data={data} columns={columns} band={true}/>
                    </Panel>
                  </div>
                )}
              </Fragment>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

InvoiceGroup.propTypes = {
  history: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape({}).isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  loadInvoiceGroup: PropTypes.func.isRequired,
  loadPreInvoice: PropTypes.func.isRequired,
};

const mapStateToProps = ({
                          documentType,
                          role,
                          providers,
                          router,
                        }) => ({
  documentType,
  role,
  providers,
  router,
});
export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...roleActions,
    ...clientsActions,
    ...authActions,
    ...providersActions
  }),
  withNamespaces(),
)(InvoiceGroup);