import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Currency, Loading, LoadInvoicesForm, Panel, TableSimple, Toggle, } from '../../components';
import { statsActions } from '../../state/ducks/stats';
import { transactionsActions } from '../../state/ducks/transactions';
import { clientsActions } from '../../state/ducks/clients';
import Modal from '../../components/Modal';
import { providersActions } from '../../state/ducks/providers';
import { formatDate, numberFilter, onInputOnlyNumbers } from '../../util';
import { API_URL, TOAST_CONFIG } from '../../config/constants';
import FormReject from './components/FormReject';
import FormApprove from './components/FormApprove';

const ItemCard = ({ name, value }) => (
  <li>
    <b>
      {`${name}:`}
    </b>
    {value}
  </li>
);

ItemCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const { t, transactions: { currency } } = this.props;
    return ({
      days: 1,
      interest_rate: 0,
      discount_rate: 0,
      adjusted_amount: 0,
      type: '',
      approveDisabled: false,
      transaction_type: 'transfer',
      destination_phone_number: '',
      amount: '',
      invoice_date: new Date(),
      invoice_amount: null,
      invoice_number: null,
      invoice_description: '',
      client: null,
      product: currency,
      transfer_date: Date.now(),
      discount_amount: 0,
      detail: '',
      options: [
        {
          text: t('extractAccount'),
          value: 1,
        },
      ],
      rejectReason: '',
      isEdit: '',
      binaryFile: '',
      password: '',
    });
  };

  componentDidMount() {
    const {
      listTransactionsSender,
      listTransactionsReceiver,
      auth: { user: { user_id, b2b: { id, type_user } } },
      getClients,
    } = this.props;
    getClients({
      params: {
        id,
        related: true,
      },
    });
    if (type_user === 'buyer') {
      listTransactionsSender({
        params: {
          user_id,
        },
      });
    }
    if (type_user === 'seller' || type_user === 'seller_assist_card') {
      listTransactionsReceiver({
        params: {
          user_id,
        },
      });
    }
  }

  getTitle = () => {
    const {
      t,
      router: { location: { pathname } },
    } = this.props;

    switch (pathname) {
      case '/review-invoices':
        return t('underReviewInvoices');
      case '/approved-invoices':
        return t('approvedInvoicesTitle');
      case '/paid-invoices':
        return t('paidInvoices');
      case '/reject-invoices':
        return t('rejectInvoiceAll');
      case '/pending-invoices':
        return t('invoicePending');
      default:
        return t('listInvoices');
    }
  };

  matchTarget = (targets) => {
    const { auth: { user: { user_id, profile } } } = this.props;

    if (targets) {
      let permisions = false;

      for (let i = 0; i < targets.roles.length; i++) {
        permisions = permisions || targets.roles[i] === profile.role_id;
      }

      for (let i = 0; i < targets.users.length; i++) {
        permisions = permisions || targets.users[i] === profile.user_id;
      }

      return permisions;
    }

    return (user_id === profile.user_id) || (profile.type === 'client');
  };

  getGridSettings = (userType, onToggle) => {
    let columns = null;
    let data = null;
    let title = null;
    const {
      t, transactions: { sender, receiver },
      auth: { user: { user_id, } },
      router: { location: { pathname } },
      //clients,
      deleteTransaction,
      listTransactionsReceiver,
      fetchTransaction,
      history,
    } = this.props;
    const {
      approveDisabled
    } = this.state;
    switch (userType) {
      case 'buyer':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoiceNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('datePayment'),
            width: 120,
            //accessor: d => formatDate(d.due_date),
            accessor: 'due_date',
            show: pathname !== '/reject-invoices',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.due_date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.due_date)
                .includes(filter.value)) {
                return formatDate(row.due_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'approved_date', // Required because our accessor is not a string
            Header: t('rejectionDate'),
            width: 120,
            headerClassName: 'wordwrap',
            accessor: 'approved_date',
            show: pathname === '/reject-invoices',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.approved_date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.approved_date)
                .includes(filter.value)) {
                return formatDate(row.approved_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'adjusted_amount', // Required because our accessor is not a string
            Header: t('totalToPayInvoice'),
            headerClassName: 'wordwrap',
            //accessor: d => formatCurrencyCl(d.adjusted_amount),
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            filterMethod: (filter, row) => numberFilter(filter, row),
            Filter: ({ filter, onChange }) =>
              <input
              onInput={onInputOnlyNumbers}
              onChange={event => onChange(event.target.value)}
              style={{ width: '100%' }}
              value={filter ? filter.value : ''}
            />
          },
          {
            id: 'currency',
            Header: t('currency'),
            width: 80,
            accessor: d => d.pay_currency_id !== null ? `${d.pay_currency_id}` : '',
          },
          /*{
            id: 'rate_transaction', // Required because our accessor is not a string
            Header: t('chargeForService'),
            headerClassName: 'wordwrap',
            width: 130,
            accessor: d => `${d.sender.rate_transaction}`,
            Cell: cellInfo => (
              <span>{cellInfo.original.rate_transaction !== null ? formatCurrencyCl(cellInfo.original.rate_transaction.adjusted_amount) : formatCurrencyCl(0)}</span>
            ),
          },*/
          {
            id: 'last_four', // Required because our accessor is not a string
            Header: t('assignedCardNumber'),
            headerClassName: 'wordwrap',
            show: sender.length > 0 ? sender[0].sender_status_id === 'PEJ' || sender[0].sender_status_id === 'PAG' : '',
            accessor: d => d.last_four !== null ? `${d.last_four}` : '',
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('businessName'),
            accessor: d => d.parent_id !== null ? t('bankProvider') : `${d.receiver.idPerson.name}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            accessor: d => `${d.description}`,
            /*Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),*/
          },
          /* {
            id: 'transferState', // Required because our accessor is not a string != null
            Header: t('transferState'),
            accessor: d => `${d.log_message} ? ${d.log_message} : ${d.senderStatus.description}`,
          },*/
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            width: 120,
            accessor: d => `${d.senderStatus.description}`,
            /*Cell: cellInfo => (
              <div>
                {cellInfo.original.log_message != null && cellInfo.original.senderStatus.id === 'PEJ' ? (
                  <span data-type="info"
                        data-tip={cellInfo.original.log_message}>{cellInfo.original.log_message}
                  </span>
                  )
                  :
                  (
                  <span>
                    {cellInfo.original.senderStatus.description}
                  </span>
                )
                }
              </div>
            ),*/
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  {cellInfo.original.resource
                  && (
                    <td>
                      <a
                        href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.resource}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button"
                      >
                          <span className="icon" title={t('download')}>
                            <a className="ml-">
                              <i className="fas fa-download"/>
                            </a>
                          </span>
                      </a>
                    </td>
                  )}
                  {cellInfo.original.sender_status_id === 'PAP' && this.matchTarget(cellInfo.original.targets)
                  && (
                    <td>
                      <button
                        className="button is-primary is-inverted"
                        onClick={() => this.approveInvoice(cellInfo.original.id)}
                        /*onClick={() => {
                          this.setState({
                            isEdit: true,
                            binaryFile: '',
                            password: '',
                          }, () => {
                            onToggle({
                              isBuyer: true,
                              type: 'approve',
                              transaction: cellInfo.original.id,
                              onSubmit: this.rejectInvoice,
                            });
                          });
                        }
                        }*/
                        disabled={approveDisabled}
                      >
                        <span className="icon" title={t('approve')}>
                          <i className="fas fa-check"/>
                        </span>
                        {/* <i className="fas fa-check mr-8px" aria-hidden="true"/>
                          {t('')}*/}
                      </button>
                    </td>
                  )}
                  {cellInfo.original.sender_status_id === 'PAP' && this.matchTarget(cellInfo.original.targets)
                  && (
                    <td>
                      <button
                        className="button is-danger is-inverted"
                        //onClick={() => this.rejectInvoice(cellInfo.original.id)}
                        onClick={() => {
                          this.setState({
                            isEdit: true,
                            binaryFile: '',
                            password: '',
                          }, () => {
                            onToggle({
                              isBuyer: true,
                              type: 'delete',
                              transaction: cellInfo.original.id,
                              onSubmit: this.rejectInvoice,
                            });
                          });
                        }
                        }
                      >
                          <span className="icon" title={t('reject')}>
                            <i className="fas fa-times"/>
                          </span>
                        {/*<i className="fas fa-times mr-8px" aria-hidden="true"/>
                          {t('')}*/}
                      </button>
                    </td>
                  )}
                  {/* {cellInfo.original.sender_status_id === 'PEJ'
                      && (
                        <div className="column ">
                          <button
                            className="button is-success is-inverted"
                            onClick={() => {
                              this.setState({
                                type: 'differ',
                              }, () => this.updateRates(cellInfo.original));
                              onToggle({
                                transaction: cellInfo.original,
                                paymentCondition: defer_condition,
                                onSubmit: this.deferPayment,
                              });
                            }
                            }
                          >
                            <i className="far fa-clock mr-8px" aria-hidden="true" />
                            {t('differ')}
                          </button>
                        </div>
                      )} */}
                  <td>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        fetchTransaction({
                          params: {
                            id: cellInfo.original.id,
                            role: 'sender',
                          },
                          callback: () => {
                            history.push('/invoice-detail');
                          }
                        });
                      }}
                    >
                        <span className="icon" title={t('detail')}>
                          <i className="far fa-file-alt"/>
                        </span>
                      {/*<i className="far fa-file-alt mr-8px" aria-hidden="true"/>*/}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = sender;
        title = this.getTitle();
        break;
      case 'seller':
      case 'seller_assist_card':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoiceNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'pay_provider_date', // Required because our accessor is not a string
            Header: t('collectionDate'),
            //accessor: d => d.pay_provider_date !== null ? formatDate(d.pay_provider_date) : '',
            accessor: 'pay_provider_date',
            show: pathname !== '/reject-invoices',
            Cell: cellInfo => (
              <div>
                <span>{cellInfo.original.pay_provider_date !== null ? formatDate(cellInfo.original.pay_provider_date) : ''}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.pay_provider_date)
                .includes(filter.value)) {
                return formatDate(row.pay_provider_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'approved_date', // Required because our accessor is not a string
            Header: t('rejectionDate'),
            //accessor: d => d.approved_date !== null ? formatDate(d.approved_date) : '',
            accessor: 'approved_date',
            show: pathname === '/reject-invoices',
            Cell: cellInfo => (
              <div>
                <span>{cellInfo.original.approved_date !== null ? formatDate(cellInfo.original.approved_date) : ''}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.approved_date)
                .includes(filter.value)) {
                return formatDate(row.approved_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            //accessor: d => `${formatCurrencyCl(d.adjusted_amount)}`,
            accessor: d => d.adjusted_amount,
            Cell: cellInfo => (
              <Currency value={cellInfo.original.adjusted_amount} />
            ),
            sortMethod: (a, b) => Number(a)-Number(b),
            filterMethod: (filter, row) => numberFilter(filter, row),
            Filter: ({ filter, onChange }) =>
              <input
                onInput={onInputOnlyNumbers}
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : ''}
              />
          },
          {
            id: 'currency',
            Header: t('currency'),
            accessor: d => d.pay_currency_id !== null ? `${d.pay_currency_id}` : '',
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('buyerName'),
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}
                </span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            Cell: cellInfo => (

              <div>
                {cellInfo.original.log_message !== null && cellInfo.original.receiver_status_id === 'PEJ' ? (
                  <span data-type="info"
                        data-tip={cellInfo.original.log_message}>{cellInfo.original.log_message}
                  </span>
                  )
                  :
                  (
                  <span>
                    {cellInfo.original.receiverStatus.description}
                  </span>
                )
                }
              </div>
            ),
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  {cellInfo.original.resource
                  && (
                    <td>
                      <a
                        href={`${API_URL}b2b/resource-file?nameFile=${cellInfo.original.resource}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button"
                      >
                        <span className="icon" title={t('download')}>
                          <a className="ml-">
                            <i className="fas fa-download"/>
                          </a>
                        </span>
                      </a>
                    </td>
                  )}
                  {cellInfo.original.receiver_status_id === 'PEM' && cellInfo.original.adjusted_amount > 0 && this.matchTarget(cellInfo.original.targets_provider)
                  && (
                    <td>
                      <button
                        className="button is-primary is-inverted"
                        onClick={() => this.approveInvoice(cellInfo.original.id)}
                      >
                         <span className="icon" title={t('approve')}>
                          <i className="fas fa-check"/>
                         </span>
                        {/* <i className="fas fa-check mr-8px" aria-hidden="true"/>
                          {t('')}*/}
                      </button>
                    </td>
                  )}
                  {cellInfo.original.receiver_status_id === 'PEM' && this.matchTarget(cellInfo.original.targets_provider)
                  && (
                    <td>
                      <button
                        className="button is-danger is-inverted"
                        onClick={() => {
                          this.rejectInvoice(cellInfo.original.id);
                        }
                        }
                      >
                        <span className="icon" title={t('delete')}>
                          <i className="fas fa-times"/>
                        </span>
                        {/*<i className="fas fa-times mr-8px" aria-hidden="true"/>
                          {t('')}*/}
                      </button>
                    </td>
                  )}
                  {/*                {cellInfo.original.receiver_status_id === 'PEJ'
                      && (
                        <div className="column ">
                          <button
                            className="button is-success is-inverted"
                            onClick={() => {
                              this.setState({
                                type: 'advance',
                              }, () => this.updateRates(cellInfo.original));
                              onToggle({
                                transaction: cellInfo.original,
                                paymentCondition: advance_condition,
                                onSubmit: this.advancePayment,
                              });
                            }
                            }
                          >
                            <i className="far fa-clock mr-8px" aria-hidden="true" />
                            {t('advance')}
                          </button>
                        </div>
                      )}*/}
                  {cellInfo.original.receiver_status_id === 'REC'
                  && (
                    <td>
                      <button
                        className="button is-primary is-inverted"
                        onClick={() => {
                          this.setState({
                            rejectReason: cellInfo.original.rec_reason,
                            isEdit: false,
                          }, () => {
                            onToggle({
                              isBuyer: true,
                              type: 'delete',
                              transaction: cellInfo.original,
                            });
                          });
                        }
                        }
                      >
                          <span className="icon" title={t('rejectReason')}>
                            <i className="far fa-eye" aria-hidden="true"/>
                          </span>
                      </button>
                    </td>
                  )}
                  {pathname === '/review-invoices'
                  && (
                    <td>
                      <Link
                        className="button is-danger is-inverted"
                        to="/review-invoices"
                        onClick={() => {
                          deleteTransaction({
                            data: {
                              id: cellInfo.original.id
                            },
                            t,
                            callback: () => {
                              listTransactionsReceiver({
                                params: {
                                  user_id,
                                },
                              });
                            }
                          });
                        }}
                      >
                          <span className="icon" title={t('delete')}>
                            <i className="fas fa-times "/>
                          </span>
                      </Link>
                    </td>
                  )}
                  <td>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        fetchTransaction({
                          params: {
                            id: cellInfo.original.id,
                            role: 'receiver',
                          },
                          callback: () => {
                            history.push('/invoice-detail');
                          }
                        });
                      }}
                    >
                        <span className="icon" title={t('detail')}>
                          <i className="far fa-file-alt"/>
                        </span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          }
        ];
        data = receiver;
        title = this.getTitle();
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  approveInvoice = (transaction_id/*, onToggle*/) => {
    const {
      approveInvoice,
      listTransactionsSender,
      listTransactionsReceiver,
      auth: { user: { user_id, profile, b2b: { type_user } } },
      showMessage,
      t,
    } = this.props;
    const {
      binaryFile,
      password,
    } = this.state;
    const data = {
      transaction_id,
      approved: true,
      user_id: profile.user_id,
      role_id: profile.role_id,
      provider: type_user === 'seller',
      file_sii: binaryFile,
      file_pass: password,
    };

    approveInvoice({
      data,
      callback: (resp) => {
        type_user === 'buyer' ? listTransactionsSender({ params: { user_id } })
          : listTransactionsReceiver({ params: { user_id } });
        if (resp.process) {
          showMessage({
            message: t('invoiceApprovedSuccess'),
            config: TOAST_CONFIG.SUCCESS,
          });
        } else {
          showMessage({
            message: t('actionNotAllowed'),
            config: TOAST_CONFIG.ERROR,
          });
        }
        //  onToggle();
      },
    });
    //onToggle();
  };

  rejectInvoice = (transaction, onToggle) => {
    const {
      approveInvoice,
      listTransactionsSender,
      listTransactionsReceiver,
      auth: { user: { user_id, profile, b2b: { type_user } } },
      showMessage,
      t,
    } = this.props;
    const {
      binaryFile,
      rejectReason,
      password,
    } = this.state;

    const data = {
      transaction_id: transaction,
      approved: false,
      user_id: profile.user_id,
      role_id: profile.role_id,
      note: rejectReason,
      provider: type_user === 'seller',
      file_sii: binaryFile,
      file_pass: password,
    };

    if (type_user === 'seller') {
      approveInvoice({
        data,
        callback: (resp) => {
          listTransactionsReceiver({ params: { user_id } });
          if (resp.process) {
            showMessage({
              message: t('invoiceRejectedSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
          }
        },
      });
    } else if (rejectReason !== '') {
      approveInvoice({
        data,
        callback: (resp) => {
          listTransactionsSender({ params: { user_id } });
          if (resp.process) {
            showMessage({
              message: t('invoiceRejectedSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
            this.setState({ rejectReason: '' });
          } else {
            showMessage({
              message: t('actionNotAllowed'),
              config: TOAST_CONFIG.ERROR,
            });
          }
          // onToggle();
        },
      });
      onToggle();
    } else {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }
  };

  editInvoice = (transaction, onToggle) => {
    const {
      editTransaction,
      auth: {
        user: {
          user_id,
        },
      },
      clients,
      listTransactionsReceiver,
    } = this.props;
    const {
      invoice_date,
      invoice_amount,
      invoice_number,
      invoice_description,
      client,
      binaryFile,
    } = this.state;
    const data = {
      id: transaction.id,
      date: invoice_date,
      invoice_number,
      amount: invoice_amount,
      buyer_id: clients.list[client].id,
      seller_id: user_id,
      description: invoice_description,
      file: binaryFile,
    };
    editTransaction({
      data,
      callback: () => {
        listTransactionsReceiver({ params: { user_id } });
        onToggle();
      },
    });
  };

  /*deferPayment = (transaction_id, condition_id, onToggle) => {
    const {
      deferInvoice,
      listTransactionsSender,
      fetchStats,
      auth: {
        user: { user_id },
      },
    } = this.props;
    const { days } = this.state;
    const data = {
      transaction_id,
      condition_id,
      days,
    };
    deferInvoice({
      data,
      callback: () => {
        listTransactionsSender({ params: { user_id } });
        fetchStats();
        onToggle();
      },
    });
  };*/

  updateRates = (data) => {
    const { days, type } = this.state;
    const {
      auth: {
        user: {
          b2b: {
            advance_condition,
            defer_condition,
          },
        },
      },
    } = this.props;
    let interest_rate;
    let discount_amount;
    const payment_condition = data.paymentCondition;
    if (type === 'advance') {
      interest_rate = 1 - (((Number(advance_condition.percentage)
        / advance_condition.days) * days) / 100);
      discount_amount = Number(Number(data.adjusted_amount)
        * (Number(payment_condition.discount_rate
          * payment_condition.receiver_discount_share) / 100)) * -1;
    } else {
      interest_rate = 1 + (((Number(defer_condition.percentage)
        / defer_condition.days) * days) / 100);
      discount_amount = Number(Number(data.adjusted_amount)
        * (Number(payment_condition.discount_rate
          * (1 - payment_condition.receiver_discount_share)) / 100));
    }
    this.setState({
      interest_rate: Number(Math.abs(((Number(data.adjusted_amount) + discount_amount)
        * (interest_rate - 1)) * -1))
        .toFixed(2),
      adjusted_amount: Number((Number(data.adjusted_amount) + discount_amount)
        * interest_rate)
        .toFixed(2),
      discount_amount: Math.abs(discount_amount)
        .toFixed(2),
    });
  };

  handleDaysChange = (event, data) => {
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    }, () => this.updateRates(data));
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    if (name === 'invoice_amount' && this.isNumber(value)) {
      this.setState({
        [name]: value,
      });
    } else if (name !== 'invoice_amount') {
      this.setState({
        [name]: value,
      });
    }
  };

  handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    this.setState({
      binaryFile: files[0],
      file: event.target.value,
    });
  };

  handleDateChange = (newDate) => {
    this.setState({
      invoice_date: newDate,
    });
  };

  handleClientChange = (newDate) => {
    this.setState({
      client: newDate.value,
    });
  };

  advancePayment = (transaction_id, condition_id, onToggle) => {
    const {
      advanceInvoice,
      listTransactionsReceiver,
      fetchStats,
      auth: {
        user: {
          b2b: { id },
        },
      },
    } = this.props;
    const { days } = this.state;
    const data = {
      transaction_id,
      condition_id,
      days,
    };
    advanceInvoice({
      data,
      callback: () => {
        listTransactionsReceiver({ params: { id } });
        fetchStats();
        onToggle();
      },
    });
  };

  handleFileReject = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const {
      t,
      showMessage,
    } = this.props;
    const files = Array.from(event.target.files);
    const arrays = files[0].name;
    let ext = arrays.split('.');

    if (name === 'file') {
      if (ext[ext.length - 1] === 'pfx') {
        this.setState({
          binaryFile: files[0],
          file: event.target.value,
        });
      } else {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('errorLoad', { type: 'pfx' })}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      }
    }
  };

  render() {
    const {
      t,
      transactions: { balance, charge, loading: transactionLoading },
      auth: { user: { b2b: { amexOwner, type_user, advance_condition, defer_condition } }, contacts },
      clients,
      clients: { loading: clientLoading },
      router: { location: { pathname } },
    } = this.props;
    const {
      days,
      interest_rate,
      adjusted_amount,
      type,
      file,
      client,
      invoice_amount,
      discount_amount,
      invoice_date,
      invoice_description,
      invoice_number,
      binaryFile,
      amount,
      rejectReason,
      isEdit,
      password,
    } = this.state;
    const charge_amount = parseFloat(amount) * (charge / 100);
    const loading = transactionLoading || clientLoading;
    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { title, columns, data } = this.getGridSettings(type_user, onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    // eslint-disable-next-line no-nested-ternary
                    toggled && toggleProps.isApprove ? (
                        <Modal onToggle={onToggle}>
                          <FormApprove
                            t={t}
                            onToggle={onToggle}
                            toggle={toggled}
                            toggleProps={toggleProps}
                            handleSelectChange={this.handleSelectChange}
                          />
                        </Modal>
                      )
                      : toggled && toggleProps.isBuyer ? (
                        <Modal onToggle={onToggle}> {loading && <Loading/>}
                          <FormReject
                            t={t}
                            onToggle={onToggle}
                            toggle={toggled}
                            toggleProps={toggleProps}
                            onSubmit={toggleProps.type === 'delete' ? () => this.rejectInvoice(toggleProps.transaction, onToggle)
                              : () => this.approveInvoice(toggleProps.transaction, onToggle)}
                            handleInputChange={this.handleInputChange}
                            data={{ rejectReason }}
                            isEdit={isEdit}
                            handleFileChange={this.handleFileReject}
                            binaryFile={binaryFile}
                            type={toggleProps.type}
                            type_user={type_user}
                            password={password}
                          />
                        </Modal>
                      )
                      /*: toggled && !toggleProps.isEdit
                        ? (
                          <Modal onToggle={onToggle}>
                            <TableList title={t('newPaymentCondition')}>
                              <div className="content">
                                <ul>
                                  <TextInput
                                    name="days"
                                    label={type === 'advance' ? t('daysAdvance') : t('daysDefer')}
                                    value={days}
                                    onChange={event => this.handleDaysChange(event,
                                      toggleProps.transaction)}
                                    placeholder="1"
                                    type="number"
                                    controlClasses="is-expanded has-icons-left"
                                    inputClasses="has-text-right"
                                    min="1"
                                    step="1"
                                    iconPosition="is-left"
                                    icon="far fa-usd"
                                  />
                                  <ItemCard
                                    name={t('percentage')}
                                    value={` ${formatCurrency(type === 'advance' ? advance_condition.percentage : defer_condition.percentage)} %`}
                                  />
                                  <ItemCard
                                    name={t('discountAmount')}
                                    value={` ${formatCurrency(discount_amount)}`}
                                  />
                                  <ItemCard
                                    name={t('interest_rate')}
                                    value={` ${formatCurrency(interest_rate)}`}
                                  />
                                  <ItemCard
                                    name={t('totalPay')}
                                    value={` ${formatCurrency(adjusted_amount)}`}
                                  />
                                </ul>
                                <button
                                  className="button is-primary is-pulled-right"
                                  onClick={() => toggleProps.onSubmit(toggleProps.transaction.id,
                                    toggleProps.paymentCondition.id, onToggle)}
                                >
                                  <i className="fas fa-check mr-8px " aria-hidden="true"/>
                                  {t('confirm')}
                                </button>
                              </div>
                            </TableList>
                          </Modal>
                        )*/ : toggled && (
                        <Modal onToggle={onToggle}>
                          <LoadInvoicesForm
                            formData={{ ...this.state }}
                            onToggle={onToggle}
                            toggle={toggled}
                            toggleProps={toggleProps}
                            balance={balance}
                            handleInputChange={this.handleInputChange}
                            handleFileChange={this.handleFileChange}
                            handleDateChange={this.handleDateChange}
                            handleClientChange={this.handleClientChange}
                            contacts={contacts}
                            chargeAmount={charge_amount}
                            invoiceAmount={invoice_amount}
                            invoiceDate={invoice_date}
                            invoiceNumber={invoice_number}
                            description={invoice_description}
                            clients={clients.list}
                            selectedClient={client}
                            onSubmit={() => this.editInvoice(toggleProps.transaction, onToggle)}
                            file={file}
                            fileName={binaryFile && binaryFile.name}
                          />
                        </Modal>
                      )
                  }
                  {data
                  && (
                    <div className="column is-full">
                      <Panel headingText={title}>
                        <TableSimple data={data} columns={columns}/>
                        {/*((type_user === 'buyer') && (pathname === '/review-invoices' || pathname === '/approved-invoices' || pathname === '/paid-invoices')) &&
                        <div className="terms-box" style={{ display: (amexOwner ? 'none' : '') }}>
                          <p style={{ marginBottom: '.5em' }}>{t('termsAndConditions')}</p>
                          <p>{t('invoiceTerms')}</p>
                        </div>
                        */}
                      </Panel>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>

    );
  }
}

Invoices.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  clients: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  fetchStats: PropTypes.func.isRequired,
  listTransactionsSender: PropTypes.func.isRequired,
  listTransactionsReceiver: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  approveInvoice: PropTypes.func.isRequired,
  editTransaction: PropTypes.func.isRequired,
  deferInvoice: PropTypes.func.isRequired,
  advanceInvoice: PropTypes.func.isRequired,
  router: PropTypes.shape(Object).isRequired,
  deleteTransaction: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
};

const mapStateToProps = ({
                          transactions,
                          auth,
                          router,
                          clients,
                        }) => ({
  transactions,
  auth,
  router,
  clients,
});

export default compose(
  connect(mapStateToProps, {
    ...statsActions, ...transactionsActions, ...clientsActions, ...providersActions,
  }),
  withNamespaces(),
)(Invoices);
